import { observer } from "mobx-react";
import axios from "axios";
import { useEffect, useState, useContext } from "react";
import s from "./members.module.css";

import SquadProtected from "../../modules/Squad/SquadProtected.jsx";

import Header from "../../components/Squad/Header/Header.jsx";
import Member from "../../components/Squad/Members/Member.jsx";
import Footer from "../../components/Squad/Footer/Footer.jsx";

// STORES
import MainStore from "../../modules/Main/MainStore.js";
import { UserStoreContext, TitleStoreContext } from "../../App.js";



const MembersPage = observer(() => {
    const [members, setMembers] = useState(null);

    const User = useContext(UserStoreContext);
    const TitleStore = useContext(TitleStoreContext);

    useEffect(() => {
        const fetchData = async () => {
            await TitleStore.getTitlesDB();
            await TitleStore.getUsersTitles();
            
            let response = await axios.post(`${MainStore.SERVER_ADDRESS}/getMembers`, {}, {
                withCredentials: true
            });

            setMembers(response.data.members);
        }
        
        fetchData();
    }, []);

    return (
        <div className="wrapper__content">
            <div className={s.members}>
                {members && members.map((member) => {
                    return <Member key={member.login} member={member} />
                })}
            </div>
        </div>
    );
});

export default MembersPage;