import { observer } from "mobx-react";
import { useState, useContext } from "react";

// MODULES
import { PlayerStoreContext } from "../../../App.js";
import { MarketStoreContext } from "../../../App.js";



const MarketOtherLot = observer(({ lot }) => {
    const Player = useContext(PlayerStoreContext);
    const Market = useContext(MarketStoreContext);

    const [showLotInfo, setShowLotInfo] = useState(false);

    const getCurrencySym = (currency) => {
        switch (currency) {
            case "baubles":
                currency = "💰";
                break;
            case "baumonds":
                currency = "💠";
                break;
            case "rubles":
                currency = "₽";
        }

        return currency;
    }

    const loadItemLot = () => {
        Market.getLotInfo(lot.id);
    }


    return (
        <div className="other-lot" key={`${lot.id}-${lot.currency}`}>
            <a onClick={loadItemLot}>{lot.id}. </a>
            <div>
                {`${Number(lot.price).toLocaleString()} ${getCurrencySym(lot.currency)}`}
            </div>
        </div>
    );
});

export default MarketOtherLot;