import { makeAutoObservable, runInAction } from "mobx";
import axios from "axios";

// MODULES
import NotificationsCenter from "../../Main/NotificationsCenter.js";

// STORES
import MainStore from "../../Main/MainStore.js";
import PlayerStore from "../PlayerStore.js";



class Market {
    marketLots = [];
    lotInfo = {};
    isLotInfoLoading = true;

    constructor() {
        makeAutoObservable(this);

        // setInterval(async () => {
        //     console.log("Ok");
        //     await this.getMarketLots();
        // }, 1000 * 3); // вернуть 5 или сделать по-другому
    }

    initialize = async () => {
        await this.getMarketLots();
    }

    getMarketLots = async () => {
        let response = await axios.post(`${MainStore.SERVER_ADDRESS}/universe/getMarketLots`, {}, {
            withCredentials: true
        });

        if (!response?.data) {
            return;
        }

        runInAction(() => {
            this.marketLots = response.data;
        });
    }

    addMarketLot = async () => {
        let response = await axios.post(`${MainStore.SERVER_ADDRESS}/universe/addMarketLot`, {}, {
            withCredentials: true
        });

        NotificationsCenter.ShowSuccess(response.data.message);

        // marketLots.push(newMarketLot);
    }

    getLotInfo = async (lotId) => {
        this.resetLotInfo();
        
        runInAction(() => {
            this.isLotInfoLoading = true;
        });

        let response = await axios.post(`${MainStore.SERVER_ADDRESS}/universe/getLotInfo`, {
            lotId
        }, {
            withCredentials: true
        });

        if (!response?.data) {
            return;
        }

        runInAction(() => {
            this.lotInfo = response.data;
            this.isLotInfoLoading = false;
        });
    }

    getCurrencySym = (currency) => {
        switch (currency) {
            case "baubles":
                currency = "💰";
                break;
            case "baumonds":
                currency = "💠";
                break;
            case "rubles":
                currency = "₽";
        }

        return currency;
    }

    buyItemInLot = async (playerId, lotId) => {
        let response = await axios.post(`${MainStore.SERVER_ADDRESS}/universe/buyItemInLot`, {
            playerId,
            lotId
        }, {
            withCredentials: true
        });

        if (!response.data?.success) {
            return NotificationsCenter.ShowInfo(response.data.message);
        }

        NotificationsCenter.ShowSuccess(response.data.message);
        PlayerStore.getPlayerData();
        NotificationsCenter.ShowInfo(`-${response.data.cost} ${this.getCurrencySym(response.data.currency)}`);
    }

    resetLotInfo = () => {
        runInAction(() => {
            this.lotInfo = {
                sellLotsList: [],
                buyLotsList: []
            };
        });
    }
}

export default new Market();