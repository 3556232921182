import React from "react";
import SquadProtected from "../../../modules/Squad/SquadProtected.jsx";
import {useParams} from "react-router-dom";



const SquadWrapper = ({ element }) => {
    const { postId } = useParams();

    return (
        <div className="wrapper">
            <SquadProtected element={React.cloneElement(element, { postId })} />
        </div>
    );
}

export default SquadWrapper;