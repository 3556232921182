// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_avatar__IzCuu {
    height: 24px;
    width: 24px;
}

.style_authorBox__glKu3 {
    display: grid;
    grid-template-columns: .1fr .1fr .1fr;
    column-gap: 5px;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Squad/Profile/BlogPost/style.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,aAAa;IACb,qCAAqC;IACrC,eAAe;IACf,mBAAmB;AACvB","sourcesContent":[".avatar {\r\n    height: 24px;\r\n    width: 24px;\r\n}\r\n\r\n.authorBox {\r\n    display: grid;\r\n    grid-template-columns: .1fr .1fr .1fr;\r\n    column-gap: 5px;\r\n    align-items: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatar": `style_avatar__IzCuu`,
	"authorBox": `style_authorBox__glKu3`
};
export default ___CSS_LOADER_EXPORT___;
