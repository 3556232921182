import { makeAutoObservable, runInAction } from "mobx";
import axios from "axios";

// STORES
import MainStore from "../Main/MainStore.js";

// MODULES
import NotificationsCenter from "../Main/NotificationsCenter.js";



class BlogStore {
    isBlogLoading = false;
    isBlogDeleting = false;
    isVoteSending = false;
    globalPostsLoading = false;
    postCommentsLoading = false;

    postComments = {};

    posts = [];
    isSorted = false;
    sortedPosts = [];
    allPosts = [];
    globalPostsAmount = 1;

    currentPageMyPosts = 0;
    maxItemsOnPageMyPosts = 5;

    pageGlobalPosts = 1;
    globalPageSize = 0;


    constructor() {
        makeAutoObservable(this);
    }

    incrementPageMyPosts = () => {
        runInAction(() => {
            this.currentPageMyPosts = Math.min(this.currentPageMyPosts + 1, this.totalPagesMyPosts - 1);
        });
    }

    decrementPageMyPosts = () => {
        runInAction(() => {
            this.currentPageMyPosts = Math.max(this.currentPageMyPosts - 1, 0);
        });
    }

    get totalPagesMyPosts() {
        return Math.ceil(this.posts.length / this.maxItemsOnPageMyPosts);
    }

    incrementPageAllPosts = async () => {
        runInAction(() => {
            this.pageGlobalPosts = Math.min(this.pageGlobalPosts + 1, this.totalPagesAllPosts);
        });

        await this.updateNews();
    }

    decrementPageAllPosts = async () => {
        runInAction(() => {
            this.pageGlobalPosts = Math.max(this.pageGlobalPosts - 1, 1);
        });

        await this.updateNews();
    }

    toStartPage = async () => {
        runInAction(() => {
            this.pageGlobalPosts = 1;
        });

        await this.updateNews();
    }

    toEndPage = async () => {
        runInAction(() => {
            this.pageGlobalPosts = this.totalPagesAllPosts;
        });

        await this.updateNews();
    }

    get totalPagesAllPosts() {
        return Math.ceil(this.globalPostsAmount / this.globalPageSize);
    }

    sendBlog = async (text) => {
        const response = (await axios.post(`${MainStore.SERVER_ADDRESS}/addBlogPost`, {
            text
        }, {
            withCredentials: true
        })).data.message;

        if (response.data?.error) {
            return NotificationsCenter.ShowWarn(response.data.error);
        } else {
            return NotificationsCenter.ShowSuccess("Пост успешно записан");
        }
    }

    updateBlog = async (userId) => {
        if (this.isBlogLoading) {
            return;
        }

        runInAction(() => {
            this.isBlogLoading = true;
        });

        let response = await axios.post(`${MainStore.SERVER_ADDRESS}/getBlogPosts`, {
            userId
        }, {
            withCredentials: true
        });

        if (!response?.data) {
            return;
        }

        let receivedPosts = response.data.posts;

        receivedPosts.forEach(post => {
            post.Post.text = MainStore.textFormatter(post.Post.text);
        });

        runInAction(() => {
            this.posts = receivedPosts;
            this.isBlogLoading = false;
        });
    }

    updateNews = async () => {
        runInAction(() => {
            this.globalPostsLoading = true;
        });

        let response = await axios.post(`${MainStore.SERVER_ADDRESS}/getAllPosts`, {
            page: this.pageGlobalPosts
        }, {
            withCredentials: true
        });

        if (!response?.data) {
            return;
        }

        let receivedPosts = response.data.posts;

        receivedPosts.forEach(post => {
            post.Post.text = MainStore.textFormatter(post.Post.text);
        });

        runInAction(() => {
            this.allPosts = response.data.posts;
            this.globalPostsAmount = response.data.postsAmount;
            this.globalPageSize = response.data.pageSize;
            this.globalPostsLoading = false;
        });
    }

    deleteBlogPost = async (blogPostId) => {
        runInAction(() => {
            this.isBlogDeleting = true;
        });

        let response = await axios.post(`${MainStore.SERVER_ADDRESS}/deleteBlogPost`, {
            blogPostId
        }, {
            withCredentials: true
        });

        if (!response?.data) {
            return;
        }

        if (response.data?.message) {
            NotificationsCenter.ShowSuccess(response.data.message);
        }

        runInAction(() => {
            this.isBlogDeleting = false;
        });
    }

    sendVoteFunction = async (postId, vote) => {
        if (this.isVoteSending) {
            return NotificationsCenter.ShowInfo("Дождитесь ответа от сервера");
        }

        runInAction(() => {
            this.isVoteSending = true;
        });

        let response = await axios.post(`${MainStore.SERVER_ADDRESS}/votePost`, {
            postId,
            vote
        }, {
            withCredentials: true
        });

        if (!response?.data) {
            return;
        }

        if (response.data?.message) {
            NotificationsCenter.ShowSuccess(response.data.message);
        }

        runInAction(() => {
            this.isVoteSending = false;
        });
    }

    getPostComments = async (postId) => {
        runInAction(() => {
            this.postCommentsLoading = true;
        });

        let response = await axios.post(`${MainStore.SERVER_ADDRESS}/getPostComments`, {
            postId
        }, {
            withCredentials: true
        });

        if (!response?.data) {
            return;
        }

        let receivedPosts = response.data.posts;

        receivedPosts.forEach(post => {
            post.Post.text = MainStore.textFormatter(post.Post.text);
        });

        runInAction(() => {
            this.postComments = receivedPosts[0];
            this.postCommentsLoading = false;
        });
    }

    search = (text) => {
        if (text !== "") {
            const regex = new RegExp(text, "i");

            runInAction(() => {
                this.sortedPosts = this.posts.filter(post => regex.test(post.Post.text));
                this.isSorted = true;
            });
        } else {
            runInAction(() => {
                this.isSorted = false;
            });
        }
    }
}

export default new BlogStore();