// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CivilisationRecipes_wrapper__4OsJL {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.CivilisationRecipes_multiblocksList__mVAqr {
    display: grid;
    grid-template-rows: 1fr;
    row-gap: 1em;
}

td {
    /*font-size: .2em;*/
}

table {
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/Civilisation/CivilisationRecipes.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,YAAY;AAChB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".wrapper {\r\n    width: 100%;\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n\r\n.multiblocksList {\r\n    display: grid;\r\n    grid-template-rows: 1fr;\r\n    row-gap: 1em;\r\n}\r\n\r\ntd {\r\n    /*font-size: .2em;*/\r\n}\r\n\r\ntable {\r\n    width: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `CivilisationRecipes_wrapper__4OsJL`,
	"multiblocksList": `CivilisationRecipes_multiblocksList__mVAqr`
};
export default ___CSS_LOADER_EXPORT___;
