import { observer } from "mobx-react";
import { Button, Form } from "react-bootstrap";
import { useState } from "react";
import axios from "axios";

// STORES
import MainStore from "../../../modules/Main/MainStore.js";
import QuotesStore from "./QuotesStore.js";

// MODULES
import NotificationCenter from "../../../modules/Main/NotificationsCenter.js";



const AddQuoteForm = observer(() => {
    const [textQuote, setTextQuote] = useState("");
    const [createdAtQuote, setCreatedAtQuote] = useState("");
    const [authorsQuote, setAuthorsQuote] = useState("");

    const OnChangeTextQuote = (event) => {
        setTextQuote(event.target.value);
    }
    
    const OnChangeCreatedAtQuote = (event) => {
        setCreatedAtQuote(event.target.value);
    }

    const OnChangeAuthorsQuote = (event) => {
        setAuthorsQuote(event.target.value);
    }


    const AddQuote = async () => {
        if (textQuote === "" || createdAtQuote === "" || authorsQuote === "") {
            return;
        }

        const date = new Date(createdAtQuote);
        date.setHours(date.getHours() - 3);
        const milliseconds = date.getTime();

        const response = await axios.post(`${MainStore.SERVER_ADDRESS}/addQuote`, {
            text: textQuote,
            createdAt: milliseconds,
            authors: authorsQuote.split(" ").join(", ")
        });

        NotificationCenter.ShowSuccess(response.data.message);
    }

    const ChangeForm = () => {
        QuotesStore.toggleIsSuggestedForm();
    }

    return (
        <div className="add-quote-form">
            <div className="add-quote-form__title">
                Добавить цитату
            </div>
            <Form>
                <Form.Control as="textarea" placeholder="Текст цитаты" className="mb-2" onChange={OnChangeTextQuote} />
                <Form.Control type="text" placeholder="Автор1 Автор2" className="mb-2" onChange={OnChangeAuthorsQuote} />
                <Form.Control type="date" placeholder="Дата" onChange={OnChangeCreatedAtQuote} />
            </Form>
            <div style={{marginTop: 8}}>Внимание! После нажатия кнопки <b style={{color: "orangered"}}>Добавить</b>, цитата будет добавлена в реестр цитат!</div>
            <div className="add-quote-form__button">
                <Button className="button" variant="outline-secondary" onClick={AddQuote}>
                    Добавить
                </Button>
                <Button className="button" variant="outline-secondary" onClick={ChangeForm} style={{marginLeft: 5}}>
                    Переключить
                </Button>
            </div>
        </div>
    );
});

export default AddQuoteForm;