import WheatProtected from "../../../modules/Wheat/WheatProtected.jsx";
import Header from "../../Main/PublicHeader.jsx";
import Footer from "../../Squad/Footer/Footer.jsx";


const WheatWrapper = ({ element }) => {
    return (
        <div className="wrapper">
            <Header title={"Default Wheat"} />
            {element}
            <Footer />
        </div>
    );
}

export default WheatWrapper;