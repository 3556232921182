import React, { useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { observer } from "mobx-react";

import Index from "../../pages/Index/Index.jsx";

// PAGES
import SquadRegistrationPage from "../../pages/Squad/RegistrationPage.jsx";
import SquadLoginPage from "../../pages/Squad/LoginPage.jsx";
import QuotesPage from "../../pages/Squad/Quotes/Quotes.jsx";
import ProfilePage from "../../pages/Squad/ProfilePage.jsx";
import MembersPage from "../../pages/Squad/MembersPage.jsx";
import HistoryPage from "../../pages/Squad/HistoryPage.jsx";
import PostsPage from "../../pages/Squad/PostsPage.jsx";

import NotFoundPage from "../../pages/Utils/NotFoundPage.jsx";
import TechnicalWorks from "../../pages/Utils/TechnicalWorks.jsx";

// COMPONENTS
import SquadWrapper from "../../components/Squad/Wrapper/SquadWrapper.jsx";

// STORES
import { UserStoreContext } from "../../App.js";
import {Post} from "../../components/Squad/Posts/Post.jsx";



const SquadSection = observer(() => {
    const User = useContext(UserStoreContext);
    
    useEffect(() => {
        async function fetchData() {
            await User.updateUserData();
        }

        fetchData();
    }, [User]);

    return (
        <>
            <Routes>
                <Route exact path="/" element={<Index />} />

                <Route path="/registration" element={<SquadRegistrationPage />} />
                <Route path="/login" element={<SquadLoginPage />} />

                {/*<Route path="*" element={<TechnicalWorks />} />*/}

                {/*Protected*/}
                <Route path="/profile" element={<SquadWrapper element={<ProfilePage />} />} />
                <Route path="/quotes" element={<SquadWrapper element={<QuotesPage />} />} />
                <Route path="/members" element={<SquadWrapper element={<MembersPage />} />} />
                <Route path="/history" element={<SquadWrapper element={<HistoryPage />} />} />
                <Route path="/posts" element={<SquadWrapper element={<PostsPage />} />} />
                <Route path="/posts/:postId" element={<SquadWrapper element={<Post />} />} />
            </Routes>

            <ToastContainer />
        </>
    );
});

export default SquadSection;

// https://stackblitz.com/github/remix-run/react-router/tree/main/examples/auth?file=src%2FApp.tsx
// https://q-games.ru/kak-sozdat-zashhishhennyj-marshrut-v-react.html
// https://ru.bem.info/methodology/quick-start/