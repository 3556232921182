import { makeAutoObservable, runInAction } from "mobx";
import axios from "axios";

// STORES
import MainStore from "../../Main/MainStore.js";

// MODULES
import NotificationsCenter from "../../Main/NotificationsCenter.js";



class TitleStore {
    isLoadingSetTitle = false;
    titles = [];
    titlesDB = [];
    availableUserTitles = [];
    membersTitlesObj = {};
    isMembersTitlesLoading = true;

    constructor() {
        makeAutoObservable(this);
    }

    getTitlesDB = async () => {
        let response = await axios.post(`${MainStore.SERVER_ADDRESS}/getAllTitles`, {}, {
            withCredentials: true
        });

        runInAction(() => {
            this.titlesDB = response.data;
        });
    }

    getUserTitles = async () => {
        let response = await axios.post(`${MainStore.SERVER_ADDRESS}/getUserTitles`, {}, {
            withCredentials: true
        });

        if (!response?.data) {
            return;
        }

        runInAction(() => {
            this.titles = response.data;
        });
    }

    setUserTitle = async (login, title) => {
        if (this.isLoadingSetTitle) {
            return NotificationsCenter.ShowInfo("Не так быстро, титул в процессе установки");
        }

        runInAction(() => {
            this.isLoadingSetTitle = true;
        });

        let response = await axios.post(`${MainStore.SERVER_ADDRESS}/setUserTitle`, {
            login,
            title
        }, {
            withCredentials: true
        });

        if (response.data?.titles === undefined) {
            NotificationsCenter.ShowInfo(response.data.message);
        } else {
            NotificationsCenter.ShowSuccess(response.data.message);
        }

        runInAction(() => {
            this.isLoadingSetTitle = false;
        });
    }

    setUserTitleByUser = async (title) => {
        if (this.isLoadingSetTitle) {
            return NotificationsCenter.ShowInfo("Не так быстро, титул в процессе установки");
        }

        runInAction(() => {
            this.isLoadingSetTitle = true;
        });

        let response = await axios.post(`${MainStore.SERVER_ADDRESS}/setUserTitleByUser`, {
            title
        }, {
            withCredentials: true
        });

        if (response.data.success) {
            NotificationsCenter.ShowSuccess(response.data.message);
        } else {
            NotificationsCenter.ShowInfo(response.data.message);
        }

        runInAction(() => {
            this.isLoadingSetTitle = false;
        });
    }

    addUserTitle = async (titleID) => {
        let response = await axios.post(`${MainStore.SERVER_ADDRESS}/addUserTitle`, {
            titleID
        }, {
            withCredentials: true
        });

        if (!response.data.success) {
            NotificationsCenter.ShowInfo(response.data.message);
        } else {
            NotificationsCenter.ShowSuccess(response.data.message);
        }
    }

    createTitle = async (title, color, rarity) => {
        let response = await axios.post(`${MainStore.SERVER_ADDRESS}/createTitle`, {
            title,
            color,
            rarity
        }, {
            withCredentials: true
        });

        if (!response.data.success) {
            NotificationsCenter.ShowInfo(response.data.message);
        } else {
            NotificationsCenter.ShowSuccess(response.data.message);
        }
    }

    getUsersTitles = async () => {
        if (Object.keys(this.membersTitlesObj).length !== 0) {
            return;
        }

        let response = await axios.post(`${MainStore.SERVER_ADDRESS}/getUsersTitles`, {}, {
            withCredentials: true
        });

        runInAction(() => {
            this.membersTitlesObj = response.data;
            this.isMembersTitlesLoading = false;
        });
    }
}

export default new TitleStore();