import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

// STORES
import MainStore from "../../modules/Main/MainStore.js";
import { UserStoreContext, PlayerStoreContext } from "../../App.js";

// COMPONENTS
import Header from "../../components/Squad/Header/Header.jsx";
import Footer from "../../components/Squad/Footer/Footer.jsx";
import NotificationsCenter from "../Main/NotificationsCenter.js";



const SquadProtected = ({ element }) => {
    const [isAuth, setIsAuth] = useState(false);
    const [timeoutText, setTimeoutText] = useState(false);

    const navigate = useNavigate();
    
    const User = useContext(UserStoreContext);
    const Player = useContext(PlayerStoreContext);

    let timeout;


    useEffect(() => {
        timeout = setTimeout(() => {
            setTimeoutText(true);
        }, 5000);

        async function checkAuth() {
            try {
                const data = (await axios.post(`${MainStore.SERVER_ADDRESS}/getAccess`, {}, {
                    withCredentials: true
                })).data;

                if (data?.error) {
                    switch (data.error) {
                        case "BANNED":
                            NotificationsCenter.ShowError(data.message);
                            break;
                    }
                }

                if (!data.isAuth) {
                    return navigate("/login");
                }

                User.setUserData(data.userData);

                await Player.initialize();

                setIsAuth(true);
            } catch (e) {
                setIsAuth(false);
            }
        }

        checkAuth();
    }, [navigate]);

    if (isAuth === false) {
        return (
            <div style={{display: "flex", justifyContent: "center", height: "100vh", alignItems: "center", flexDirection: "column"}}>
                <Spinner />
                {timeoutText &&
                    <div>
                        <div>Could be a problem with the server or low internet bandwidth :(</div>
                        <div>Try again later</div>
                    </div>
                }
            </div>
        )
    }

    clearTimeout(timeout);
    // if (!isAuth) {
    //     return navigate("/login");
    // }

    // return element;

    return (
        <>
            <Header />
            {element}
            <Footer />
        </>
    );
}

export default SquadProtected;