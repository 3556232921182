import { makeAutoObservable, runInAction } from "mobx";
import axios from "axios";

import MainStore from "../../../modules/Main/MainStore.js";

import NotificationsCenter from "../../../modules/Main/NotificationsCenter.js";



class QuotesStore {
    quotes = [];
    suggestedQuotes = [];
    allValutions = 0;
    maxValutions = 0;
    isQuotesUpdate = false;
    isQuotesLoading = false;
    isSuggestedForm = false;
    isSortValution = false;
    isShowQuotesID = false;
    isPhotoMode = false;

    constructor() {
        makeAutoObservable(this);
    }

    toggleIsPhotoMode() {
        runInAction(() => {
            this.isPhotoMode = !this.isPhotoMode;
        });
    }

    toggleIsSuggestedForm() {
        runInAction(() => {
            this.isSuggestedForm = !this.isSuggestedForm;
        });
    }

    toggleIsShowQuotesID() {
        runInAction(() => {
            this.isShowQuotesID = !this.isShowQuotesID;
        });
    }

    setQuotes(quotes) {
        runInAction(() => {
            this.quotes = quotes;
        });
    }

    setSuggestedQuotes(quotes) {
        runInAction(() => {
            this.suggestedQuotes = quotes;
        });
    }

    setSortValution(isSort) {
        runInAction(() => {
            this.isSortValution = isSort;
        });
    }

    quoteAddValution = async (userId, quoteId, valution) => {
        let data = (await axios.post(`${MainStore.SERVER_ADDRESS}/quoteValution`, {
            userId,
            quoteId,
            valution
        }, {
            withCredentials: true
        })).data;

        if (data?.ok) {
            NotificationsCenter.ShowSuccess(data.message);
        } else {
            NotificationsCenter.ShowInfo(data.message);
        }

        // TODO: Нужно сделать так, чтобы в Store попадали новые цитаты,
        // Но страница пользователя не перерисовывала каждый раз их
    }

    loadingQuotes = async () => {
        runInAction(() => {
            this.isQuotesLoading = true;
        });
       
        await this.updateQuotes();

        runInAction(() => {
            this.isQuotesLoading = false;
        });
    }

    updateQuotes = async () => {
        if (!this.isQuotesUpdate) {
            runInAction(() => {
                this.isQuotesUpdate = true;
                this.allValutions = 0;
            });

            let response = (await axios.post(`${MainStore.SERVER_ADDRESS}/getQuotes`, {}, {
                withCredentials: true
            })).data;

            if (!response?.quotes) {
                return;
            }
            
            response.quotes.forEach((quote) => {
                this.allValutions += quote.quoteValutionUsers.length;
            });

            runInAction(() => {
                this.quotes = response.quotes;
                this.maxValutions = this.quotes.length * response.usersCount;
            });

            let suggestedQuotes = (await axios.post(`${MainStore.SERVER_ADDRESS}/getSuggestedQuotes`, {}, {
                withCredentials: true
            })).data;

            runInAction(() => {
                this.suggestedQuotes = suggestedQuotes.quotes;
                this.isQuotesUpdate = false;
            });
        }
    }

    getQuotesFromAuthor = async (author) => {
        if (!this.AllValutionIsLoading) {
            runInAction(() => {
                this.isQuotesUpdate = true;
                this.allValutions = 0;
            });
            
            let data = (await axios.post(`${MainStore.SERVER_ADDRESS}/getQuotesFromAuthor`, { author })).data;

            data.quotes.forEach((quote) => {
                this.allValutions += quote.quoteValutionUsers.length;
            });

            runInAction(() => {
                this.quotes = data.quotes;
                this.maxValutions = this.quotes.length * data.usersCount;
                this.isQuotesUpdate = false;
            });
        }
    }

    sortValution = () => {
        runInAction(() => {
            this.isSortValution = !this.isSortValution;
        });

        if (this.isSortValution) {
            let quotes = this.quotes;

            let sortQuotes = [...quotes].sort((a, b) => {
                if (b.avgValution - a.avgValution !== 0) {
                    return b.avgValution - a.avgValution;
                } else {
                    return b.quoteValutionUsers.length - a.quoteValutionUsers.length;
                }
            });

            runInAction(() => {
                this.quotes = sortQuotes;
            });
        } else {
            this.updateQuotes();
        }
    }

    confirmQuote = async (quoteId) => {
        let data = (await axios.post(`${MainStore.SERVER_ADDRESS}/confirmSuggestedQuote`, {
            quoteId
        }, {
            withCredentials: true
        })).data;
        
        NotificationsCenter.ShowInfo(data.message);
    }

    declineQuote = async (quoteId) => {
        let data = (await axios.post(`${MainStore.SERVER_ADDRESS}/declineSuggestedQuote`, {
            quoteId
        }, {
            withCredentials: true
        })).data;

        NotificationsCenter.ShowInfo(data.message);
    }
}

export default new QuotesStore();