import { useState } from "react";

import UniverseEditItemsStore from "../../modules/Universe/UniverseEditItemsStore.js";



const ItemComponent = ({ item }) => {
    const [itemName, setItemName] = useState(item.title);
    const [itemDescription, setItemDescription] = useState(item.description);

    const updateItem = () => {
        UniverseEditItemsStore.updateItem(item.itemId, {
            title: itemName,
            description: itemDescription,
            damage: item.damage,
            armor: item.armor,
            price: item.price,
            requireLevel: item.requireLevel
        });
    }

    const editTitle = (e) => {
        if (e.target.value.length === 0) {
            setItemName(item.title);
            return;
        }

        setItemName(e.target.value);
    }

    const editDescription = (e) => {
        if (e.target.value.length === 0) {
            setItemDescription(item.description);
            return;
        }

        setItemDescription(e.target.value);
    }

    return (
        <tr>
            <td>
                <button onClick={updateItem}>OK</button>
            </td>
            <td>
                {item.itemId}
            </td>
            <td>
                <textarea placeholder={item.title} onChange={editTitle} />
            </td>
            <td>
                <textarea placeholder={item.description} onChange={editDescription} />
            </td>
            <td>
                {item.price}
            </td>
            <td>
                {item.chance}
            </td>
            <td>
                {item.rarity}
            </td>
            <td>
                {item.dropFromCase}
            </td>
            <td>
                {item.type}
            </td>
            <td>
                {item.damage}
            </td>
            <td>
                {item.armor}
            </td>
            <td>
                {item.itemRealChance}
            </td>
            <td>
                {item.requireLevel}
            </td>
        </tr>
    );
};

export default ItemComponent;