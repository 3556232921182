import {useContext, useEffect, useState} from "react";
import axios from "axios";
import s from "./disassembler.module.css";
import {DisassemblerStoreContext} from "../../../../App.js";



export const Item = ({ itemName, amountNeed }) => {
    const DisassemblerStore = useContext(DisassemblerStoreContext);

    const first = itemName.split(".")[0].split("_");
    // const [displayName, setDisplayName] = useState(first.map(word => word[0].toUpperCase() + word.slice(1, word.length)).join(" "));

    const getItemInfo = async () => {
        await DisassemblerStore.getItemData(itemName);
        await DisassemblerStore.getRecipeInfo(itemName);

        //setItemInfo(itemInfo);
        //setItemData(itemData);
        //setIsLoaded(true);
        //setIsOpen(!isOpen);

        // console.log(itemInfo);
    }

    // console.log("Item", itemName);

    return (
        <div className={s.item} onClick={getItemInfo}>
            <div>
                {amountNeed !== undefined ? `x${amountNeed}` : ""} {itemName.split(".")[0].split("_").map(word => word[0].toUpperCase() + word.slice(1, word.length)).join(" ")}
            </div>
            {/*<div className={s.list}>
                {isLoaded && itemInfo?.ingredients?.map((item) => {
                    return (
                        <div>
                        <Item itemName={item.name + ".json"}/>
                            <div>
                                {itemData?.emc ? `${Math.ceil(itemData.emc)} Coins` : ""}
                            </div>
                        </div>
                    );
                })}
            </div>*/}
        </div>
    );
}