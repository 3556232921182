import { observer } from "mobx-react";
import { Button, Form } from "react-bootstrap";
import { useState, useContext } from "react";

// STORES
import { UserStoreContext } from "../../../App.js";
import QuotesStore from "./QuotesStore.js";

import NotificationCenter from "../../../modules/Main/NotificationsCenter.js";



const Quote = observer((quote) => {
    const [isShowValution, setIsShowValution] = useState(quote.isShowValution);
    const [valutionValue, setValutionValue] = useState(3);

    const User = useContext(UserStoreContext);

    const OnChangeValutionValue = (event) => {
        setValutionValue(event.target.value);
    }

    const QuoteValution = async () => {
        if (valutionValue.length > 1) {
            return NotificationCenter.ShowWarn("Оценка должна быть числом от 1 до 5");
        }

        setIsShowValution(false);

        if (quote.quoteValutionUsers.includes(User.userData.id)) {
            return;
        }

        await QuotesStore.quoteAddValution(User.userData.id, quote.id, valutionValue);
    }


    return (
        <div className="quote">
            {QuotesStore.isShowQuotesID &&
            <div className="quote__id">
                {quote.id}
            </div>}
            
            <div className="quote__text">
                {/* <q>{quote.text}</q> */}
                {quote.text}
            </div>
            <div className="quote__bottom">
                <div className="quote__authors">
                    {quote.authors}
                </div>
                <div className="quote__created-at">
                    {!QuotesStore.isPhotoMode &&
                    <div className="quote__valution-value-avg">
                        Средняя оценка: {quote.avgValution} (x{quote.quoteValutionUsers.length})
                    </div>}
                    
                    {quote.createdAt}
                </div>
            </div>
            {(!quote.quoteValutionUsers.includes(User.userData.id) && isShowValution) && <div className="quote__valution">
                <div className="quote__range">
                    <Form.Range min={1} max={5} onChange={OnChangeValutionValue} />
                </div>
                <div className="quote__valution-value">
                    {valutionValue}
                </div>
                <div className="quote__button">
                    <Button className="button" variant="outline-secondary" onClick={QuoteValution}>
                        Оценить
                    </Button>
                </div>
            </div>}
        </div>
    );
});

export default Quote;