import { makeAutoObservable, runInAction } from "mobx";
import axios from "axios";

// STORES
import MainStore from "../Main/MainStore.js";

// MODULES
import NotificationsCenter from "../Main/NotificationsCenter.js";



class UserStore {
    isAvatarUpload = false;
    userData = {
        avatarURL: `${MainStore.SERVER_ADDRESS}/squad/avatars/download.gif`
    }

    constructor() {
        makeAutoObservable(this);
    }


    // initialize = async () => {
    //     await this.updateUserData();
    // }

    setUserData = (userData) => {
        runInAction(() => {
            this.userData = userData;
        });
    }

    resetURL = () => {
        runInAction(() => {
            this.userData.avatarURL = "http://default-squad.ru:8542/squad/avatars/default.gif";
        });
    }

    uploadAvatar = async (formData) => {
        runInAction(() => {
            this.isAvatarUpload = true;
        });

        try {
            const response = await axios.post(`${MainStore.SERVER_ADDRESS}/uploadAvatar`, formData,
                {
                    withCredentials: true
                });

            if (response.status === 200) {
                NotificationsCenter.ShowSuccess(response.data.message);
                this.resetURL();
            } else {
                NotificationsCenter.ShowError(response.data.message);
            }
        } catch (e) {
            NotificationsCenter.ShowError("Произошла ошибка при загрузке аватара");
            console.error("Ошибка при загрузке аватара:", e);
        }

        await this.updateUserData();

        runInAction(() => {
            this.isAvatarUpload = false;
        });
    }

    // updateAvatarURL = async () => {
    //     let response = (await axios.post(`${MainStore.SERVER_ADDRESS}/isExistAvatar`, {
    //         userId: this.userId
    //     })).data;

    //     if (response?.message) {
    //         console.log(response.message);
    //     }

    //     runInAction(() => {
    //         this.avatarURL = response.isExist ? `http://default-squad.ru:8542/squad/avatars/${this.userId}.jpg` : `http://default-squad.ru:8542/squad/avatars/default.jpg`;
    //     });
    // }

    updateUserData = async () => {
        let response = await axios.post(`${MainStore.SERVER_ADDRESS}/getUser`, {}, {
            withCredentials: true
        });

        if (!response?.data) {
            return;
        }
        
        runInAction(() => {
            this.userData = response.data;
        });
    }

    logout = async () => {
        await axios.get(`${MainStore.SERVER_ADDRESS}/logout`, {
            withCredentials: true
        });
    }
}

export default new UserStore();