import { makeAutoObservable, runInAction } from "mobx";
import axios from "axios";

// STORES
import MainStore from "../Main/MainStore.js";

// MODULES
import NotificationsCenter from "../Main/NotificationsCenter.js";



class UniverseEditItemsStore {
    items = [];

    constructor() {
        makeAutoObservable(this);
    }

    getItems = async () => {
        let response = await axios.post(`${MainStore.SERVER_ADDRESS}/universe/getItemsForEdit`, {}, {
            withCredentials: true
        });

        if (!response?.data) {
            return;
        }

        runInAction(() => {
            this.items = response.data.items;
        });
    }

    updateItem = async (itemId, newData) => {
        let response = await axios.post(`${MainStore.SERVER_ADDRESS}/universe/updateItem`, {
            itemId,
            newData
        }, {
            withCredentials: true
        });

        if (response?.data?.message) {
            NotificationsCenter.ShowSuccess(response.data.message);
        }
    }

    deleteItem = async (itemId) => {
        let response = await axios.post(`${MainStore.SERVER_ADDRESS}/universe/deleteItem`, {
            itemId
        }, {
            withCredentials: true
        });

        if (!response?.data) {
            return;
        }

        if (response.data?.message) {
            NotificationsCenter.ShowSuccess(response.message);
        }
    }
}

export default new UniverseEditItemsStore();