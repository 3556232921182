import React, { useState } from "react";
import { HeartFill, LightningFill, Radioactive, ShieldFill } from "react-bootstrap-icons";



const Card = () => {
    const [cardName, setCardName] = useState("ЛИСИМП");
    const [cardNameColor, setCardNameColor] = useState("#4169e1");
    const [description, setDescription] = useState("");
    const [imageURL, setImageURL] = useState("https://sun9-51.userapi.com/impf/c844520/v844520967/86542/wiEVeLVyeaM.jpg?size=539x449&quality=96&sign=d3719ed449b4112a9be12ef266d751e8&type=album");
    const [stats, setStats] = useState("");
    const [rarity, setRarity] = useState("РЕДКАЯ");
    const [rarityColor, setRarityColor] = useState("#ffa500");
    const [hp, setHp] = useState(30);
    const [armor, setArmor] = useState(10);
    const [attack, setAttack] = useState(15);
    const [colorStats, setColorStats] = useState("#ffffff");


	return (
        <div className="wrapper" style={{color: "white", textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center"}}>
            <div style={{display: "flex", alignItems: "baseline"}}>
                <div style={{marginRight: 5}}>
                    Имя карты
                </div>
                <input style={{marginTop: 20}} type="text" onChange={(event) => setCardName(event.target.value)} value={cardName} />
            </div>
            <div style={{display: "flex", alignItems: "baseline"}}>
                <div style={{marginRight: 5}}>
                    Цвет имени карты
                </div>
                <input style={{marginTop: 10}} type="color" onChange={(event) => setCardNameColor(event.target.value)} value={cardNameColor} />
            </div>
            <div style={{display: "flex", alignItems: "baseline"}}>
                <div style={{marginRight: 5}}>
                    Описание
                </div>
                <input style={{marginTop: 10}} type="text" onChange={(event) => setDescription(event.target.value)} value={description} />
            </div>
            <div style={{display: "flex", alignItems: "baseline"}}>
                <div style={{marginRight: 5}}>
                    Картинка
                </div>
                <input style={{marginTop: 10}} type="text" onChange={(event) => setImageURL(event.target.value)} value={imageURL} />
            </div>
            <div style={{display: "flex", alignItems: "baseline"}}>
                <div style={{marginRight: 5}}>
                    Характеристики
                </div>
                <input style={{marginTop: 10}} type="text" onChange={(event) => setStats(event.target.value)} value={stats} />
            </div>
            <div style={{display: "flex", alignItems: "baseline"}}>
                <div style={{marginRight: 5}}>
                    Редкость
                </div>
                <input style={{marginTop: 10}} type="text" onChange={(event) => setRarity(event.target.value)} value={rarity} />
            </div>
            <div style={{display: "flex", alignItems: "baseline"}}>
                <div style={{marginRight: 5}}>
                    Цвет редкости
                </div>
                <input style={{marginTop: 10}} type="color" onChange={(event) => setRarityColor(event.target.value)} value={rarityColor} />
            </div>
            <div style={{display: "flex", alignItems: "baseline"}}>
                <div style={{marginRight: 5}}>
                    Здоровье
                </div>
                <input style={{marginTop: 10}} type="text" onChange={(event) => setHp(event.target.value)} value={hp} />
            </div>
            <div style={{display: "flex", alignItems: "baseline"}}>
                <div style={{marginRight: 5}}>
                    Защита
                </div>
                <input style={{marginTop: 10}} type="text" onChange={(event) => setArmor(event.target.value)} value={armor} />
            </div>
            <div style={{display: "flex", alignItems: "baseline"}}>
                <div style={{marginRight: 5}}>
                    Атака
                </div>
                <input style={{marginTop: 10}} type="text" onChange={(event) => setAttack(event.target.value)} value={attack} />
            </div>
            <div style={{display: "flex", alignItems: "baseline"}}>
                <div style={{marginRight: 5}}>
                    Цвет значений
                </div>
                <input style={{marginTop: 10}} type="color" onChange={(event) => setColorStats(event.target.value)} value={colorStats} />
            </div>
            
            <h3 style={{marginTop: 20}}></h3>
            <div class="outerCard">
                <h5 style={{color: cardNameColor}}>{cardName}</h5>
                <img height="100%" width="100%" class="image" src={imageURL} />
                <div className="card_parent_stats">
                    <div className="card_stats">
                        <ul style={{listStyle: "none", justifyContent: "center", padding: 5, color: colorStats}}>
                            <li>
                                <HeartFill size={20} color="red" /> {hp}
                            </li>
                            <li>
                                <ShieldFill size={20} color="#05f" /> {armor}
                            </li>
                            <li>
                                <LightningFill size={20} color="yellow" /> {attack}
                            </li>
                            {/* <li>
                                <Radioactive size={20} color="yellow" />
                            </li> */}
                        </ul>
                    </div>
                </div>
                <div class="card_info">
                    <div class="card_block">
                        <i className="card_block_title">ОПИСАНИЕ</i>
                        <hr className="card_hr"></hr>
                        <div className="card_block_text">
                            {description}
                        </div>
                    </div>
                    <div class="card_block">
                        <i className="card_block_title">ХАРАКТЕРИСТИКИ</i>
                        <hr className="card_hr"></hr>
                        <div className="card_block_text">
                            {stats}
                        </div>
                    </div>
                </div>
                <div id="cardFooter" style={{color: rarityColor}}>{rarity}</div>
            </div>
        </div>
	);
};

export default Card;