import {makeAutoObservable, runInAction} from "mobx";
import axios from "axios";



class MainStore {
    ROOT_DOMAIN = "http://default-squad.ru";
    SERVER_PORT = 8542;
    SERVER_ADDRESS = `${this.ROOT_DOMAIN}:${this.SERVER_PORT}`;


    constructor() {
        makeAutoObservable(this);
    }

    shieldingElement = (text, element, replace) => {
        return text.replace(new RegExp(`\\((\\${element})\\)|\\${element}(.*?)\\${element}`, "g"), replace);
    }

    textFormatterOnlyText = (text) => {
        text = text.replace(/\\n/g, "<br>");

        text = this.shieldingElement(text, "*", "$1<b>$2</b>");
        text = this.shieldingElement(text, "%", "$1<em>$2</em>");
        text = this.shieldingElement(text, "[code]", "$1<code>$2</code>");
        text = this.shieldingElement(text, "_", "$1<u>$2</u>");
        // text = this.shieldingElement(text, "[color:orange]", "$1<span style=color:orange>$2</span>");
        // text = this.shieldingElement(text, "[color:green]", "$1<span style=color:green>$2</span>");
        // text = this.shieldingElement(text, "[color:red]", "$1<span style=color:red>$2</span>");

        // text = text.replace(/((http|https):\/\/[^\s]+)/g, '<a href="$1" target="_blank">$1</a>');
        
        return text;
    }

    textFormatter = (text) => {
        text = this.textFormatterOnlyText(text);

        text.replace("php", "");

        return (
            <div dangerouslySetInnerHTML={{ __html: text }} />
        );
    }

    // async GetServerPort () {
    //     let port = (await axios.get(`${this.ROOT_DOMAIN}:5000/getPort/DefaultSquad`)).data.port;

    //     runInAction(() => {
    //        this.SERVER_PORT = port;
    //        this.SERVER_ADDRESS = `${this.ROOT_DOMAIN}:${this.SERVER_PORT}`;
    //     });
    // }
}

export default new MainStore();