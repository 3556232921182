import { toast } from "react-toastify";



class NC {
    ShowSuccess(text) {
        toast.success(text, {
            draggable: false,
            theme: localStorage.getItem("theme")
        });
    }

    ShowInfo(text) {
        toast.info(text, {
            draggable: false,
            theme: localStorage.getItem("theme")
        });
    }

    ShowWarn(text) {
        toast.warn(text, {
            draggable: false,
            theme: localStorage.getItem("theme")
        });
    }

    ShowError(text) {
        toast.error(text, {
            draggable: false,
            theme: localStorage.getItem("theme")
        });
    }
}

export default new NC();
