// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_item__6VZvb {
    flex: 1 1;
}

table {
    border-collapse: collapse;
    max-width: 640px;
}

.style_rdTable__gYHff thead tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    background-color: rgba(101, 163, 60, 0.4);
    border-radius: 4px 4px 0 0;
    color: #375821;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 11px;
}

.style_rdTable__gYHff tr {
    display: flex;
    align-items: center;
    padding: 15px;
    gap: 10px;
}

.style_rdTable__gYHff tr td, .style_rdTable__gYHff tr th {
    flex: 1 1;
}

.style_rdTable__cell64__UfK0C {
    flex: 0 0 64px !important;
}

.style_light__60qLr {
    background: #4d5154;
}

.style_dark__tdCpp {
    background: #2b2f32;
}

.style_itemTexture__8pBVK {
    display: flex;
    justify-content: center;
    width: 16px;
    height: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/Wheat/Items/ItemTopAmount/style.module.css"],"names":[],"mappings":"AAAA;IACI,SAAO;AACX;;AAEA;IACI,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,4CAA4C;IAC5C,yCAAyC;IACzC,0BAA0B;IAC1B,cAAc;IACd,yBAAyB;IACzB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,SAAS;AACb;;AAEA;IACI,SAAO;AACX;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,WAAW;IACX,YAAY;AAChB","sourcesContent":[".item {\r\n    flex: 1;\r\n}\r\n\r\ntable {\r\n    border-collapse: collapse;\r\n    max-width: 640px;\r\n}\r\n\r\n.rdTable thead tr {\r\n    border-bottom: 1px solid rgba(0, 0, 0, 0.05);\r\n    background-color: rgba(101, 163, 60, 0.4);\r\n    border-radius: 4px 4px 0 0;\r\n    color: #375821;\r\n    text-transform: uppercase;\r\n    letter-spacing: 1px;\r\n    font-size: 11px;\r\n}\r\n\r\n.rdTable tr {\r\n    display: flex;\r\n    align-items: center;\r\n    padding: 15px;\r\n    gap: 10px;\r\n}\r\n\r\n.rdTable tr td, .rdTable tr th {\r\n    flex: 1;\r\n}\r\n\r\n.rdTable__cell64 {\r\n    flex: 0 0 64px !important;\r\n}\r\n\r\n.light {\r\n    background: #4d5154;\r\n}\r\n\r\n.dark {\r\n    background: #2b2f32;\r\n}\r\n\r\n.itemTexture {\r\n    display: flex;\r\n    justify-content: center;\r\n    width: 16px;\r\n    height: 16px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `style_item__6VZvb`,
	"rdTable": `style_rdTable__gYHff`,
	"rdTable__cell64": `style_rdTable__cell64__UfK0C`,
	"light": `style_light__60qLr`,
	"dark": `style_dark__tdCpp`,
	"itemTexture": `style_itemTexture__8pBVK`
};
export default ___CSS_LOADER_EXPORT___;
