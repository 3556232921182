import { observer } from "mobx-react";
import { Image, Button, Modal, Form } from "react-bootstrap";
import { useState, useContext } from "react";
import s from "../../../pages/Squad/members.module.css";

// STORES
import { UserStoreContext, TitleStoreContext } from "../../../App.js";



const Member = observer(({ member }) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [title, setTitle] = useState("");
    const [newTitle, setNewTitle] = useState("");
    const [newTitleColor, setNewTitleColor] = useState("#ffffff");
    const [newTitleRarity, setNewTitleRarity] = useState(0);

    const User = useContext(UserStoreContext);
    const TitleStore = useContext(TitleStoreContext);

    const openModalToggle = () => {
        setModalOpen(!isModalOpen);
    }

    const selectChange = (event) => {
        setTitle(event.target.value);
    }

    const setTitleHandle = async () => {
        await TitleStore.setUserTitle(member.login, title);
        setTitle("");
    }

    // const addTitleHandle = () => {
    //
    // }

    const createTitleHandle = async () => {
        await TitleStore.createTitle(newTitle, newTitleColor, newTitleRarity);
    }

    return (
        <div className={s.member}>
            <div className="member__avatar">
                <Image height={128} width={128} src={member.avatarURL} title={member.login + ' Avatar'} rounded />
            </div>
            <div className={s.memberData}>
                <div className={s.memberTop}>
                    <div className={s.memberHeader}>
                        <div className={s.memberLogin}>{member.login}</div>
                        {TitleStore.membersTitlesObj[member.login] !== null && <div className={`member__title-${TitleStore.membersTitlesObj[member.login].rarity}`} style={{borderColor: TitleStore.membersTitlesObj[member.login].color}}>{TitleStore.membersTitlesObj[member.login].title}</div>}
                    </div>
                    {User.userData.accessLevel === 5 &&
                    <div className="buttons-group">
                        <Button className="button" variant={"outline-secondary"} onClick={openModalToggle}>
                            Edit
                        </Button>
                    </div>}
                </div>
                <div className={s.memberBottom}>
                    {member.isBanned ? <div className={s.banned}>Banned</div> : ""}
                </div>
                {/* {member.titles.length !== 0 &&
                <div className="member__titles">
                    {member.titles.map((title) => {
                        return (
                            <div key={title.id} className={`member__title-${title.rarity}`} style={{borderColor: title.color}}>
                                {title.title}
                            </div>
                        )
                    })}
                </div>
                } */}
            </div>

            <Modal
                show={isModalOpen}
                backdrop="static"
                keyboard={false}
                >
                <Modal.Header>
                    <Modal.Title>
                        Редактирование {member.login}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="edit-user">
                        <div>
                            <h5>Присвоить титул (ADD)</h5>
                            <Form.Select id="add-title" size="sm" onChange={selectChange}>
                                <option key={0} value="">Выбрать</option>
                                {TitleStore.titlesDB.map((title, index) => {
                                    return <option key={index} value={title.title}>{title.title}</option>;
                                })}
                            </Form.Select>
                        </div>
                        <div>
                            <h5>Установить титул (SET)</h5>
                            <Form.Select id="set-title" size="sm" onChange={selectChange}>
                                <option key={0} value="">Без титула</option>
                                {TitleStore.titlesDB.map((title, index) => {
                                    return <option key={index} value={title.title}>{title.title}</option>;
                                })}
                            </Form.Select>
                        </div>
                        <div className="create-title">
                            <h5>Создать титул</h5>
                            Титул <input onChange={(event) => {setNewTitle(event.target.value)}} type="text" />
                            Цвет <input onChange={(event) => {setNewTitleColor(event.target.value)}} type="color" />
                            Редкость (0-4) <input onChange={(event) => {setNewTitleRarity(event.target.value)}} type="text" />
                            <div className="buttons-group">
                                <Button onClick={createTitleHandle}>
                                    Создать
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="buttons-group">
                        <Button onClick={openModalToggle}>
                            Назад
                        </Button>
                        <Button onClick={setTitleHandle}>
                            Применить
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
});

export default Member;