import React from 'react';
import { useTheme } from './ThemeContext';
import { Button } from "react-bootstrap";
import { MoonStars, Sun } from "react-bootstrap-icons";



const ThemeToggleButton = () => {
    const { theme, setTheme } = useTheme();

  	const toggleTheme = () => {
		setTheme(theme === 'dark' ? 'light' : 'dark');
  	}

  	return (
    	<Button className="button" variant="outline-secondary" onClick={toggleTheme}>
        	{theme === "light" ? <MoonStars /> : <Sun />}     
    	</Button>
  	);
}

export default ThemeToggleButton;