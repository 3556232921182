import { observer } from "mobx-react";

import SquadProtected from "../../modules/Squad/SquadProtected.jsx";

import Header from "../../components/Squad/Header/Header.jsx";
import HistoryList from "../../components/Squad/History/HistoryList.jsx";
import Footer from "../../components/Squad/Footer/Footer.jsx";



const HistoryPage = observer(() => {
    return (
        <div className="wrapper__content">
            <HistoryList />
        </div>
    );
});

export default HistoryPage;