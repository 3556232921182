// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.anton-game-wrapper {
    font-family: ManropeMedium, "Segoe UI", sans-serif;
    font-size: 3em;

    width: auto;
    height: 100vh;
    display: grid;
    align-items: center;
    justify-items: center;
}

.anton-game-content {
    display: grid;
    justify-items: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/Games/Project416/Project416.css"],"names":[],"mappings":"AAAA;IACI,kDAAkD;IAClD,cAAc;;IAEd,WAAW;IACX,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,qBAAqB;AACzB","sourcesContent":[".anton-game-wrapper {\r\n    font-family: ManropeMedium, \"Segoe UI\", sans-serif;\r\n    font-size: 3em;\r\n\r\n    width: auto;\r\n    height: 100vh;\r\n    display: grid;\r\n    align-items: center;\r\n    justify-items: center;\r\n}\r\n\r\n.anton-game-content {\r\n    display: grid;\r\n    justify-items: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
