import {makeAutoObservable, runInAction} from "mobx";
import axios from "axios";


import MainStore from "../Main/MainStore.js";

import NotificationsCenter from "../Main/NotificationsCenter.js";



class EventStore {
    isLoading = true;
    members = [];

    constructor() {
        makeAutoObservable(this);
    }

    registration = async (nickname) => {
        const response = await axios.post(MainStore.SERVER_ADDRESS + "/event/registration", { nickname });

        if (response.data.ok) {
            NotificationsCenter.ShowSuccess(response.data.message);
            await this.getMembers();
        } else {
            NotificationsCenter.ShowWarn(response.data.message);
        }
    }

    getMembers = async () => {
        const response = await axios.get(MainStore.SERVER_ADDRESS + "/event/getMembers");

        runInAction(() => {
            this.members = response.data;
            this.isLoading = false;
        });
    }
}

export default new EventStore();