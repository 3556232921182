// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.members_members__Q\\+Ru2 {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: .5em;
}

.members_member__6U\\+et {
    display: grid;
    grid-template-columns: auto 1fr;
}

.members_memberData__yWEFE {
    display: grid;
    grid-template-rows: auto 1fr;
    row-gap: .25em;
    padding: 0 .25em;
}

.members_memberTop__prYbJ {
    display: flex;
    justify-content: space-between;
    background: rgba(40, 40, 40, 0.8);
    border-radius: .25em;
}

.members_memberBottom__alQqT {
    display: flex;
    background: rgba(40, 40, 40, 0.8);
    border-radius: .25em;
}

.members_memberHeader__-xrfh {
    display: flex;
    align-items: center;
}

.members_memberLogin__Xw3m2 {
    margin: 0 .4em;
}

.members_banned__Bpzl1 {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;
    width: 100%;

    padding: .5em;
    color: red !important;
    text-transform: uppercase;

    border: 1px solid red;
    border-radius: .25em;
}`, "",{"version":3,"sources":["webpack://./src/pages/Squad/members.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,qCAAqC;IACrC,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,+BAA+B;AACnC;;AAEA;IACI,aAAa;IACb,4BAA4B;IAC5B,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,iCAAiC;IACjC,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,iCAAiC;IACjC,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;IAEnB,YAAY;IACZ,WAAW;;IAEX,aAAa;IACb,qBAAqB;IACrB,yBAAyB;;IAEzB,qBAAqB;IACrB,oBAAoB;AACxB","sourcesContent":[".members {\r\n    display: grid;\r\n    grid-template-columns: auto auto auto;\r\n    grid-gap: .5em;\r\n}\r\n\r\n.member {\r\n    display: grid;\r\n    grid-template-columns: auto 1fr;\r\n}\r\n\r\n.memberData {\r\n    display: grid;\r\n    grid-template-rows: auto 1fr;\r\n    row-gap: .25em;\r\n    padding: 0 .25em;\r\n}\r\n\r\n.memberTop {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    background: rgba(40, 40, 40, 0.8);\r\n    border-radius: .25em;\r\n}\r\n\r\n.memberBottom {\r\n    display: flex;\r\n    background: rgba(40, 40, 40, 0.8);\r\n    border-radius: .25em;\r\n}\r\n\r\n.memberHeader {\r\n    display: flex;\r\n    align-items: center;\r\n}\r\n\r\n.memberLogin {\r\n    margin: 0 .4em;\r\n}\r\n\r\n.banned {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n\r\n    height: 100%;\r\n    width: 100%;\r\n\r\n    padding: .5em;\r\n    color: red !important;\r\n    text-transform: uppercase;\r\n\r\n    border: 1px solid red;\r\n    border-radius: .25em;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"members": `members_members__Q+Ru2`,
	"member": `members_member__6U+et`,
	"memberData": `members_memberData__yWEFE`,
	"memberTop": `members_memberTop__prYbJ`,
	"memberBottom": `members_memberBottom__alQqT`,
	"memberHeader": `members_memberHeader__-xrfh`,
	"memberLogin": `members_memberLogin__Xw3m2`,
	"banned": `members_banned__Bpzl1`
};
export default ___CSS_LOADER_EXPORT___;
