import {makeAutoObservable, runInAction} from "mobx";
import axios from "axios";

import MainStore from "../../../modules/Main/MainStore.js";


class WheatItemsStore {
    items = [];
    itemsSorted = [];
    itemChartData = {};

    isSorted = false;
    itemsLoading = false;


    constructor() {
        makeAutoObservable(this);
    }

    onChangeSearch = (event) => {
        this.updateItems(event.target.value);
    }

    updateItems = (query) => {
        if (query !== "") {
            const regex = new RegExp(query, "i");

            runInAction(() => {
                this.itemsSorted = this.items.filter(item => regex.test(item.name || item.id));
                this.isSorted = true;
            });
        } else {
            runInAction(() => {
                this.isSorted = false;
            });
        }
    }

    getItemsProportionalDistribution = async () => {
        runInAction(() => {
            this.itemsLoading = true;
        });

        const response = await axios.get(MainStore.SERVER_ADDRESS + "/wheat/items/getProportionalDistribution");

        runInAction(() => {
            this.items = response.data.items;
            this.itemsLoading = false;
        });
    }

    getItemsTopAmount = async () => {
        runInAction(() => {
            this.itemsLoading = true;
        });

        const response = await axios.get(MainStore.SERVER_ADDRESS + "/wheat/items/getTopAmount");

        runInAction(() => {
            this.items = response.data.items;
            this.itemsLoading = false;
        });
    }

    getItemChartData = async (itemId) => {
        runInAction(() => {
            this.itemsLoading = true;
        });

        const response = await axios.get(MainStore.SERVER_ADDRESS + `/wheat/items/getItemData/${itemId}`);

        console.log("Data from server:", response.data);

        runInAction(() => {
            this.itemChartData = response.data;
            this.itemsLoading = false;
        });
    }

    getTopAmountDynamics = async () => {
        runInAction(() => {
            this.itemsLoading = true;
        });

        const response = await axios.get(MainStore.SERVER_ADDRESS + "/wheat/items/getTopAmountDynamics");

        runInAction(() => {
            this.items = response.data.items;
            this.itemsLoading = false;
        });
    }
}

export default new WheatItemsStore();