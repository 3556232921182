import "./Project416.css";

import React from "react";
import {observer} from "mobx-react";

import GoogleButtonSignIn from "./GoogleButtonSignIn.jsx";



const Project416 = observer(() => {
    return (
        <div className="anton-game-wrapper">
            <div className="anton-game-content">
                <div className="anton-game-title">
                    Project 416
                </div>
                <div className="auth-form">
                    <GoogleButtonSignIn/>
                </div>
            </div>
        </div>
    );
});

export default Project416;