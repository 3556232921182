import { observer } from "mobx-react";

// COMPONENTS
import UniverseHeader from "../../../components/Universe/Header/UniverseHeader.jsx";
import StorageList from "../../../components/Universe/Storage/StorageList.jsx";
import UniverseFooter from "../../../components/Universe/Footer/UniverseFooter.jsx";



const StoragePage = observer(() => {
    return (
        <div className="universe__wrapper__content">
            <UniverseHeader />
            <StorageList />
            <UniverseFooter />
        </div>
    );
});

export default StoragePage;