import MainStore from "../../../modules/Main/MainStore.js";



const Item = ({ item }) => {
    return (
        <div className="s-container item-container">
            <div className="itemImage">
                <img src={`${MainStore.SERVER_ADDRESS}/wheat/items/${item.id}.png`} width={16} height={16} />
            </div>
            <div className="itemName">
                {item.name || item.id.split("_").map((word) => word[0].toUpperCase() + word.substring(1)).join(" ")}
            </div>
            <div className="itemAmount">
                {item.percent}%
            </div>
        </div>
    );
}

export default Item;