import { observer } from "mobx-react";
import { Button } from "react-bootstrap";

// STORES
import QuotesStore from "./QuotesStore.js";



const SuggestedQuote = observer((quote) => {
    const QuoteAdd = async () => {
        await QuotesStore.confirmQuote(quote.id);
        await QuotesStore.updateQuotes();
    }

    const QuoteDelete = async () => {
        await QuotesStore.declineQuote(quote.id);
        await QuotesStore.updateQuotes();
    }


    return (
        <div className="quote">
            От {quote.whoSuggested}
            <div className="quote__text">
                {quote.text}
            </div>
            <div className="quote__bottom">
                <div className="quote__authors">
                    {quote.authors}
                </div>
                <div className="quote__created-at">
                    {quote.createdAt}
                </div>
            </div>
            <div className="quote__review">
                <Button variant="outline-success" onClick={QuoteAdd}>
                    Принять
                </Button>
                <Button style={{marginLeft: 5}} variant="outline-danger" onClick={QuoteDelete}>
                    Отклонить
                </Button>
            </div>
        </div>
    );
});

export default SuggestedQuote;