import { observer } from "mobx-react";
import { useContext } from "react";

// STORES
import { PlayerStoreContext } from "../../../App.js";

const UniverseProfileContent = observer(() => {
  const Player = useContext(PlayerStoreContext);

  return (
    <div className="universe__page-body">
      <span className="tooltip">
        <button className="tooltip-toggle" type="button">
          <svg
            className="tooltip-icon"
            width="1"
            height="9"
            viewBox="0 0 1 9"
            fill="blue"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#a)">
              <path d="M0 9h1V3H0v6ZM1 0H0v1h1V0Z" />
            </g>
          </svg>
        </button>
        <span className="tooltip-text">
          Укажите количество детей, которые будут с вами, возраст которых от 6
          до 18 лет. Дети до 6 лет размещаются бесплатно.
        </span>
      </span>

      <div className="pairs">
        <dl className="pair">
          <dt>Игровой ID:</dt>
          <dd>{Player.playerData.botId}</dd>
        </dl>
        <dl className="pair">
          <dt>Рейтинг:</dt>
          <dd>{Player.playerData.rating.toLocaleString()}</dd>
        </dl>
        <dl className="pair">
          <dt>Лига:</dt>
          <dd>{Player.playerData.league}</dd>
        </dl>
        <dl className="pair">
          <dt>Динамический рейтинг:</dt>
          <dd>...</dd>
        </dl>
        <dl className="pair">
          <dt>Уровень:</dt>
          <dd>{Player.playerData.level.amount.toLocaleString()}</dd>
        </dl>
        <dl className="pair">
          <dt>Опыт:</dt>
          <dd>{Player.playerData.level.exp.toLocaleString()}</dd>
        </dl>
        <dl className="pair">
          <dt>Редчайший дроп:</dt>
          <dd>
            {Player.playerData.rarestDrop.name} (
            {Player.playerData.rarestDrop.chance["$numberDecimal"]}%)
          </dd>
        </dl>
        <dl className="pair">
          <dt>Регистрация:</dt>
          <dd>{Player.playerData.regDate}</dd>
        </dl>
        <dl className="pair">
          <dt>Репутация:</dt>
          <dd>{Player.playerData.reputation}</dd>
        </dl>
      </div>
    </div>
  );
});

export default UniverseProfileContent;
