import { makeAutoObservable, runInAction } from "mobx";
import axios from "axios";

// STORES
import MainStore from "../../../../../src/modules/Main/MainStore.js";
import NotificationsCenter from "../../../../modules/Main/NotificationsCenter.js";



class DisassemblerStore {
    isLoading = true;
    recipeInfo = {};
    itemData = {};
    files = [];

    sortedItems = [];
    isSorted = false;

    ingredientsMap = new Map();

    constructor() {
        makeAutoObservable(this);
    }

    setRecipeInfo = (recipeInfo) => {
        runInAction(() => {
            this.recipeInfo = recipeInfo;
        });
    }

    setItemData = (itemData) => {
        runInAction(() => {
            this.itemData = itemData;
        });
    }

    setFiles = (files) => {
        runInAction(() => {
            this.files = files;
            this.isLoading = false;
        });
    }

    getFiles = async () => {
        const response = await axios.get(`${MainStore.SERVER_ADDRESS}/gtnh/getFiles`);
        const { files } = response.data;

        this.setFiles(files);
    }

    getItemData = async (itemName, amountNeed) => {
        const response = await axios.get(`${MainStore.SERVER_ADDRESS}/gtnh/getItemData/${itemName}/${amountNeed}`);

        console.log(response.data);
        if (response.data?.message) {
            NotificationsCenter.ShowWarn(response.data.message);
            return;
        }

        const { itemData } = response.data;
        this.setItemData(itemData);
    }

    getRecipeInfo = async (itemName) => {
        const response = await axios.get(`${MainStore.SERVER_ADDRESS}/gtnh/getItemInfo/${itemName}`);
        const { itemInfo } = response.data;
        this.setRecipeInfo(itemInfo);
    }

    sort = (itemName) => {
        console.log(itemName);

        if (itemName !== "") {
            const regex = new RegExp(itemName, "i");

            runInAction(() => {
                this.sortedItems = this.files.filter(file => regex.test(file));

                this.isSorted = true;
            });
        } else {
            runInAction(() => {
                this.isSorted = false;
            });
        }
    }

    setInMap = (index, value) => {
        runInAction(() => {
            this.ingredientsMap.set(index, value);
        });
    }

    clearMap = () => {
        runInAction(() => {
            this.ingredientsMap.clear();
        });
    }

    sendItemData = async (itemName, amountResult, amountEnergy, amountCreationTime, machineName, isFluid, isBasic, amountEMC) => {
        const simpleArray = [];

        for (const [key, value] of this.ingredientsMap.entries()) {
            simpleArray.push([key, value]);
        }

        let response = await axios.post(`${MainStore.SERVER_ADDRESS}/gtnh/insertItem`, {
            ingredients: simpleArray,
            itemName, amountResult, amountEnergy, amountCreationTime, machineName, isFluid, isBasic, amountEMC
        }, {
            withCredentials: true
        });

        this.clearMap();

        return response.data;
    }
}

export default new DisassemblerStore();