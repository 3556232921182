import { observer } from "mobx-react";
import { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";



const Chart = observer(({ index, cluster, chartData }) => {
    useLayoutEffect(() => {
        let root;
        try {
            root = am5.Root.new(`chartdiv${index}`);
        } catch (e) {
            console.log(`\n`.repeat(50), e);
            return;
        }
        
        root.setThemes([am5themes_Animated.new(root)]);

        var chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: true,
                panY: true,
                wheelX: "panX",
                wheelY: "zoomX",
                pinchZoomX: true,
                paddingLeft: 0,
            })
        );
        
        var cursor = chart.set(
            "cursor",
            am5xy.XYCursor.new(root, {
                behavior: "none",
            })
        );
        cursor.lineY.set("visible", false);

        function getData() {
            let data = [];

            for (let t = 0; t < chartData.timestamps.length; t++) {
                data.push({
                    date: Number(chartData.timestamps[t]),
                    value: chartData.positions[t]
                });
            }

            return data;
        }

        // Create axes
        // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
        var xAxis = chart.xAxes.push(
            am5xy.ValueAxis.new(root, {
                baseInterval: {
                    timeUnit: "day",
                    count: 1,
                },
                renderer: am5xy.AxisRendererX.new(root, {
                    minorGridEnabled: true,
                }),
                tooltip: am5.Tooltip.new(root, {}),
            })
        );

        xAxis.get("renderer").labels.template.setAll({
            fill: root.interfaceColors.get("alternativeText")
        });

        var yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererY.new(root, {
                    pan: "zoom",
                }),
            })
        );

        yAxis.get("renderer").labels.template.setAll({
            fill: root.interfaceColors.get("alternativeText")
        });

        // Add series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
        var series = chart.series.push(
            am5xy.LineSeries.new(root, {
                name: "Series",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "value",
                valueXField: "date",
                tooltip: am5.Tooltip.new(root, {
                    labelText: "{valueY}",
                }),
            })
        );

        // Add scrollbar
        // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
        chart.set(
            "scrollbarX",
            am5.Scrollbar.new(root, {
                orientation: "horizontal",
            })
        );

        // Set data
        var data = getData();

        chart.get("colors").set("colors", [
            am5.color(0x095256),
            am5.color(0x087f8c),
            am5.color(0x5aaa95),
            am5.color(0x86a873),
            am5.color(0xbb9f06)
        ]);

        series.data.processor = am5.DataProcessor.new(root, {
            numericFields: ["date", "value"]
        });

        series.data.setAll(data);

        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        series.appear(1000);
        chart.appear(1000, 100);

        return () => {
            root.dispose();
        }
    }, []);


    return (
        <div className="wb__chart">
            <h4>{cluster}</h4>
            <div id={`chartdiv${index}`} style={{ width: "400px", height: "400px" }}></div>
        </div>
    );
});

export default Chart;