import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import { Image, Form, Button } from "react-bootstrap";

// COMPONENTS
import Header from "../../components/Squad/Header/Header.jsx";
import AvatarUploadForm from "../../modules/Squad/Profile/AvatarUploadForm.jsx";
import Blog from "../../components/Squad/Profile/Blog.jsx";
import BlogPost from "../../components/Squad/Profile/BlogPost/BlogPost.jsx";
import Footer from "../../components/Squad/Footer/Footer.jsx";


// STORES
import { UserStoreContext, TitleStoreContext } from "../../App.js";



const ProfilePage = observer(() => {
    const [blogText, setBlogText] = useState("");
    const [selectTitle, setSelectTitle] = useState("");

    const navigate = useNavigate();
    const User = useContext(UserStoreContext);
    const TitleStore = useContext(TitleStoreContext);

    useEffect(() => {
        async function fetchData() {
            await TitleStore.getUsersTitles();
            await TitleStore.getUserTitles();
        }

        fetchData();
    }, []);
    

    // TODO: Удалить если в Header уже есть этот функционал
    // const LogoutHandler = () => {
    //     localStorage.removeItem("user-id");
    //     localStorage.removeItem("squad-access-token");
    //     localStorage.removeItem("squad-refresh-token");
    //     navigate("/login");
    // }

    const GetLangAccessLevel = () => {
        const langAL = [
            <a className="access-level__color__0">Нормальный</a>,
            <a className="access-level__color__1">Dev</a>,
            <a className="access-level__color__2">Dev</a>,
            <a className="access-level__color__3">Dev</a>,
            <a className="access-level__color__4">Наивысший</a>,
            <a className="access-level__color__5">Наивысший</a>
        ]

        return langAL[User.userData.accessLevel];
    }

    const GetQuoteStatus = () => {
        let statuses = {
            0: "Новичок в цитатах",
            10: "Идущий к познанию цитат",
            25: "Растущий бамбук",
            50: "Бамбуковый побег",
            75: "Бамбуковая гора",
            100: "Картофельный трансформатор",
            150: "Диоритовый трансформатор",
            200: "Учитель цитат",
            250: "Пражский бульбук",
            300: "Экстракт знаний"
        }

        let status = '';
        let quotes = User.userData.valutionsCount;

        for (const [key, value] of Object.entries(statuses)) {
            if (quotes >= key) {
                status = value;
                continue;
            }
        }

        return status;
    }

    const selectChange = (event) => {
        setSelectTitle(event.target.value);
    }

    const submitChangeTitle = () => {
        TitleStore.setUserTitleByUser(selectTitle);
        setSelectTitle("");
    }
    

    return (
        <div className="wrapper__content">
            <div className="wrapper__container">
                <div className="top">
                    <Image src={User.userData.avatarURL} className="profile__avatar-image" />
                    {/* Заменить некоторое на dl или что-то такое */}
                    <div className="profile__login">
                        <div className="profile__login-value">
                            {User.userData.login}
                        </div>
                        {TitleStore.membersTitlesObj[User.userData.login] !== null && <div className={`member__title-${TitleStore.membersTitlesObj[User.userData.login]?.rarity}`} style={{borderColor: TitleStore.membersTitlesObj[User.userData.login]?.color}}>{TitleStore.membersTitlesObj[User.userData.login]?.title}</div>}
                    </div>
                </div>
                <hr />
                <div className="bottom">
                    <div className="profile__userinfo">
                        <div>
                            Регистрация: {new Date(Number(User.userData.regTime)).toLocaleString()}
                        </div>
                        <div>
                            Оценено цитат: {User.userData.valutionsCount}
                        </div>
                        {User.userData.accessLevel === 5 &&
                        <div className="RP-SCRIPT">
                            <a className="RP-LINK" href="https://radiopotok.ru/radio/1714">Smooth Jazz Lounge</a>
                        </div>}
                        {/* <div>
                            Статус: {GetQuoteStatus()}
                        </div> */}
                    </div>
                    <AvatarUploadForm />
                    {/* Сделать в будущем по-другому, при открытии модального окна (по нажатию на титул) показывать возможные титулы
                    и при нажатии на титул менять его, супер изи */}
                    <div className="profile__change-title">
                        Изменить титул
                        <div style={{display: "flex", columnGap: 5}}>
                            <Form.Select id="edit-title" size="sm" onChange={selectChange}>
                                <option key={0} value="">Без титула</option>
                                {TitleStore.titles.map((title, index) => {
                                    return <option key={index} value={title.title}>{title.title}</option>;
                                })}
                            </Form.Select>
                            <div className="buttons-group">
                                <Button className="button" variant={"outline-secondary"} onClick={submitChangeTitle}>
                                    Применить
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <Blog />
            </div>
        </div>
    );
});

export default ProfilePage;