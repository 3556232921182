import "./style.module.css";


export const Table = ({columns, rows}) => {
    return (
        <table>
            <thead>
                <tr>
                    {columns.map(columnName => {
                        return <th scope="col">{columnName}</th>;
                    })}
                </tr>
            </thead>
            <tbody>
                {rows.map(row => {
                    return <tr>
                        {row.values.map(rowValue => {
                            return <td>{rowValue}</td>;
                        })}
                    </tr>;
                })}
            </tbody>
        </table>
    );
}