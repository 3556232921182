import s from "./post.module.css";
import {useContext, useState} from "react";

import NotificationsCenter from "../../../../modules/Main/NotificationsCenter.js";

// STORES
import {BlogStoreContext, TitleStoreContext} from "../../../../App.js";
import {Button} from "react-bootstrap";
import {ArrowDown, ArrowUp} from "react-bootstrap-icons";
import {useNavigate} from "react-router-dom";


const BlogPost = ({ post, isPublic }) => {
    const [voteTimeout, setVoteTimeout] = useState(false);

    const navigate = useNavigate();

    const TitleStore = useContext(TitleStoreContext);
    const BlogStore = useContext(BlogStoreContext);

    const DeletePostHandler = async () => {
        if (BlogStore.isBlogDeleting) {
            return NotificationsCenter.ShowWarn("Дождитесь удаления записи/поста");
        }

        await BlogStore.deleteBlogPost(post.Post.id);
    }

    const startVoteTimeout = () => {
        if (voteTimeout) {
            return false;
        }

        setVoteTimeout(true);

        setTimeout(() => {
            setVoteTimeout(false);
        }, 500);

        return true;
    }

    const sendLike = async () => {
        if (!startVoteTimeout()) {
            return NotificationsCenter.ShowWarn("Не так быстро");
        }

        await BlogStore.sendVoteFunction(post.Post.id, 1);
    }

    const sendDislike = async () => {
        if (!startVoteTimeout()) {
            return NotificationsCenter.ShowWarn("Не так быстро");
        }

        await BlogStore.sendVoteFunction(post.Post.id, 0);
    }

    const openComments = () => {
        return navigate(`/posts/${post.Post.id}`);
    }


    return ( // onClick={openComments}
        <div className={`${s.post} s-container ${post.Vote?.rm ? s.recommendationPost : ""}`} id={post.Post.id} >
            {isPublic &&
                <div className="post-author-info">
                    <div className={s.authorBox}>
                        <img className={s.avatar} src={post.User.avatar}
                             alt={"Avatar"}></img>
                        <a className="post-author-name" href={`#members/${post.User.userId}`} dir="auto">
                            {post.User.login}
                        </a>
                        <div>
                            {TitleStore.membersTitlesObj[post.User.login] !== null &&
                                <div className={`member__title-${TitleStore.membersTitlesObj[post.User.login].rarity}`}
                                     style={{borderColor: TitleStore.membersTitlesObj[post.User.login].color}}>{TitleStore.membersTitlesObj[post.User.login].title}
                                </div>}
                        </div>

                    </div>
                </div>}

            {isPublic ? <div className="post-text">{post.Post.text}</div> :
                <div className="post-text">{post.Post.text}</div>}
            <hr/>

            {isPublic &&
                <div className={s.voteButtonsBox}>
                    <button style={{marginRight: ".3em"}} className={`${post.Vote.isLike === true ? s.voted : ""} ${s.voteButton}`} onClick={sendLike}>
                        <ArrowUp/> x{post.Vote.likesAmount}</button><button className={`${post.Vote.isLike === false ? s.voted : ""} ${s.voteButton}`} onClick={sendDislike}>
                        <ArrowDown/> x{post.Vote.dislikesAmount}</button>
                </div>
            }

            <div style={{marginTop: ".5em"}}>
                <span className={s.postDate} title={new Date(Number(post.Post.createdAt)).toLocaleString()}>
                    {new Date(Number(post.Post.createdAt)).toLocaleString("ru", {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric'
                    })}{isPublic &&
                        <div style={{
                                display: "flex",
                                alignItems: "center",
                                columnGap: 5
                            }}>
                        </div>}
                </span>

                {!isPublic &&
                <div className="buttons-group" style={{margin: 0}}>
                    <Button
                        className="button" variant="outline-danger" onClick={DeletePostHandler}>
                        Удалить
                    </Button>
                </div>}
            </div>
        </div>
    );
}

export default BlogPost;