import { observer } from "mobx-react";

// COMPONENTS
import UniverseHeader from "../../../components/Universe/Header/UniverseHeader.jsx";
import MarketList from "../../../components/Universe/Market/MarketList.jsx";
import UniverseFooter from "../../../components/Universe/Footer/UniverseFooter.jsx";



const MarketPage = observer(() => {
    return (
        <div className="universe__wrapper__content">
            <UniverseHeader />
            <MarketList />
            <UniverseFooter />
        </div>
    );
});

export default MarketPage;