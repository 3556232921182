import style from "./CivilisationRecipes.module.css";



const CivilisationRecipes = () => {
    const recipes = [
        {
            multiblock: "Печь для кирпичей",
            recipes: [
                {
                    ingredients: [
                        {
                            name: "Блок глины",
                            amount: 1
                        }
                    ],
                    results: [
                        {
                            name: "Терракота",
                            amount: 1
                        }
                    ],
                    consumeFuel: 1,
                    time: 12
                }
            ]
        },
        {
            multiblock: "Печь для металлов",
            recipes: [
                {
                    ingredients: [
                        {
                            name: "Блок глины",
                            amount: 1
                        }
                    ],
                    results: [
                        {
                            name: "Терракота",
                            amount: 1
                        }
                    ],
                    consumeFuel: 1,
                    time: 12
                }
            ]
        },
        {
            multiblock: "Сборщик",
            recipes: [
                {
                    ingredients: [
                        {
                            name: "Блок глины",
                            amount: 1
                        }
                    ],
                    results: [
                        {
                            name: "Терракота",
                            amount: 1
                        }
                    ],
                    consumeFuel: 1,
                    time: 12
                }
            ]
        }
    ];

    return (
        <div className={style.wrapper}>
            <div className={style.multiblocksList}>
                {recipes.map(recipe => {
                    return (
                        <div className="multiblock">
                            <h2 className="multiblock-title">
                                {recipe.multiblock}
                            </h2>
                            <table>
                                <thead>
                                <tr>
                                    <td className="td-title">
                                        Ингридиенты
                                    </td>
                                    <td className="td-title">
                                        Результат
                                    </td>
                                    <td className="td-title">
                                        Время
                                    </td>
                                    <td className="td-title">
                                        Потребление
                                    </td>
                                    <td className="td-title">
                                        Всего топлива
                                    </td>
                                </tr>
                                </thead>
                                <tbody>
                                {recipe.recipes.map(recipe => {
                                    return (
                                        <tr>
                                            <td>
                                                {recipe.ingredients.map(ingredient => {
                                                    return (
                                                        <div>
                                                            x{ingredient.amount} {ingredient.name}
                                                        </div>
                                                    );
                                                })}
                                            </td>
                                            <td>
                                                {recipe.results.map(result => {
                                                    return (
                                                        <div>
                                                            x{result.amount} {result.name}
                                                        </div>
                                                    );
                                                })}
                                            </td>
                                            <td>
                                                {recipe.time}
                                            </td>
                                            <td>
                                                {recipe.consumeFuel}
                                            </td>
                                            <td>
                                                {recipe.time * recipe.consumeFuel}
                                            </td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                        </div>
                    );
                })}
            </div>

            <a href="/" className="footer" style={{color: "gray"}}>
                &copy; 2020-2024 DEFAULT-SQUAD.RU
            </a>
        </div>
    );
}

export default CivilisationRecipes;