import "./Civilisation.css";



const Civilisation = () => {
    const mutiblocksData = [
        {
            name: "Генератор",
            description: "Главный мультиблок. Генерирует тепло в радиусе 25 блоков.",
            stats: {
                type: "Генератор тепла",
                workingRange: 25,
                generatedHeat: 0.5,
                warmingInterval: 1,
                maxFuel: 300
            },
            image: "http://default-squad.ru:8542/civilisation/multiblocks/generator_full.gif"
        },
        {
            name: "Костёр",
            description: "Генерирует тепло в радиусе 6 блоков.",
            stats: {
                type: "Генератор тепла",
                workingRange: 6,
                generatedHeat: 0.2,
                warmingInterval: 2
            },
            image: "http://default-squad.ru:8542/civilisation/multiblocks/campfire_full.gif"
        },
        {
            name: "Табличка территории",
            description: "Создаёт область города в радиусе действия. Отвечает за описание предмета (где он был создан, добыт).",
            stats: {
                type: "Табличка территории",
                workingRange: 50
            },
            image: "http://default-squad.ru:8542/civilisation/multiblocks/area_sign_full.gif"
        },
        {
            name: "Обработка терракоты",
            description: "Обрабатывает блок глины в блок терракоты, нужный для создания печи для кирпичей.",
            stats: {
                type: "Обжарка блока"
            },
            image: "http://default-squad.ru:8542/civilisation/multiblocks/terracotta_processing_full.png"
        },
        {
            name: "Печь для кирпичей",
            description: "Первая структура со своими рецептами.\nОбжаривает глину и кирпичи.",
            stats: {
                type: "Генератор тепла и Печь",
                workingRange: 2,
                generatedHeat: 0.5,
                warmingInterval: 1,
                maxFuel: 500
            },
            image: "http://default-squad.ru:8542/civilisation/multiblocks/oven_for_brick_full.gif"
        },
        {
            name: "Печь для металлов",
            description: "Способна обрабатывать рецепты предыдущей печи более выгодно, и также может обрабатывать металлы.",
            stats: {
                type: "Генератор тепла и Печь",
                workingRange: 5,
                generatedHeat: 0.5,
                warmingInterval: 1,
                maxFuel: 2000
            },
            image: "http://default-squad.ru:8542/civilisation/multiblocks/oven_for_metals_full.gif"
        },
        {
            name: "Сборщик",
            description: "Первый мультиблок, требующий ватты для работы. Собирает предметы более выгодно, а также создает компоненты уровня выше. Ватты генерируются из любого рядом стоящего (не обязательно вплотную) генератора тепла.\nЧем ближе сборщик к генератору тепла, тем больше ватт.",
            stats: {
                type: "Сборщик предметов",
                wattRequired: 15
            },
            image: "http://default-squad.ru:8542/civilisation/multiblocks/assembler_full.gif"
        },
        {
            name: "Призыв ночного смотрителя",
            new: false,
            description: "Первый босс на проекте - Ночной смотритель. С него выпадает 2 блока незерита и от 10 до 17 стержней ифрита. Для призыва требует 1 блок незерита. Поведение как и у обычного вардена, с немного изменённой скоростью ходьбы.",
            stats: {
                type: "Призыв босса",
                maxHp: 1500
            },
            image: "http://default-squad.ru:8542/civilisation/multiblocks/night_caretaker_full.gif"
        },
        {
            name: "Пиролизная печь",
            new: true,
            description: "Позволяет делать древесный уголь из древесины. Первый мультиблок, требующий жидкость для работы. И также первый мультиблок с улучшаемыми блоками. Улучшение блоков также повышает уровень мультиблока. Более высокий уровень мультиблока позволяет производить продукцию быстрее. Резервуар наполняется при помещении ведра воды в печь. Пустое ведро вернётся вам на выходе.",
            stats: {
                type: "Печь для пиролиза",
                tankVolume: (12000).toLocaleString()
            },
            image: "http://default-squad.ru:8542/civilisation/multiblocks/pyrolyse_oven_full.gif"
        },
        {
            name: "Плавильня",
            new: true,
            description: "Переплавляет металлические предметы в самородки / слитки. Требует лаву для работы. Первый мультиблок, требующий предметные трубы для ввода/вывода предметов.",
            stats: {
                type: "Плавильня",
                tankVolume: (8000).toLocaleString()
            },
            image: "http://default-squad.ru:8542/civilisation/multiblocks/smelter_full.gif"
        },
        {
            name: "???",
            new: true,
            description: "Скоро...",
            stats: {
                type: "???"
            },
            image: "http://default-squad.ru:8542/civilisation/404.png"
        }
    ];

    const keyTranslateMap = new Map();
    keyTranslateMap.set("type", "Тип");
    keyTranslateMap.set("workingRange", "Радиус действия");
    keyTranslateMap.set("generatedHeat", "Генерирует тепла");
    keyTranslateMap.set("warmingInterval", "Интервал работы");
    keyTranslateMap.set("workingRange", "Радиус действия");
    keyTranslateMap.set("maxFuel", "Максимум топлива");
    keyTranslateMap.set("wattRequired", "Требует ватт");
    keyTranslateMap.set("maxHp", "Здоровье");
    keyTranslateMap.set("tankVolume", "Резервуар");

    const isServerOn = true;

    return (
        <div className="wrapper-civilisation">
            <div className="background-cover">
                <div className="civilisation-title">
                    Civilisation
                </div>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <div className="description" style={{color: "gray"}}>
                        Последнее обновление: 21 сент. 2024
                    </div>

                    <div>
                        Страница также служит как Wiki к проекту
                    </div>
                </div>

                <div className="line"></div>

                <div className="block-content">
                    <div className="description-box-time">
                        <div className="title">
                            Сервер открыт!
                        </div>
                    </div>
                </div>

                <div className="line"></div>

                <div className="block-content">
                    <div className="title">
                        Ключевые особенности
                    </div>
                    <ul>
                        <li>
                            <a href="#multiblocks">Мультиблоки</a>
                        </li>
                        <li>
                            <a href="#item-pipes">Предметные трубы</a>
                        </li>
                        <li>
                            <a href="#dynamic-player-temperature">Динамическая температура тела игрока</a>
                        </li>
                        <li>
                            <a href="#snow-world">Полностью снежный мир</a>
                        </li>
                        <li>
                            <a href="#expired-items">Срок годности еды</a>
                        </li>
                        <li>
                            <a href="#local-difficulty">Локальная сложность</a>
                        </li>
                        <li>
                            <a href="#heat-pipes">Тепловые трубы (статья в разработке)</a>
                        </li>
                    </ul>
                </div>

                <div className="line"></div>

                <div className="block-content">
                    <div className="title" id="multiblocks">
                        Мультиблоки
                    </div>

                    <div className="description-box">
                        <div className="description-box__title">
                            Что такое мультиблок
                        </div>

                        Подробности про реализацию мультиблоков в проекте Civilisation. Мультиблок - это термин,
                        определяемый как функциональная структура, состоящая из (и обычно требующая) нескольких блоков
                        для функционирования. Структура должна состоять из определённых блоков которые должны быть
                        расположены строго на своих местах.
                    </div>

                    <div className="multiblock-list">
                        {mutiblocksData.map((multiblock) => {
                            return (
                                <div className="multiblock" key={multiblock.name}>
                                    <div className="multiblock__title">
                                        {multiblock.name}
                                        {multiblock.new &&
                                            <div className="civilisation-title" style={{fontSize: 22}}>
                                                Новое
                                            </div>
                                        }
                                    </div>
                                    <div className="description-box" style={{padding: 0, paddingTop: 10}}>
                                        <div className="description-text">
                                            <div className="description-box__title">
                                                Описание
                                            </div>
                                            <div className="multiblock__description">
                                                {multiblock.description}
                                            </div>
                                            <br/>
                                            <div className="description-box__title">
                                                Характеристики
                                            </div>
                                            <div className="multiblock__stats">
                                                <table className="universe__table">
                                                    <thead>
                                                    <tr className="table__categories-sticky">
                                                        <th>
                                                            Характеристика
                                                        </th>
                                                        <th>
                                                            Значение
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody className="inventory-list">
                                                    {Object.keys(multiblock.stats).map(key => {
                                                        return (
                                                            <tr className="inventory-item" key={key}>
                                                                <td>
                                                                    {keyTranslateMap.get(key)}
                                                                </td>
                                                                <td>
                                                                    {multiblock.stats[key]
                                                                        + (key === "workingRange" ? " м" : "")
                                                                        + (key === "generatedHeat" ? " Cº" : "")
                                                                        + (key === "warmingInterval" ? " сек" : "")
                                                                        + (key === "maxFuel" ? " ед." : "")
                                                                        + (key === "wattRequired" ? " Вт" : "")
                                                                        + (key === "maxHp" ? " ХП" : "")
                                                                        + (key === "tankVolume" ? " mb" : "")
                                                                    }
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <br/>
                                            <div className="description-box__title">
                                                Постройка
                                            </div>
                                        </div>
                                        <div className="description-image">
                                            <div className="multiblock__image">
                                                <img src={multiblock.image} alt={multiblock.name}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div className="line"></div>

                <div className="block-content">
                    <div className="title" id="item-pipes">
                        Предметные трубы
                    </div>

                    <div className="description-box">
                        <div>
                            <div className="description-box__title">
                                Зачем?
                            </div>

                            Ради интереса я решил реализовать систему труб в игре, а также подумал что это может стать
                            интересной игровой механикой. Также это более гибкий способ распределения предметов, в
                            отличие
                            от воронок. Блоком, определяющим трубу, является [<img
                            src="http://default-squad.ru:8542/wheat/items/smooth_sandstone.png"/> Гладкий песчаник].
                        </div>
                        <br/>
                        <div>
                            <div className="description-box__title">
                                Управление
                            </div>
                            У каждой трубы есть направления, которые можно установить или убрать нажав Shift + ПКМ [<img
                            src="http://default-squad.ru:8542/wheat/items/bone.png"/> Костью] на нужной стороне трубы.
                            <br/>
                            Также можно получить дополнительную информацию о трубе, нажав по ней Shift + ПКМ [<img
                            src="http://default-squad.ru:8542/wheat/items/stick.png"/> Палкой].
                        </div>
                        <br/>
                        <div>
                            <div className="description-box__title">
                                Логистика
                            </div>
                            Для ввода и вывода предметов из труб нужны Поставщик и Потребитель.
                            <br/>
                            Поставщиком является [<img
                            src="http://default-squad.ru:8542/wheat/items/dispenser.png"/> Раздатчик], а Потребителем -
                            [<img src="http://default-squad.ru:8542/wheat/items/barrel.png"/> Бочка].
                            Предмет с Поставщика будет пытаться двигаться в сторону Потребителя. Предмет будет
                            перемещаться
                            в том случае, если между Поставщиком и Потребителем верно настроена магистраль труб.
                        </div>
                        <br/>
                        Если предмет пойдёт по тупиковому маршруту, он останется в последней трубе, до которой он смог
                        дойти. Если сломать трубу с предметом - предмет выпадет. Если предмет попытается добавиться в
                        инвентарь Потребителя, и у последнего инвентарь будет заполнен, предмет выпадет рядом с
                        Потребителем.
                        <br/><br/>
                        В случае правильной настройки перекрёстка труб, труба в которую перейдёт предмет определяется
                        случайным образом.
                    </div>
                </div>

                <div className="line"></div>

                <div className="block-content">
                    <div className="title" id="dynamic-player-temperature">
                        Динамическая температура игрока
                    </div>

                    <div className="description-box">
                        <div className="description-box__title">

                        </div>
                        В проекте Civilisation у игрока есть температура, которую необходимо поддерживать.
                        <br/><br/>
                        Температура будет повышаться, если игрок находится рядом с любыми генераторами тепла.
                        <br/>
                        Температура будет понижаться, если игрок находится далеко от генераторов тепла.
                        <br/><br/>
                        Также на понижение температуры игрока влияет множество факторов, такие как погодные условия,
                        находится ли он в воде, какая броня на нём надета.
                    </div>
                </div>

                <div className="line"></div>

                <div className="block-content">
                    <div className="title" id="snow-world">
                        Полностью снежный мир
                    </div>

                    <div className="description-box">
                        <div className="description-box__title">

                        </div>
                        Планета будет покрыта снегом и льдом.
                    </div>
                </div>

                <div className="line"></div>

                <div className="block-content">
                    <div className="title" id="expired-items">
                        Срок годности еды
                    </div>

                    <div className="description-box">
                        У каждой еды будет свой срок годности.
                        <br/><br/>
                        Также за съедение еды разного состояния будут накладываться как положительные, так и негативные
                        эффекты.
                    </div>
                </div>

                <div className="line"></div>

                <div className="block-content">
                    <div className="title" id="local-difficulty">
                        Локальная сложность
                    </div>

                    <div className="description-box">
                        Если один игрок убежит далеко от остальных, у него начнёт повышаться локальная сложность.
                        <br/><br/>
                        Локальная сложность влияет на получение урона, а также на скорость, с которой ломаются
                        инструменты.
                    </div>
                </div>

                <div className="line"></div>

                <div className="block-content" style={{marginBottom: 50}}>
                    <div className="title" style={{fontSize: 28}}>
                        Присоединяйтесь к нам!
                    </div>

                    <div className="description-box"
                         style={{width: "auto", display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <div>
                            Ведь вместе - веселей.
                        </div>
                        <br/>
                        <div className="centered">
                            <a className="c-link" href="http://default-squad.ru/event-registration">
                                {"\>"} Здесь вы можете записаться на участие в проекте {"\<"}
                            </a>
                        </div>
                        <br/><br/>
                        <div className="server-stats">
                            <div className="stats-div">
                                1.21.1
                            </div>
                            <div className="stats-div">
                                default-squad.ru:25595
                            </div>
                            <div className="stats-div">
                                Сервер: {isServerOn ? <span style={{color: "seagreen"}}>ВКЛ</span> :
                                <span style={{color: "indianred"}}>ВЫКЛ</span>}
                            </div>
                        </div>
                    </div>
                </div>

                <a href="/" className="footer" style={{color: "gray"}}>
                    &copy; 2020-2024 DEFAULT-SQUAD.RU
                </a>
            </div>
        </div>
    );
}

export default Civilisation;