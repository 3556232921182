import s from "../gtnh.module.css";
import {useState} from "react";
import DisassemblerStore from "../../../../pages/Other/GTNH/Disassembler/DisassemblerStore.js";
export const ItemSlot = ({ index }) => {
    const [itemName, setItemName] = useState("");
    const [amountNeed, setAmountNeed] = useState("");

    const onChangeName = (event) => {
        setItemName(event.target.value);
    }

    const onChangeAmount = (event) => {
        if (itemName === "" || itemName === undefined || itemName === null) {
            return;
        }

        const freshAmount = event.target.value;

        setAmountNeed(freshAmount);
        DisassemblerStore.setInMap(index, [itemName, freshAmount]);
    }

    return (
        <div className={s.itemSlot}>
            <div>
                {`Item ${index + 1} - `}
            </div>
            <input type={"text"} onChange={onChangeName}/>
            <label htmlFor={"amountNeed"}></label>
            <input id={"amountNeed"} type={"text"} value={amountNeed} onChange={onChangeAmount}/>
        </div>
    );
}