import { observer } from "mobx-react";
import { useState, useContext } from "react";
import { Modal, Spinner } from "react-bootstrap";

// COMPONENTS
import RainbowLine from "../Other/RainbowLine.jsx";
import MarketOtherLot from "./MarketOtherLot.jsx";

// MODULES
import { PlayerStoreContext } from "../../../App.js";
import { MarketStoreContext } from "../../../App.js";



const MarketLot = observer(({ lot }) => {
    const Player = useContext(PlayerStoreContext);
    const Market = useContext(MarketStoreContext);

    const [showLotInfo, setShowLotInfo] = useState(false);

    const getCurrencySym = (currency) => {
        switch (currency) {
            case "baubles":
                currency = "💰";
                break;
            case "baumonds":
                currency = "💠";
                break;
            case "rubles":
                currency = "₽";
        }

        return currency;
    }

    const typesLocalization = (itemType) => {
        const locals = new Map([
            ["item", "Предмет"],
            ["food", "Еда"],
            ["potion", "Зелье"],
            ["amulet", "Амулет"],
            ["armorhead", "Броня: Шлем"],
            ["armorchestplate", "Броня: Шлем"],
            ["armorboots", "Броня: Ботинки"],
            ["AmmoArrow", "Боеприпас (Лук)"],
            ["AmmoBolt", "Боеприпас (Арбалет)"],
            ["Container", "Открываемое"],
            ["container", "Открываемое"],
            ["weapon", "Оружие: Ближний бой"],
            ["WeaponBow", "Оружие: Лук"],
            ["WeaponXBow", "Оружие: Арбалет"],
            ["shield", "Броня: Щит"]
        ]);

        return locals.get(itemType);
    }

    const toggleShowLotInfo = () => {
        setShowLotInfo(!showLotInfo);

        if (!showLotInfo) {
            Market.resetLotInfo();
            Market.getLotInfo(lot.id);
        }
    }

    const buyItemInLot = () => {
        toggleShowLotInfo();

        Market.buyItemInLot(Player.playerData.botId, Market.lotInfo.id);
    }


    return (
        <>
            <tr className="inventory-item">
                <td className="item-name" onClick={toggleShowLotInfo}>
                    {lot.itemName}
                </td>
                <td>
                    {typesLocalization(lot.itemType)}
                </td>
                <td>
                    {lot.lotsForSell}
                </td>
                <td>
                    {lot.lotsToBuy}
                </td>
                <td>
                    {`${(lot.sellPrice).toLocaleString()} ${getCurrencySym(lot.currency)}`}
                </td>
                <td>
                    {`${(lot.buyPrice).toLocaleString()} ${getCurrencySym(lot.currency)}`}
                </td>
            </tr>

            <Modal
                show={showLotInfo}
                backdrop="static"
                keyboard={false}
                >
                <Modal.Header>
                    <Modal.Title>
                        Лот №{Market.lotInfo.id}
                    </Modal.Title>
                </Modal.Header>
                <RainbowLine />
                <Modal.Body>
                    <div className="pairs">
                        <dl className="pair">
                            <dt>Предмет:</dt>
                            <dd>{lot.itemName}</dd>
                        </dl>
                        <dl className="pair">
                            <dt>Продавец:</dt>
                            <dd>---</dd> {/* возвращать вместе с lotInfo */}
                        </dl>
                        <hr />
                        <dl className="pair">
                            <dt>Стоимость от:</dt>
                            {/* <dd>{lot.sellPrice === 0 ? "Нет в продаже" : `${lot.sellPrice.toLocaleString()} ${getCurrencySym(lot.currency)}`}</dd> */}
                            <dd>{Market?.lotInfo?.sellLotsList?.length > 0 ? `${Number(Market.lotInfo.sellLotsList[0].price).toLocaleString()} ${getCurrencySym(Market.lotInfo.sellLotsList[0].currency)}` : "Нет в продаже"}</dd>
                        </dl>
                        <dl className="pair">
                            <dt>Стоимость текущего лота:</dt>
                            <dd>{Market?.lotInfo?.price > 0 ? `${Number(Market.lotInfo.price).toLocaleString()} ${getCurrencySym(Market.lotInfo.currency)}` : "Нет в продаже"}</dd>
                        </dl>
                    </div>
                    <hr />
                    <div className="other-lots">
                        <div className="other-lots__title">
                            <b>ДРУГИЕ ЛОТЫ</b>
                        </div>
                        {Market.isLotInfoLoading ? <div><center><Spinner /></center></div> :
                            <div className="other-lots__container">
                                <div className="sell-buy-title">
                                    <b>ПРОДАЖА</b><b>ПОКУПКА</b>
                                </div>
                                <hr />
                                <div className="other-lots__lists">
                                    <div className="other-lots__sell-list">
                                        <div className="pairs">
                                            {Market.lotInfo.sellLotsList.map((sellLot) => {
                                                return <MarketOtherLot className="pair" key={`${sellLot.id}-${sellLot.currency}`} lot={sellLot} />;
                                            })}
                                        </div>
                                    </div>
                                    <div className="other-lots__buy-list">
                                        <div className="pairs">
                                            {Market.lotInfo.buyLotsList.map((buyLot) => {
                                                return (
                                                    <div className="pair" key={`${buyLot.id}-${buyLot.currency}`}>
                                                        {`${buyLot.id}. ${Number(buyLot.price).toLocaleString()} ${getCurrencySym(buyLot.currency)}`}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="universe-buttons universe-buttons-group">
                        {Market?.lotInfo?.sellLotsList?.length > 0 &&
                            <button onClick={buyItemInLot}>
                                Купить
                            </button>
                        }
                        <button onClick={toggleShowLotInfo}>
                            Назад
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
});

export default MarketLot;