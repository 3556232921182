import { useContext, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import NotificationsCenter from "../../../modules/Main/NotificationsCenter.js";

// STORES
import MainStore from "../../../modules/Main/MainStore.js";
import { UserStoreContext } from "../../../App.js";
import AuthStore from "./AuthStore.js";



const SquadLoginForm = (props) => {
    const navigate = useNavigate();

    const User = useContext(UserStoreContext);

    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const LoginClickHandler = async () => {
        if (buttonDisabled) {
            return NotificationsCenter.ShowInfo("Не так быстро");
        }

        if (login.length < AuthStore.LOGIN_MIN_LENGTH || login.length > AuthStore.LOGIN_MAX_LENGTH) {
            return NotificationsCenter.ShowInfo(`Логин должен быть не менее ${AuthStore.LOGIN_MIN_LENGTH} и не более ${AuthStore.LOGIN_MAX_LENGTH} символов`);
        }

        if (password.length < AuthStore.PASSWORD_MIN_LENGTH || password.length > AuthStore.PASSWORD_MAX_LENGTH) {
            return NotificationsCenter.ShowInfo(`Пароль должен быть не менее ${AuthStore.PASSWORD_MIN_LENGTH} и не более ${AuthStore.PASSWORD_MAX_LENGTH} символов`);
        }

        setButtonDisabled(true);
        
        localStorage.removeItem("chakra-ui-color-mode");
        localStorage.removeItem("userId");

        let response = (await axios.post(`${MainStore.SERVER_ADDRESS}/login`, {
            login,
            password
        }, {
            withCredentials: true
        })).data;
        
        if (response?.userData) {
            NotificationsCenter.ShowSuccess(response.message);
            User.setUserData(response.userData);
            
            setTimeout(() => {
                navigate("/profile");
            }, 250);
        } else {
            NotificationsCenter.ShowWarn(response.message);
            setTimeout(() => {
                setButtonDisabled(false);
            }, 1000);
        }
    }

    const OnChangeLogin = (event) => {
        setLogin(event.target.value.replace(" ", ""));
    }

    const OnChangePassword = (event) => {
        setPassword(event.target.value);
    }
    

    return (
        <div className="page__body">
            <section>
                <div className="container">
                    <h2>{props.title}</h2>
                    <div className="inputBox">
                        <input id="squad-login" type="text" placeholder="Login" minLength={AuthStore.LOGIN_MIN_LENGTH} maxLength={AuthStore.LOGIN_MAX_LENGTH} onChange={OnChangeLogin} required autoFocus />
                    </div>
                    <div className="inputBox">
                        <input id="squad-password" type="password" placeholder="Password" minLength={AuthStore.PASSWORD_MIN_LENGTH} maxLength={AuthStore.PASSWORD_MAX_LENGTH} onChange={OnChangePassword} required />
                    </div>
                    <div className="inputBox">
                        <input type="submit" value="Login" id="btn001" onClick={LoginClickHandler} />
                    </div>
                    <div className="group">
                        <a href="/">Main</a>
                        <a href="/registration">Registration</a>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default SquadLoginForm;