import { observer } from "mobx-react";
import { useContext } from "react";

// COMPONENTS
import StorageItem from "./StorageItem.jsx";

// STORES
import { PlayerStoreContext } from "../../../App.js";



const StorageList = observer(() => {
    const Player = useContext(PlayerStoreContext);

    let totalAmount = 0;
    let totalPrice = 0;

    return (
        <div className="wrapper__content">
            <h2>Склад</h2>
            <table className="universe__table">
                <thead>
                    <tr className="table__categories-sticky">
                        {/* <th>
                            ID
                        </th> */}
                        <th>
                            Предмет
                        </th>
                        <th>
                            Тип
                        </th>
                        <th>
                            Количество
                        </th>
                        <th>
                            Цена за штуку
                        </th>
                        <th>
                            Общая цена
                        </th>
                    </tr>
                </thead>
                <tbody className="inventory-list">
                    {Player.playerData.storageItems.map((item) => {
                        totalAmount += item.amount;
                        totalPrice += item.amount * item.price;

                        return <StorageItem key={item.itemId} item={item} />;
                    })}
                    <tr className="inventory-item">
                        {/* <td>
                            {item.itemId}
                        </td> */}
                        <td>
                            =====
                        </td>
                        <td>
                            =====
                        </td>
                        <td>
                            {totalAmount.toLocaleString()}
                        </td>
                        <td>
                            =====
                        </td>
                        <td>
                            {totalPrice.toLocaleString()}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
});

export default StorageList;