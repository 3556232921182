import "react-toastify/dist/ReactToastify.css";
import { createContext } from "react";

import { ThemeProvider } from "./UI/ThemeContext.js";

// Navigation Routes (Sections)
import SquadSection from "./modules/Squad/SquadSection.jsx";
import UniverseSection from "./modules/Universe/UniverseSection.jsx";
import WheatSection from "./modules/Wheat/WheatSection.jsx";
import WildberriesSection from "./modules/WB/WildberriesSection.jsx";
import OtherSection from "./modules/Other/OtherSection.jsx";
import GamesSection from "./modules/Games/GamesSection.jsx";

// STORES
import UserStore from "./modules/Squad/UserStore.js";
import BlogStore from "./modules/Squad/BlogStore.js";
import PlayerStore from "./modules/Universe/PlayerStore.js";
import MarketStore from "./modules/Universe/Market/MarketStore.js";
import WildberriesStore from "./modules/WB/WildberriesStore.js";
import TitleStore from "./modules/Squad/Profile/TitleStore.js";
import WheatItemsStore from "./modules/Wheat/Items/WheatItemsStore.js";
import EventStore from "./modules/Projects/EventStore.js";


export const UserStoreContext = createContext(UserStore);
export const BlogStoreContext = createContext(BlogStore);
export const PlayerStoreContext = createContext(PlayerStore);
export const MarketStoreContext = createContext(MarketStore);
export const WildberriesStoreContext = createContext(WildberriesStore);
export const TitleStoreContext = createContext(TitleStore);
export const WheatItemsStoreContext = createContext(WheatItemsStore);
export const EventStoreContext = createContext(EventStore);


const MainSections = () => {
    return (
        <div className="main-sections">
            <UserStoreContext.Provider value={UserStore}>
                <BlogStoreContext.Provider value={BlogStore}>
                    <TitleStoreContext.Provider value={TitleStore}>
                        <SquadSection />
                    </TitleStoreContext.Provider>
                </BlogStoreContext.Provider>
            </UserStoreContext.Provider>

            <PlayerStoreContext.Provider value={PlayerStore}>
                <MarketStoreContext.Provider value={MarketStore}>
                    <UniverseSection />
                </MarketStoreContext.Provider>
            </PlayerStoreContext.Provider>

            <WheatItemsStoreContext.Provider value={WheatItemsStore}>
                <WheatSection />
            </WheatItemsStoreContext.Provider>
        </div>
    );
}

const OtherSections = () => {
    return (
        <div className="other-sections">
            <WildberriesStoreContext.Provider value={WildberriesStore}>
                <WildberriesSection />
            </WildberriesStoreContext.Provider>

            <EventStoreContext.Provider value={EventStore}>
                <OtherSection />
            </EventStoreContext.Provider>

            <GamesSection />
        </div>
    );
}

const App = () => {
    return (
        <div className="app">
            <ThemeProvider>
                <MainSections />
                <OtherSections />
            </ThemeProvider>
        </div>
    );
};

export default App;

// https://ru.bem.info/methodology/quick-start/