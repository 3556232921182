// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile_wrapper__kuiL9 {
    padding: 2em;
}

.profile_profileTop__\\+3GCz {
    display: grid;
    justify-items: center;
    grid-template-columns: auto auto auto auto;
}

.profile_userAvatar__I51tx {
    height: 25em;
    width: 25em;
    border-radius: 1em;
}

.profile_userInfo__GTL7f {
}

.profile_userLoginTitle__qnf0t {
    display: flex;
    align-items: center;
}

.profile_userLogin__P7f5X {
    font-size: 2.5em;
    margin-right: .25em;
}

.profile_userTitle__tyuRQ {
    font-size: .85em;
}

.profile_changeTitle__Afigt {
    margin-top: .75em;
}`, "",{"version":3,"sources":["webpack://./src/pages/Squad/profile.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,qBAAqB;IACrB,0CAA0C;AAC9C;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,kBAAkB;AACtB;;AAEA;AACA;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".wrapper {\r\n    padding: 2em;\r\n}\r\n\r\n.profileTop {\r\n    display: grid;\r\n    justify-items: center;\r\n    grid-template-columns: auto auto auto auto;\r\n}\r\n\r\n.userAvatar {\r\n    height: 25em;\r\n    width: 25em;\r\n    border-radius: 1em;\r\n}\r\n\r\n.userInfo {\r\n}\r\n\r\n.userLoginTitle {\r\n    display: flex;\r\n    align-items: center;\r\n}\r\n\r\n.userLogin {\r\n    font-size: 2.5em;\r\n    margin-right: .25em;\r\n}\r\n\r\n.userTitle {\r\n    font-size: .85em;\r\n}\r\n\r\n.changeTitle {\r\n    margin-top: .75em;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `profile_wrapper__kuiL9`,
	"profileTop": `profile_profileTop__+3GCz`,
	"userAvatar": `profile_userAvatar__I51tx`,
	"userInfo": `profile_userInfo__GTL7f`,
	"userLoginTitle": `profile_userLoginTitle__qnf0t`,
	"userLogin": `profile_userLogin__P7f5X`,
	"userTitle": `profile_userTitle__tyuRQ`,
	"changeTitle": `profile_changeTitle__Afigt`
};
export default ___CSS_LOADER_EXPORT___;
