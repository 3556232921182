import React from 'react';
import { useTheme } from './ThemeContext';
import { MoonStars, Sun } from "react-bootstrap-icons";



const UniverseThemeToggleButton = () => {
    const { theme, setTheme } = useTheme();

  	const toggleTheme = () => {
		setTheme(theme === 'dark' ? 'light' : 'dark');
  	}

  	return (
    	<button onClick={toggleTheme} style={{width: 35, height: 35, padding: 0, borderRadius: 10}}>
        	{theme === "light" ? <MoonStars /> : <Sun />}     
    	</button>
  	);
}

export default UniverseThemeToggleButton;