// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.post_wrapper__PbZx9 {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 1em;
}`, "",{"version":3,"sources":["webpack://./src/components/Squad/Posts/post.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,YAAY;AAChB","sourcesContent":[".wrapper {\r\n    width: 100%;\r\n    display: flex;\r\n    justify-content: center;\r\n    padding: 1em;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `post_wrapper__PbZx9`
};
export default ___CSS_LOADER_EXPORT___;
