import { makeAutoObservable, runInAction } from "mobx";
import axios from "axios";

// STORES
import MainStore from "../Main/MainStore.js";



class WildberriesStore {
    clusters = {};
    isLoadingData = true;

    constructor() {
        makeAutoObservable(this);
        // this.getData()
    }

    getData = async () => {
        let response = await axios.get(`${MainStore.SERVER_ADDRESS}/wb`);

        if (!response?.data) {
            return;
        }

        runInAction(() => {
            this.clusters = response.data;
            this.isLoadingData = false;
        });
    }
}

export default new WildberriesStore();