import { observer } from "mobx-react";


const TechnicalWorks = observer(() => {
    return (
        <div className="V-H__align" style={{display: "flex", flexDirection: "column"}}>
            <div className="technical-works-text  smartText">
                На сайте проводятся технические работы
            </div>
            <hr style={{height: 1, width: "100%", color: "gray"}} />
            <div className="technical-works-text smartText">
                Раздел станет доступен после 18.08.2024
            </div>
        </div>

    );
});

export default TechnicalWorks;