// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gtnh_formBackground__fncGa {
    box-shadow: 0 25px 50px rgba(255, 255, 255, .05);
    border-radius: 1em;
    margin-bottom: 2em;
}

.gtnh_addItemForm__\\+FCSG {
    padding: 2em 0;

    display: grid;
    row-gap: 1em;
    justify-items: center;

    fieldset {
        padding: 0 2em;
        display: grid;
        justify-items: center;
        justify-content: space-between;
        row-gap: .5em;
        width: 100%;

        legend {
            text-decoration: underline;
        }
    }
}

.gtnh_itemSlot__5Hsf6 {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.gtnh_slotsContainer__1hVuF {

}

.gtnh_section3__x17zb {
    display: flex;
    flex-direction: column;
}

.gtnh_sectionRow__KQ3wV {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/modules/Other/GTNH/gtnh.module.css"],"names":[],"mappings":"AAAA;IACI,gDAAgD;IAChD,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,cAAc;;IAEd,aAAa;IACb,YAAY;IACZ,qBAAqB;;IAErB;QACI,cAAc;QACd,aAAa;QACb,qBAAqB;QACrB,8BAA8B;QAC9B,aAAa;QACb,WAAW;;QAEX;YACI,0BAA0B;QAC9B;IACJ;AACJ;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,WAAW;AACf;;AAEA;;AAEA;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,WAAW;IACX,8BAA8B;AAClC","sourcesContent":[".formBackground {\r\n    box-shadow: 0 25px 50px rgba(255, 255, 255, .05);\r\n    border-radius: 1em;\r\n    margin-bottom: 2em;\r\n}\r\n\r\n.addItemForm {\r\n    padding: 2em 0;\r\n\r\n    display: grid;\r\n    row-gap: 1em;\r\n    justify-items: center;\r\n\r\n    fieldset {\r\n        padding: 0 2em;\r\n        display: grid;\r\n        justify-items: center;\r\n        justify-content: space-between;\r\n        row-gap: .5em;\r\n        width: 100%;\r\n\r\n        legend {\r\n            text-decoration: underline;\r\n        }\r\n    }\r\n}\r\n\r\n.itemSlot {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    width: 100%;\r\n}\r\n\r\n.slotsContainer {\r\n\r\n}\r\n\r\n.section3 {\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n\r\n.sectionRow {\r\n    display: grid;\r\n    grid-template-columns: 1fr 1fr;\r\n    width: 100%;\r\n    justify-content: space-between;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formBackground": `gtnh_formBackground__fncGa`,
	"addItemForm": `gtnh_addItemForm__+FCSG`,
	"itemSlot": `gtnh_itemSlot__5Hsf6`,
	"slotsContainer": `gtnh_slotsContainer__1hVuF`,
	"section3": `gtnh_section3__x17zb`,
	"sectionRow": `gtnh_sectionRow__KQ3wV`
};
export default ___CSS_LOADER_EXPORT___;
