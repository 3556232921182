import React from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { observer } from "mobx-react";


// PAGES
import NotFoundPage from "../../pages/Utils/NotFoundPage.jsx";
import TechnicalWorks from "../../pages/Utils/TechnicalWorks.jsx";
import Civilisation from "../../pages/Civilisation/Civilisation.jsx";
import CivilisationRecipes from "../../pages/Civilisation/CivilisationRecipes.jsx";
import EventRegistration from "../Projects/EventRegistration.jsx";
import Card from "../../components/Squad/Fun/Card.jsx";
import Calculator from "../../pages/Other/Calculator/Calculator.jsx";

// COMPONENTS

// STORES



const OtherSection = observer(() => {
    return (
        <>
            <Routes>
                {/*<Route path="*" element={<TechnicalWorks />} />*/}

                <Route path="/card" element={<Card/>}/>
                <Route path="/calc" element={<Calculator/>}/>
                <Route path="/event/civilisation" element={<Civilisation/>}/>
                <Route path="/event/civilisation/recipes" element={<CivilisationRecipes/>}/>
                <Route path="/event-registration" element={<EventRegistration/>}/>
            </Routes>

            <ToastContainer/>
        </>
    );
});

export default OtherSection;