import { useContext, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import NotificationsCenter from "../../../modules/Main/NotificationsCenter.js";

// STORES
import { UserStoreContext } from "../../../App.js";
import MainStore from "../../../modules/Main/MainStore.js";
import AuthStore from "./AuthStore.js";



const SquadRegistrationForm = (props) => {
    const navigate = useNavigate();

    const User = useContext(UserStoreContext);

    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [code, setCode] = useState("");
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const RegistrationClickHandler = async () => {
        if (buttonDisabled) {
            return NotificationsCenter.ShowInfo("Не так быстро");
        }
        
        if (login.length < AuthStore.LOGIN_MIN_LENGTH || login.length > AuthStore.LOGIN_MAX_LENGTH) {
            return NotificationsCenter.ShowInfo(`Логин должен быть не менее ${AuthStore.LOGIN_MIN_LENGTH} и не более ${AuthStore.LOGIN_MAX_LENGTH} символов`);
        }

        if (password.length < AuthStore.PASSWORD_MIN_LENGTH || password.length > AuthStore.PASSWORD_MAX_LENGTH) {
            return NotificationsCenter.ShowInfo(`Пароль должен быть не менее ${AuthStore.PASSWORD_MIN_LENGTH} и не более ${AuthStore.PASSWORD_MAX_LENGTH} символов`);
        }

        if (code.length < AuthStore.CODE_LENGTH || code.length > AuthStore.CODE_LENGTH) {
            return NotificationsCenter.ShowInfo(`Код должен иметь длину в ${AuthStore.CODE_LENGTH} символов`);
        }

        setButtonDisabled(true);

        let response = (await axios.post(`${MainStore.SERVER_ADDRESS}/registration`, { login, password, code })).data;

        if (response?.userData) {
            NotificationsCenter.ShowSuccess(response.message);
            User.setUserData(response.userData);
            setTimeout(() => {
                localStorage.setItem("squad-access-token", response.tokens.accessToken);
                navigate("/profile");
            }, 250);
        } else {
            NotificationsCenter.ShowWarn(response.message);
            setTimeout(() => {
                setButtonDisabled(false);
            }, 1000);
        }

        if (response?.tokens) {
            setTimeout(() => {
                navigate("/profile");
            }, 250);
        }
    }

    const OnChangeLogin = (event) => {
        setLogin(event.target.value.replace(" ", ""));
    }

    const OnChangePassword = (event) => {
        setPassword(event.target.value);
    }

    const OnChangeCode = (event) => {
        setCode(event.target.value);
    }


    return (
        <div className="page__body">
            <section>
                <div className="container">
                    <h2>{props.title}</h2>
                    <div className="inputBox">
                        <input type="text" placeholder="Login" minLength={AuthStore.LOGIN_MIN_LENGTH} maxLength={AuthStore.LOGIN_MAX_LENGTH} onChange={OnChangeLogin} required autoFocus />
                    </div>
                    <div className="inputBox">
                        <input type="password" placeholder="Password" minLength={AuthStore.PASSWORD_MIN_LENGTH} maxLength={AuthStore.PASSWORD_MAX_LENGTH} onChange={OnChangePassword} required />
                    </div>
                    <div className="inputBox">
                        <input type="text" placeholder="Code" minLength={AuthStore.CODE_LENGTH} maxLength={AuthStore.CODE_LENGTH} onChange={OnChangeCode} required />
                    </div>
                    <div className="inputBox">
                        <input type="submit" value="Registration" id="btn001" onClick={RegistrationClickHandler} />
                    </div>
                    <div className="group">
                        <a href="/">Main</a>
                        <a href="/login">Login</a>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default SquadRegistrationForm;