import { observer } from "mobx-react";
import { Button, Image, Dropdown } from "react-bootstrap";
import { House, ArrowUpRightSquare } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";

import NotificationsCenter from "../../../modules/Main/NotificationsCenter.js";

import ThemeToggleButton from "../../../UI/ThemeToggleButton.jsx";




const WheatHeader = observer(() => {
    const navigate = useNavigate();

    const IndexNavigate = () => {
        navigate("/");
    }

    const WheatIndexNavigate = () => {
        navigate("/wheat");
    }

    return (
        <header className="page__header page__wheat-header">
            <div className="page__header__left-block">
                <div className="page__header__left-block__name" style={{paddingLeft: 12}}>
                    Default Wheat
                    <div className="page__header__left-block__buttons"></div>
                </div>
            </div>
            <div className="page__header__right-block">
                <div className="buttons-group">
                    <ThemeToggleButton />
                    <Dropdown>
                        <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                            Menu
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="/wheat">Wheat <ArrowUpRightSquare /></Dropdown.Item>
                            <Dropdown.Item href="/">Main <House /></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        </header>
    );
});

export default WheatHeader;