import s from "./style.module.css";
import MainStore from "../../../../modules/Main/MainStore.js";



const ItemTopAmount = ({ item, index }) => {
    return (
        // s-container item-container
        <tr className={`${s.rdTable__cell} ${index % 2 === 0 ? s.dark : s.light}`}>
            <td className={s.itemTexture}>
                <img className={s.itemTexture} src={`${MainStore.SERVER_ADDRESS}/wheat/items/${item.id}.png`} title={item.id} alt="Texture" />
            </td>
            <td className={s.itemName}>
                <a href={`http://default-squad.ru/wheat/item-chart-amount/minecraft:${item.id}`}>
                    {item.name || item.id.split("_").map((word) => word[0].toUpperCase() + word.substring(1)).join(" ")}
                </a>
            </td>
            <td className={s.itemAmount}>
                {item.amount.toLocaleString().replaceAll(",", " ")}
            </td>
        </tr>
    );
}

export default ItemTopAmount;