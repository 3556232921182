import { observer } from "mobx-react";
import "./DefaultCoin.css";

import React, { useEffect, useState } from "react";



const DefaultCoin = observer(() => {
    let [coin, setCoin] = useState(0);
    let [previousClickDate, setPreviousClickDate] = useState(0);
    let [coinsPerClick, setCoinsPerClick] = useState(100);
    let [coinsPerClickLevel, setCoinsPerClickLevel] = useState(1);

    useEffect(() => {
        let coins = localStorage.getItem("coins");
        let previousClickDate = localStorage.getItem("p");
        let cpc = localStorage.getItem("coinsPerClick");
        let cpcl = localStorage.getItem("coinsPerClickLevel");
    
        if (coins != null) {
            setCoin(Number(coins));
        }

        if (previousClickDate != null) {
            setPreviousClickDate(Number(previousClickDate));
        }

        if (cpc != null) {
            setCoinsPerClick(Number(cpc));
        }

        if (cpcl != null) {
            setCoinsPerClickLevel(Number(cpcl));
        }
    }, []);

    
    const checkDate = () => {
        let clickDate = Date.now();
        let isOk = false;

        if (clickDate - previousClickDate > 100) {
            isOk = true;
        } else {
            // console.log("Слишком быстро!");
        }

        setPreviousClickDate(clickDate);
        return isOk;
    }

    const isAvailableCoins = (needCoins) => coin >= needCoins;

    const clickHandle = () => {
        if (checkDate()) {
            let newCoins = coin + coinsPerClickLevel;
            console.log(coin, coinsPerClickLevel);
            setCoin(newCoins);
            localStorage.setItem("coins", newCoins.toString());
            localStorage.setItem("p", previousClickDate.toString());
        }
    }

    const takeCoins = (amount) => {
        setCoin(coin - amount);
    }

    const clickUpgradeCoinsPerClick = () => {
        if (isAvailableCoins(coinsPerClick)) {
            const newCoinsPerClickLevel = coinsPerClickLevel + 1;

            setCoinsPerClickLevel(newCoinsPerClickLevel);
            takeCoins(coinsPerClick);
            let newUpgradePrice = Math.round(coinsPerClick * 1.2);
            setCoinsPerClick(newUpgradePrice);
            localStorage.setItem("coinsPerClickLevel", newCoinsPerClickLevel.toString());
            localStorage.setItem("coinsPerClick", newUpgradePrice.toString());
        }
    }

    // const getSym = () => {
    //     let index = Math.abs(Math.round(Math.random() * coin.toString().split("").length - 1));
    //     const sym = coin.toString().split("")[index];
    //     return sym;
    // }

	return (
        <div className="wrapper-dc">
            <div className="text unselectable">
                Default Coin
            </div>
            <div className="coin-amount unselectable">
                $ {coin.toLocaleString()}
            </div>
            <div className="coin" onClick={clickHandle}>
                <div className="coin-text unselectable" style={{color: "black"}}>
                    $
                </div>
            </div>
            <div className="upgrades">
                <div className="upgrade">
                    <div className="upgrade-name unselectable">
                        Заработок за клик <u>$ {coinsPerClickLevel.toLocaleString()}</u>
                    </div>
                    <div className="upgrade-button">
                        <div className="upgrade-button-bg  unselectable" onClick={clickUpgradeCoinsPerClick}>
                            $ {coinsPerClick.toLocaleString()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default DefaultCoin;