import { observer } from "mobx-react";
import { useContext, useEffect } from "react";
import { Spinner } from "react-bootstrap";

// COMPONENTS
import UniverseHeader from "../../../components/Universe/Header/UniverseHeader.jsx";
import UniverseProfileContent from "../../../components/Universe/Profile/UniverseProfileContent.jsx";
import UniverseFooter from "../../../components/Universe/Footer/UniverseFooter.jsx";



const UniverseProfilePage = observer(() => {
    return (
        <div className="universe__wrapper__content">
            <UniverseHeader />
            <UniverseProfileContent />
            <UniverseFooter />
        </div>
    );
});

export default UniverseProfilePage;