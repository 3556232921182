import {useContext, useEffect} from "react";
import {Spinner} from "react-bootstrap";
import {observer} from "mobx-react";

import {WheatItemsStoreContext} from "../../../App.js";

// COMPONENTS
import ItemTopAmountDynamics from "../../../components/Wheat/Items/ItemTopAmountDynamics.jsx";



const TopAmountDynamics = observer(() => {
    const WIS = useContext(WheatItemsStoreContext);

    useEffect(() => {
        const fetchData = async () => {
            await WIS.getTopAmountDynamics();
        }

        fetchData();
    }, [WIS]);

    return (
        <div className="wheat__wrapper-content">
            <div className="wheat__list-title">
                ТОП по динамике количества
            </div>
            <div className="wheat__list-title">
                Количество изменений количества предмета за всё время с 7 августа 2023 года
            </div>
            <div className="wheat-search">
                <input type="text" placeholder="Поиск" onChange={WIS.onChangeSearch}/>
            </div>
            {WIS.itemsLoading ? <Spinner/> :
                WIS.isSorted ?
                    WIS.itemsSorted.map((item) => <ItemTopAmountDynamics key={item.id} item={item}/>)
                    :
                    WIS.items.map((item) => <ItemTopAmountDynamics key={item.id} item={item}/>)
            }
        </div>
    );
});

export default TopAmountDynamics;