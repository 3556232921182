import { observer } from "mobx-react";



const StorageItem = observer(({ item }) => {
    const typesLocalization = (itemType) => {
        const locals = new Map([
            ["item", "Предмет"],
            ["food", "Еда"],
            ["potion", "Зелье"],
            ["amulet", "Амулет"],
            ["armorhead", "Броня: Шлем"],
            ["armorchestplate", "Броня: Шлем"],
            ["armorboots", "Броня: Ботинки"],
            ["AmmoArrow", "Боеприпас (Лук)"],
            ["AmmoBolt", "Боеприпас (Арбалет)"],
            ["AmmoBow", "Боеприпас (Лук)"],
            ["AmmoXBow", "Боеприпас (Арбалет)"],
            ["Container", "Открываемое"],
            ["container", "Открываемое"],
            ["weapon", "Оружие: Ближний бой"],
            ["WeaponBow", "Оружие: Лук"],
            ["WeaponXBow", "Оружие: Арбалет"],
            ["shield", "Броня: Щит"]
        ]);

        return locals.get(itemType);
    }

    return (
        <tr className="inventory-item">
            {/* <td>
                {item.itemId}
            </td> */}
            <td>
                {item.title}
            </td>
            <td>
                {typesLocalization(item.type) || item.type}
            </td>
            <td>
                {item.amount.toLocaleString()}
            </td>
            <td>
                {item.price.toLocaleString()}
            </td>
            <td>
                {(item.amount * item.price).toLocaleString()}
            </td>
        </tr>
    );
});

export default StorageItem;