import { makeAutoObservable } from "mobx";



class AuthStore {
    LOGIN_MIN_LENGTH = 3;
    LOGIN_MAX_LENGTH = 16;
    PASSWORD_MIN_LENGTH = 6;
    PASSWORD_MAX_LENGTH = 32;
    CODE_LENGTH = 60;

    constructor() {
        makeAutoObservable(this);
    }
}

export default new AuthStore();