import MainStore from "../../../modules/Main/MainStore.js";


const Footer = () => {
    return (
        <footer style={{display: "flex", flexDirection: "column"}}>
            <div>
                &copy; 2020-2024 <a href={MainStore.ROOT_DOMAIN}>DEFAULT-SQUAD.RU</a>
            </div>
            {/*<div className="footer-version" style={{color: "#4b4b4b"}}>Version 1</div>*/}
        </footer>
    );
};

export default Footer;