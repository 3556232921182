// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_chartWrapper__0swkU {
    width: 100%;
    font-family: ManropeMedium, "Segoe UI", sans-serif;
}

.style_title__YZXjN {
    display: flex;
    justify-content: center;
}

.style_chartTitle__LAu88 {
    font-size: 2em;
    margin: .45em 0;
}

.style_chart__B7Mtu {
    width: auto;
    height: 600px;
}`, "",{"version":3,"sources":["webpack://./src/components/Main/Chart/style.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kDAAkD;AACtD;;AAEA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,aAAa;AACjB","sourcesContent":[".chartWrapper {\r\n    width: 100%;\r\n    font-family: ManropeMedium, \"Segoe UI\", sans-serif;\r\n}\r\n\r\n.title {\r\n    display: flex;\r\n    justify-content: center;\r\n}\r\n\r\n.chartTitle {\r\n    font-size: 2em;\r\n    margin: .45em 0;\r\n}\r\n\r\n.chart {\r\n    width: auto;\r\n    height: 600px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chartWrapper": `style_chartWrapper__0swkU`,
	"title": `style_title__YZXjN`,
	"chartTitle": `style_chartTitle__LAu88`,
	"chart": `style_chart__B7Mtu`
};
export default ___CSS_LOADER_EXPORT___;
