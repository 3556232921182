import { observer } from "mobx-react";

// STORES
import MainStore from "../../../modules/Main/MainStore.js";



const UniverseFooter = observer(() => {
    return (
        <footer className="universe-footer">
            <div>&copy; 2020-2024</div><a href={MainStore.ROOT_DOMAIN}>DEFAULT-SQUAD.RU</a> & <a href="https://vk.com/default_chatbot">Default Bot</a>
        </footer>
    );
});

export default UniverseFooter;