import { observer } from "mobx-react";


const NotFoundPage = observer(() => {
    return (
        <div className="index__card__title V-H__align smartText">
            Not Found
        </div>
    );
});

export default NotFoundPage;