import { observer } from "mobx-react";
import { useContext, useEffect } from "react";
import { Button } from "react-bootstrap";
import s from "./posts.module.css";

// STORES
import {BlogStoreContext, TitleStoreContext} from "../../App.js";

// COMPONENTS
import BlogPost from "../../components/Squad/Profile/BlogPost/BlogPost.jsx";



const PostsPage = observer(() => {
    const BlogStore = useContext(BlogStoreContext);
    const TitleStore = useContext(TitleStoreContext);

    useEffect(() => {
        async function fetchData() {
            await BlogStore.updateNews();
            await TitleStore.getUsersTitles();
        }

        fetchData();
    }, []);

    const nextPage = async () => {
        await BlogStore.incrementPageAllPosts();
    }

    const prevPage = async () => {
        await BlogStore.decrementPageAllPosts();
    }

    const toStartPage = async () => {
        await BlogStore.toStartPage();
    }

    const toEndPage = async () => {
        await BlogStore.toEndPage();
    }

    const search = (event) => {
        const value = event.target.value;
        BlogStore.search(value);
    }


    return (
        <div className="wrapper__content">
            <div className={s.postsList}>
                <div className="buttons-group">
                    <Button className="button" variant={"outline-secondary"} onClick={toStartPage}
                            disabled={BlogStore.pageGlobalPosts === 1 || BlogStore.globalPostsLoading}>
                        &lt;&lt;
                    </Button>
                    <Button className="button" variant={"outline-secondary"} onClick={prevPage}
                            disabled={BlogStore.pageGlobalPosts === 1 || BlogStore.globalPostsLoading}>
                        Предыдущая
                    </Button>
                    <Button className="button" variant={"outline-secondary"} onClick={nextPage}
                            disabled={BlogStore.pageGlobalPosts === BlogStore.totalPagesAllPosts || BlogStore.globalPostsLoading}>
                        Следующая
                    </Button>
                    <Button className="button" variant={"outline-secondary"} onClick={toEndPage}
                            disabled={BlogStore.pageGlobalPosts === BlogStore.totalPagesAllPosts || BlogStore.globalPostsLoading}>
                        &gt;&gt;
                    </Button>
                </div>
                <div style={{display: "flex", justifyContent: "center"}}>
                    Страница {BlogStore.pageGlobalPosts} из {BlogStore.totalPagesAllPosts}
                </div>
                {/*<div className="wheat-search">*/}
                {/*    <input type="text" placeholder="Поиск" onChange={search} />*/}
                {/*</div>*/}
                {!TitleStore.isMembersTitlesLoading && BlogStore.allPosts.map((post) => {
                    return <BlogPost key={post.Post.id} post={post}
                                     isPublic={true} />
                })}
                <div className="buttons-group">
                    <Button className="button" variant={"outline-secondary"} onClick={toStartPage}
                            disabled={BlogStore.pageGlobalPosts === 1 || BlogStore.globalPostsLoading}>
                        &lt;&lt;
                    </Button>
                    <Button className="button" variant={"outline-secondary"} onClick={prevPage}
                            disabled={BlogStore.pageGlobalPosts === 1 || BlogStore.globalPostsLoading}>
                        Предыдущая
                    </Button>
                    <Button className="button" variant={"outline-secondary"} onClick={nextPage}
                            disabled={BlogStore.pageGlobalPosts === BlogStore.totalPagesAllPosts || BlogStore.globalPostsLoading}>
                        Следующая
                    </Button>
                    <Button className="button" variant={"outline-secondary"} onClick={toEndPage}
                            disabled={BlogStore.pageGlobalPosts === BlogStore.totalPagesAllPosts || BlogStore.globalPostsLoading}>
                        &gt;&gt;
                    </Button>
                </div>
            </div>
            {/* <h2 style={{marginTop: 20}}>Это реклама? Это дефолт Фляма? Нет, это TON!</h2>
            <iframe height="2000px" width="100%" id="geckoterminal-embed" title="GeckoTerminal Embed" src="https://www.geckoterminal.com/ru/eth/pools/0x4b62fa30fea125e43780dc425c2be5acb4ba743b?embed=1&info=1&swaps=1" frameborder="0" allow="clipboard-write" allowfullscreen></iframe> */}
        </div>
    );
});

export default PostsPage;