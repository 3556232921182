import { observer } from "mobx-react";



const RainbowLine = observer(() => {
    return (
        <div className="rainbow-line"></div>
    );
});

export default RainbowLine;