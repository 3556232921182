import "bootstrap/dist/css/bootstrap.min.css";
import "./UI/global.css";
import "./UI/dark-theme.css";
import "./UI/light-theme.css";

import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";

import App from "./App.js";

const root = ReactDOM.createRoot(document.getElementById('root'));



root.render(
  <StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </StrictMode>
);

// http://default-squad.ru/
// https://ru.bem.info/methodology/quick-start/