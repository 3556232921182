import "./Event.css";

import {observer} from "mobx-react";
import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

// STORES
import MainStore from "../../modules/Main/MainStore.js";
import { EventStoreContext } from "../../App.js";

// COMPONENTS
import Header from "../../components/Squad/Header/Header.jsx";
import Footer from "../../components/Squad/Footer/Footer.jsx";
import AuthStore from "../Squad/Auth/AuthStore.js";
import EventStore from "./EventStore.js";



const EventRegistration = observer(({ element }) => {
    useEffect(() => {
        const fetchData = async () => {
            await EventStore.getMembers();
        }

        fetchData();
    }, []);

    const [nickname, setNickname] = useState("");

    const navigate = useNavigate();

    const eventStore = useContext(EventStoreContext);

    const OnChangeNickname = (event) => {
        setNickname(event.target.value);
    }

    const SubmitButton = async () => {
        setNickname("");
        await eventStore.registration(nickname);
    }

    return (
        <div className="event-wrapper">
            <div className="page">
                <div className="block">
                    <div className="event-title">
                        Регистрация на проект Civilisation
                    </div>
                    <hr />
                    <div className="form">
                        <div className="inputBox">
                            <input id="form-nickname" type="text" placeholder="Ник"
                                   minLength={3} maxLength={20} value={nickname}
                                   onChange={OnChangeNickname} required />
                        </div>
                        <div className="inputBox">
                            <input type="submit" value="Ок" id="input-event-submit" onClick={SubmitButton}/>
                        </div>
                    </div>
                </div>
                <div className="block">
                    <div className="event-title">
                        Зарегистрированные участники
                    </div>
                    <hr />
                    <div className="event-members">
                        {eventStore.isLoading ? <Spinner /> : eventStore.members.map((member) => {
                            let id = Math.random() * 1000000;
                            return (
                                <div className="event-member" id={id.toString()} key={id}>
                                    <div className="event-member-login">
                                        {member.nickname}
                                    </div>
                                    <div className="event-member-created">
                                        {new Date(member.createdAt).toLocaleDateString()}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
});

export default EventRegistration;