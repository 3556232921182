import { observer } from "mobx-react";
import { useContext, useEffect } from "react";
import rs from "./post.module.css";

// STORES
import {BlogStoreContext, TitleStoreContext} from "../../../App.js";
import BlogPost from "../Profile/BlogPost/BlogPost.jsx";

// COMPONENTS


export const Post = observer(({ postId }) => {
    const TitleStore = useContext(TitleStoreContext);
    const BlogStore = useContext(BlogStoreContext);

    useEffect(() => {
        async function fetchData() {
            await TitleStore.getUsersTitles();
            await BlogStore.getPostComments(postId);
        }

        fetchData();
    }, []);


    return (
        <div className={rs.wrapper}>
            <BlogPost isPublic={true} post={BlogStore.postComments} />
        </div>
    );
});