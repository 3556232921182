import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { useState, useContext } from "react";
import { Modal, Spinner } from "react-bootstrap";

// UI
import UniverseThemeToggleButton from "../../../UI/UniverseThemeToggleButton.jsx";

// COMPONENTS
import RainbowLine from "../../../components/Universe/Other/RainbowLine.jsx";

// STORES
import { UserStoreContext, PlayerStoreContext } from "../../../App.js";



const UniverseHeader = observer(() => {
    const User = useContext(UserStoreContext);
    const Player = useContext(PlayerStoreContext);

    const [changeNicknameOpen, setChangeNicknameOpen] = useState(false);
	const [newNickname, setNewNickname] = useState(Player.playerData.nickname);

    const navigate = useNavigate();

    const profileClickHandler = () => {
        navigate("/universe/profile");
    }

    const inventoryClickHandler = () => {
        navigate("/universe/inventory");
    }

    const storageClickHandler = () => {
        navigate("/universe/storage");
    }

    const marketClickHandler = () => {
        navigate("/universe/market");
    }

    const equipmentClickHandler = () => {
        navigate("/universe/equipment");
    }


    const changeNicknameToggle = () => {
        setChangeNicknameOpen(!changeNicknameOpen);
        setNewNickname(Player.playerData.nickname);
    }

    const applyChangeNickname = () => {
        if (Player.playerData.nickname === newNickname) {
            return;
        }

        Player.updateNickname(newNickname);
        changeNicknameToggle();
    }

    if (changeNicknameOpen) {
    	return (
			<div className="modal__wrapper">
				<Modal.Dialog className="universe-buttons">
					<Modal.Header>
						<Modal.Title>Сменить никнейм</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<div className="inputBox">
							<input type="text" placeholder={Player.playerData.nickname} onChange={(e) => e.target.value.length === 0 ? setNewNickname(Player.playerData.nickname) : setNewNickname(e.target.value)} />
						</div>
					</Modal.Body>

					<Modal.Footer>
						<div className="universe-buttons-group">
							<button onClick={applyChangeNickname}>Сохранить</button>
							<button onClick={changeNicknameToggle}>Отмена</button>
						</div>
					</Modal.Footer>
				</Modal.Dialog>
			</div>
      	);
    }


    return (
        <>
            <div className="universe-header">
                <div className="left-block">
                    <div className="universe-login-text" onClick={changeNicknameToggle}>
                        <div className="hint">
                            {Player.playerData.nickname}
                            {/* <span class="hint-text">
                                Нажмите на ник, чтобы сменить его
                            </span> */}
                        </div>
                    </div>
                    <div className="universe-buttons">
                        <div className="universe-buttons-group">
                            <button onClick={profileClickHandler}>
                                Профиль
                            </button>
                            <button onClick={inventoryClickHandler}>
                                Инвентарь
                            </button>
                            <button onClick={storageClickHandler}>
                                Склад
                            </button>
                            <button onClick={marketClickHandler}>
                                Биржа
                            </button>
                            <button onClick={equipmentClickHandler}>
                                Снаряжение
                            </button>
                        </div>
                    </div>
                </div>
                <div className="right-block">
                    <div className="currencies">
                        <div className="hint">
                            💰 {Player.playerData.baubles.toLocaleString()}
                            {/* <span className="hint-text">
                                Основная валюта вселенной - баблы
                            </span> */}
                        </div>
                        <div className="hint">
                            💠 {Player.playerData.baumonds.toLocaleString()}
                            {/* <span className="hint-text">
                                Второстепенная валюта - баумонды
                            </span> */}
                        </div>
                        <div className="hint">
                            {(User.userData.rubles).toFixed(2)} ₽
                            {/* <span className="hint-text">
                                Донат-валюта - рубли
                            </span> */}
                        </div>
                    </div>
                    <div className="universe-buttons">
                        <div className="theme-toggle-button" style={{marginLeft: 20}}>
                            <UniverseThemeToggleButton />
                        </div>
                    </div>
                </div>
            </div>
            <RainbowLine />
        </>
    );
});

export default UniverseHeader;