import { observer } from "mobx-react";
import { useContext } from "react";
import { Spinner } from "react-bootstrap";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";


// STORES
import { WildberriesStoreContext } from "../../App.js";

// COMPONENTS
import Chart from "../../components/WB/Chart.jsx";



const WB = observer(() => {
    const WildberriesStore = useContext(WildberriesStoreContext);

    if (WildberriesStore.isLoadingData) {
        return <center>Загрузка <Spinner /></center>;
    }

    let keys = Object.keys(WildberriesStore.clusters.chartsData);
    let timestamp = WildberriesStore.clusters.chartsData[keys[0]].timestamps[WildberriesStore.clusters.chartsData[keys[0]].timestamps.length - 1];


    return (
        <div className="wb__wrapper">
            <div className="wb__date-title">
                <h2>{WildberriesStore.clusters.todayDate}, {new Date(Number(timestamp)).toLocaleTimeString()}</h2>
            </div>
            <div className="wb__charts">
                {keys.map((key) => {
                    return <Chart key={WildberriesStore.clusters.chartsData[key].index} index={WildberriesStore.clusters.chartsData[key].index} cluster={key} chartData={WildberriesStore.clusters.chartsData[key]} />;
                })}
            </div>
        </div>
    );
});

export default WB;