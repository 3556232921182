import { observer } from "mobx-react";
import {Button, Dropdown, Image} from "react-bootstrap";
import {House, ArrowRight, ArrowUpRightSquare} from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import s from "./header.module.css";

import NotificationsCenter from "../../../modules/Main/NotificationsCenter.js";

import ThemeToggleButton from "../../../UI/ThemeToggleButton.jsx";

// STORES
import { UserStoreContext } from "../../../App.js";



const Header = observer(() => {
    const User = useContext(UserStoreContext);

    const navigate = useNavigate();

    const ProfileNavigate = () => {
        navigate("/profile");
    }

    const QuotesNavigate = () => {
        navigate("/quotes");
    }

    const MembersNavigate = () => {
        navigate("/members");
    }

    const HistoryNavigate = () => {
        navigate("/history");
        NotificationsCenter.ShowInfo("История будет дополняться");
    }

    const PostsNavigate = () => {
        navigate("/posts");
    }

    const LogoutHandler = async () => {
        await User.logout();
        navigate("/login");
    }

    return (
        <div className={s.header}>
            <div className={s.wrapper}>
                <div className={s.avatarLogin}>
                    <div className={s.avatar}>
                        <Image src={User.userData.avatarURL} title={User.userData.login + ' Avatar'} rounded/>
                    </div>
                    <div className={`${s.login} text-glitch`}>
                        {User.userData.login}
                    </div>
                </div>
                <div className={s.left}>
                    <div className={`buttons-group`}>
                        <div className={s.button} onClick={ProfileNavigate}>
                            Профиль
                        </div>
                        <div className={s.button} onClick={QuotesNavigate}>
                            Цитаты
                        </div>
                        <div className={s.button} onClick={MembersNavigate}>
                            Участники
                        </div>
                        <div className={s.button} onClick={HistoryNavigate}>
                            История
                        </div>
                        <div className={s.button} onClick={PostsNavigate}>
                            Посты
                        </div>
                    </div>
                </div>

                <div className={`${s.right}`}>
                    <div className={`buttons-group`}>
                        <ThemeToggleButton/>
                        <Dropdown>
                            <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="/profile">Профиль</Dropdown.Item>
                                <Dropdown.Item href="/quotes">Цитаты</Dropdown.Item>
                                <Dropdown.Item href="/members">Участники</Dropdown.Item>
                                <Dropdown.Item href="/history">История</Dropdown.Item>
                                <Dropdown.Item href="/posts">Посты</Dropdown.Item>

                                <Dropdown.Item href="/">Главная <House /></Dropdown.Item>
                                <Dropdown.Item onClick={LogoutHandler}>Выход <ArrowUpRightSquare /></Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Header;