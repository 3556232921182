import { observer } from "mobx-react";
import { Button, Spinner } from "react-bootstrap";
import { useEffect, useContext, useState } from "react";

// STORES
import { UserStoreContext } from "../../../App.js";
import QuotesStore from "./QuotesStore.js";

// COMPONENTS
import Header from "../../../components/Squad/Header/Header.jsx";
import Footer from "../../../components/Squad/Footer/Footer.jsx";
import Quote from "./Quote.jsx";
import SuggestedQuote from "./SuggestedQuote.jsx";
import AddQuoteForm from "./AddQuoteForm.jsx";
import AddSuggestedQuoteForm from "./AddSuggestedQuoteForm.jsx";

// MODULES
import SquadProtected from "../../../modules/Squad/SquadProtected.jsx";
import NotificationCenter from "../../../modules/Main/NotificationsCenter.js";



const Quotes = observer(() => {
    const [author, setAuthor] = useState("");
    const userStore = useContext(UserStoreContext);

    useEffect(() => {
        const fetchData = async () => {
            await QuotesStore.loadingQuotes();
        }
        
        fetchData();
    }, []);

    const OnChangeInputAuthor = (event) => {
        setAuthor(event.target.value);
    }

    const SearchFromAuthor = () => {
        if (author === "") {
            return;
        }

        QuotesStore.getQuotesFromAuthor(author);
    }

    const UpdateQuotesHandler = async () => {
        await QuotesStore.updateQuotes();
        NotificationCenter.ShowSuccess("Цитаты обновлены");
    }

    // let columns = 3; // TODO: Сделать нормально колонки с цитатами красивенько | 29.01.2023

    return (
        <div className="wrapper__content">
            <div className="page__quotes-header">
                <div className="page__quotes__addQuoteForm">
                    {userStore.userData.accessLevel > 2 &&
                    !QuotesStore.isSuggestedForm ?
                    <div className="add-quote-form__container">
                        <AddQuoteForm />
                    </div> :
                    <div className="add-quote-form__container">
                        <AddSuggestedQuoteForm />
                    </div>}
                </div>
                <div className="page__quotes__buttons-area">
                    <div className="buttons-group">
                        <Button className="button" variant={!QuotesStore.isShowQuotesID ? "outline-secondary" : "outline-success"} onClick={() => QuotesStore.toggleIsShowQuotesID()}>
                            Показывать ID цитат
                        </Button>
                        <Button className="button" variant={!QuotesStore.isPhotoMode ? "outline-secondary" : "outline-success"} onClick={() => QuotesStore.toggleIsPhotoMode()}>
                            Фоторежим
                        </Button>
                        <Button className="button" variant={"outline-secondary"} onClick={UpdateQuotesHandler}>
                            Обновить
                        </Button>
                    </div>
                    <div className="page__quotes__sort-buttons">
                        Сортировать по:
                        <Button className="button" variant={!QuotesStore.isSortValution ? "outline-secondary" : "outline-success"} style={{marginLeft: 5}} onClick={() => QuotesStore.sortValution()}>
                            Оценка
                        </Button>
                    </div>
                    <div className="page__quotes__search-author">
                        <input type="text" placeholder="Поиск по автору" onChange={OnChangeInputAuthor} maxLength={100}></input>
                        <Button className="button" variant={"outline-secondary"} onClick={SearchFromAuthor} style={{marginLeft: 5}}>
                            Найти
                        </Button>
                    </div>
                </div>
            </div>
            <hr></hr>
            {/* TODO: Доделать жёстко цитаты */}
            <div className="page__quotes-footer">
                <div className="page__quotes__quote-area">
                    <div className="quotes__sections">
                        <div className="quotes__section">
                            {QuotesStore.isQuotesLoading ? <Spinner /> :
                            <>
                                <div className="quotes__section-title">
                                    Цитаты великих людей Default Squad
                                </div>
                                <div className="quotes__list">
                                {QuotesStore.quotes.map((quote) => {
                                    let isShowValution = !quote.quoteValutionUsers.includes(userStore.userId);
                                    return <Quote key={quote.id} id={quote.id} text={quote.text} createdAt={new Date(Number(quote.createdAt)).toLocaleDateString()} authors={quote.authors} avgValution={quote.avgValution} isShowValution={isShowValution} quoteValutionUsers={quote.quoteValutionUsers} />;
                                })}
                                </div>
                            </>
                            }
                        </div>
                        {userStore.userData.accessLevel > 3 && QuotesStore.suggestedQuotes.length !== 0 &&
                        <div className="quotes__section">
                            <div className="quotes__section-title">
                                Предложка по цитатам
                            </div>
                            <div className="quotes__list">
                                {QuotesStore.suggestedQuotes.map((quote) => {
                                    return <SuggestedQuote key={quote.id} id={quote.id} text={quote.text} createdAt={new Date(Number(quote.createdAt)).toLocaleDateString()} authors={quote.authors} whoSuggested={quote.whoSuggested}/>;
                                })}
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
            

        /* <center>
            <div className="page__body">
                {userStore.userData.accessLevel > 2 &&
                !QuotesStore.isSuggestedForm ?
                <div className="add-quote-form__container">
                    <AddQuoteForm />
                </div> :
                <div className="add-quote-form__container">
                    <AddSuggestedQuoteForm />
                </div>}
                <div className="quotes__attention">
                    ⚠ Существует довольно частый баг. При отправке цитате оценки, она ей не приходит. Смотрите каждый раз на изменяющуюся среднюю оценку. Если она изменилась - оценка поставлена, можно дальше ставить оценки. Если же нет - обновите страницу, иначе ваши последующие оценки не учтутся.<hr></hr><Center>Ведутся (нет) работы по устранению данной проблемы</Center>
                </div>
                <div style={{marginTop: 7}}>
                    {QuotesStore.isQuotesUpdate ? <Spinner animation="border" /> : `Всего оценок: ${QuotesStore.AllValutions} из ${QuotesStore.maxValutions} (${(QuotesStore.AllValutions / QuotesStore.maxValutions * 100).toFixed(2)}%)`}
                </div>
                <div className="quotes__options-buttons">
                    <Button variant={!QuotesStore.IsShowQuotesID.get() ? "outline-secondary" : "outline-success"} onClick={() => QuotesStore.toggleIsShowQuotesID()}>
                        Показывать ID цитат
                    </Button>
                    <Button style={{marginLeft: 5}} variant={!QuotesStore.IsPhotoMode.get() ? "outline-secondary" : "outline-success"} onClick={() => QuotesStore.toggleIsPhotoMode()}>
                        Фоторежим
                    </Button>
                    <Button style={{marginLeft: 5}} variant={"outline-secondary"} onClick={() => QuotesStore.updateQuotes()}>
                        Обновить
                    </Button>
                </div>
                <div className="quotes__sort-buttons">
                    Сортировать по:
                    <Button variant={!QuotesStore.IsSortValution.get() ? "outline-secondary" : "outline-success"} style={{marginLeft: 5}} onClick={() => QuotesStore.sortValution()}>
                        Оценка
                    </Button>
                </div>
                <div className="quotes__search-author">
                    <input type="text" placeholder="Поиск по автору" onChange={OnChangeInputAuthor} maxLength={100}></input>
                    <Button variant={"outline-secondary"} onClick={SearchFromAuthor} style={{marginLeft: 5}}>
                        Найти
                    </Button>
                </div>
                <div className="quotes__sections">
                    <div className="quotes__section">
                        <div className="quotes__section-title">
                            Цитаты великих людей Default Squad
                        </div>
                        <ol className="quotes__list">
                            {QuotesStore.Quotes.get().map((quote) => {
                                let isShowValution = !quote.quoteValutionUsers.includes(userStore.userId);
                                return <Quote key={quote.id} id={quote.id} text={quote.text} createdAt={new Date(Number(quote.createdAt)).toLocaleDateString()} authors={quote.authors} avgValution={quote.avgValution} isShowValution={isShowValution} quoteValutionUsers={quote.quoteValutionUsers} />;
                            })}
                        </ol>
                    </div>
                    {userStore.userData.accessLevel > 3 && QuotesStore.SuggestedQuotes.get().length != 0 &&
                    <div className="quotes__section">
                        <div className="quotes__section-title">
                            Предложка по цитатам
                        </div>
                        <ol className="quotes__list">
                            {QuotesStore.SuggestedQuotes.get().map((quote) => {
                                return <SuggestedQuote key={quote.id} id={quote.id} text={quote.text} createdAt={new Date(Number(quote.createdAt)).toLocaleDateString()} authors={quote.authors} whoSuggested={quote.whoSuggested}/>;
                            })}
                        </ol>
                    </div>}
                </div>
            </div>
        </center> */
    );
});

export default Quotes;