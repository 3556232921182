import {useContext, useEffect} from "react";
import {Spinner} from "react-bootstrap";
import s from "../../../components/Wheat/Items/ItemTopAmount/style.module.css";

import {WheatItemsStoreContext} from "../../../App.js";

import {observer} from "mobx-react";

// COMPONENTS
import ItemTopAmount from "../../../components/Wheat/Items/ItemTopAmount/ItemTopAmount.jsx";



const TopAmount = observer(() => {
    const WIS = useContext(WheatItemsStoreContext);

    useEffect(() => {
        const fetchData = async () => {
            await WIS.getItemsTopAmount();
        }

        fetchData();
    }, [WIS]);

    return (
        <div className="wheat__wrapper-content">
            <div className="wheat__list-title">
                ТОП по количеству
            </div>
            <div className="wheat-search">
                <input type="text" placeholder="Поиск" onChange={WIS.onChangeSearch} />
            </div>
            {WIS.itemsLoading ? <Spinner /> :
                <table className={s.rdTable}>
                    <thead>
                    <tr>
                        <th className={s.rdTable__cell}>
                            Изображение
                        </th>
                        <th className={s.rdTable__cell}>
                            Название
                        </th>
                        <th className={s.rdTable__cell}>
                            Количество
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                        {WIS.isSorted
                            ? WIS.itemsSorted.map((item, index) => <ItemTopAmount key={item.id} item={item} index={index} />)
                            : WIS.items.map((item, index) => <ItemTopAmount key={item.id} item={item} index={index} />)
                        }
                    </tbody>
                </table>
            }
        </div>
    );
});

export default TopAmount;