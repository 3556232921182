import React, { useState, useContext } from "react";
import { observer } from "mobx-react";
import { Button } from "react-bootstrap";

// STORES
import { UserStoreContext } from "../../../App.js";

import NotificationCenter from "../../../modules/Main/NotificationsCenter.js";



const AvatarUploadForm = observer(() => {
    const [file, setFile] = useState(null);

    const User = useContext(UserStoreContext);

    const HandleFileChange = (e) => {
        setFile(e.target.files[0]);
    }

    const HandleUpload = async () => {
        if (file == null) {
            return NotificationCenter.ShowError("Выберите файл");
        }

        let allowedExtension = "image";
        let [ type, extension ] = file.type.split("/");

        if (type != allowedExtension) {
            return NotificationCenter.ShowWarn("Загружаемый файл не является изображением");
        }

        const newFile = new File([file], `${User.userData.id}.jpg`, {
            type: `${type}/${extension}`
        });
        
        const formData = new FormData();
        formData.append("avatar", newFile);

        await User.uploadAvatar(formData);

        NotificationCenter.ShowSuccess("Аватар обновлен");
    }

    return (
        <div className="profile__avatar-upload">
            <div className="edit-photo">
                Изменить фото
                <input about="Выбрать файл" type="file" accept=".jpg, .jpeg, .png, .gif" onChange={HandleFileChange} />
            </div>
            <div>
                <Button onClick={HandleUpload} variant="outline-primary" disabled={User.isAvatarUpload} style={{width: 210, marginTop: 5}}>
                    Загрузить
                </Button>
            </div>
        </div>
    )
});

export default AvatarUploadForm;