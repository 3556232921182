// COMPONENTS
import {observer} from "mobx-react";
import {Button} from "react-bootstrap";
import {useNavigate} from "react-router-dom";



const WheatIndex = observer(() => {
    const navigate = useNavigate();

    const ItemsProportionalDistributionNavigate = () => navigate("/wheat/items/proportional-distribution");
    const ItemsTopAmountNavigate = () => navigate("/wheat/items/top-amount");
    const ItemsTopAmountDynamicsNavigate = () => navigate("/wheat/items/top-amount-dynamics");
    const PlayersStatisticsNavigate = () => navigate("/wheat/players-statistics");

    return (
        <div className="wheat__wrapper-content">
            <div className="wheat__index-title">
                Wheat
            </div>
            <div className="wheat__section-list">
                <Button variant="light" onClick={PlayersStatisticsNavigate} disabled={true}>
                    Статистика игроков (WIP)
                </Button>
                <Button variant="secondary" onClick={ItemsProportionalDistributionNavigate}>
                    Пропорциональное распределение
                </Button>
                <Button variant="secondary" onClick={ItemsTopAmountNavigate}>
                    ТОП по количеству
                </Button>
                <Button variant="secondary" onClick={ItemsTopAmountDynamicsNavigate}>
                    ТОП по динамике
                </Button>
            </div>
        </div>
    );
});

export default WheatIndex;