import {useContext, useEffect} from "react";
import s from "./disassembler.module.css";
import {Item} from "./Item.jsx";
import {DisassemblerStoreContext} from "../../../../App.js";
import {observer} from "mobx-react";
import {AddItemForm} from "../../../../modules/Other/GTNH/Disassembler/AddItemForm.jsx";



export const DisassemblerPage = observer(() => {
    const DisassemblerStore = useContext(DisassemblerStoreContext);

    useEffect(() => {
        async function fetchData() {
            await DisassemblerStore.getFiles();
        }

        fetchData();
    }, []);

    const searchApply = (event) => {
        const itemName = event.target.value;
        DisassemblerStore.sort(itemName);
    }

    const emc = DisassemblerStore.recipeInfo?.emc || DisassemblerStore.itemData?.emc;
    const coins = Math.ceil(emc / 0.1159);

    return (
        <div className={s.wrapper}>
            <AddItemForm />
            <div>
                <input type={"text"} placeholder="Search" onChange={searchApply} />
            </div>
            <div>

            </div>
            <div className={s.scrollContainer}>
                {!DisassemblerStore.isLoading &&
                    DisassemblerStore.isSorted
                    ? DisassemblerStore.sortedItems.map((file, index) => {
                        return (
                            <Item key={index} itemName={file} />
                        )
                    })
                    : DisassemblerStore.files.map((file, index) => {
                        return (
                            <Item key={index} itemName={file} />
                        )
                    })
                }
            </div>

            <div className={s.dataContainer}>
                <div>
                    {Object.keys(DisassemblerStore.itemData).length !== 0 ? DisassemblerStore.itemData.disassembleItem : "Not selected"}
                </div>
                <div>
                    {emc && `${emc.toFixed(4)} EMC / ${coins} Coins`}
                </div>
                <div>
                    {DisassemblerStore.recipeInfo?.ingredients ? "Ingredients" : ""}
                </div>
                {DisassemblerStore.recipeInfo?.ingredients?.map(item => {
                    return (
                        <Item itemName={item.name} amountNeed={item?.amountNeed ? item.amountNeed : 1} />
                        //<div>name: {item.name}</div>
                    )
                })}
            </div>
        </div>
    );
});