import SquadProtected from "../../../modules/Squad/SquadProtected.jsx";



const SquadWrapper = ({ element }) => {
    return (
        <div className="wrapper">
            <SquadProtected element={element} />
        </div>
    );
}

export default SquadWrapper;