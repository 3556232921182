import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

// STORES
import MainStore from "../../modules/Main/MainStore.js";
import { UserStoreContext } from "../../App.js";

// COMPONENTS
import Header from "../../components/Wheat/Header/WheatHeader.jsx";
import Footer from "../../components/Squad/Footer/Footer.jsx";



const WheatProtected = ({ element }) => {
    const [isAuth, setIsAuth] = useState(null);

    const navigate = useNavigate();

    const User = useContext(UserStoreContext)

    useEffect(() => {
        async function checkAuth() {
            try {
                const data = (await axios.post(`${MainStore.SERVER_ADDRESS}/getAccess`, {
                    userId: User?.userId || 0
                }, {
                    withCredentials: true
                })).data;

                if (!data.isAuth) {
                    return navigate("/login");
                }

                User.setUserData(data.userData);

                setIsAuth(true);
            } catch (e) {
                setIsAuth(false);
            }
        }

        checkAuth();
    }, [navigate]);

    if (isAuth === null) {
        return <Spinner />;
    }

    return (
        <>
            <Header />
            {element}
            <Footer />
        </>
    );
}

export default WheatProtected;