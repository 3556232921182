// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (min-width: 500px) {
    .disassembler_wrapper__VBzhJ {
        padding: 2em !important;
    }
}



.disassembler_wrapper__VBzhJ {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em 5em;
}

.disassembler_list__KyOTO {
    display: grid;
    row-gap: .5em;
}

.disassembler_item__nwZV9 {
    cursor: pointer;
    padding: .4em .2em;
    width: 20em;
    border: 1px solid gray;

    &:hover {
        background: #4d5154;
    }
}

.disassembler_scrollContainer__AmmGi {
    width: 300px;
    height: 600px;
    overflow-y: auto; /* Включить вертикальную прокрутку */
    border: 1px solid #ccc; /* Опциональная рамка */
    padding: 10px;
    margin: 20px;
}

.disassembler_dataContainer__40iA8 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/Other/GTNH/Disassembler/disassembler.module.css"],"names":[],"mappings":"AAAA;IACI;QACI,uBAAuB;IAC3B;AACJ;;;;AAIA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,WAAW;IACX,sBAAsB;;IAEtB;QACI,mBAAmB;IACvB;AACJ;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,gBAAgB,EAAE,oCAAoC;IACtD,sBAAsB,EAAE,uBAAuB;IAC/C,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB","sourcesContent":["@media screen and (min-width: 500px) {\r\n    .wrapper {\r\n        padding: 2em !important;\r\n    }\r\n}\r\n\r\n\r\n\r\n.wrapper {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    padding: 2em 5em;\r\n}\r\n\r\n.list {\r\n    display: grid;\r\n    row-gap: .5em;\r\n}\r\n\r\n.item {\r\n    cursor: pointer;\r\n    padding: .4em .2em;\r\n    width: 20em;\r\n    border: 1px solid gray;\r\n\r\n    &:hover {\r\n        background: #4d5154;\r\n    }\r\n}\r\n\r\n.scrollContainer {\r\n    width: 300px;\r\n    height: 600px;\r\n    overflow-y: auto; /* Включить вертикальную прокрутку */\r\n    border: 1px solid #ccc; /* Опциональная рамка */\r\n    padding: 10px;\r\n    margin: 20px;\r\n}\r\n\r\n.dataContainer {\r\n    width: 100%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `disassembler_wrapper__VBzhJ`,
	"list": `disassembler_list__KyOTO`,
	"item": `disassembler_item__nwZV9`,
	"scrollContainer": `disassembler_scrollContainer__AmmGi`,
	"dataContainer": `disassembler_dataContainer__40iA8`
};
export default ___CSS_LOADER_EXPORT___;
