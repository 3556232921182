import { observer } from "mobx-react";
import { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";

// STORES
import { BlogStoreContext, TitleStoreContext } from "../../App.js";

// COMPONENTS
import Header from "../../components/Squad/Header/Header.jsx";
import Footer from "../../components/Squad/Footer/Footer.jsx";
import BlogPost from "../../components/Squad/Profile/BlogPost/BlogPost.jsx";
import SquadWrapper from "../../components/Squad/Wrapper/SquadWrapper.jsx";



const PostsPage = observer(() => {
    const [isHighlighted, setIsHighlighted] = useState(null);

    const BlogStore = useContext(BlogStoreContext);
    const TitleStore = useContext(TitleStoreContext);

    useEffect(() => {
        async function fetchData() {
            await BlogStore.updateNews();
            await TitleStore.getUsersTitles();
        }

        fetchData();

        const handleHashChange = () => {
            const hash = window.location.hash;
            const targetId = hash.substring(1);
            setIsHighlighted(Number(targetId));

            setTimeout(() => {
                setIsHighlighted(null);
            }, 1000 * 2);
        }

        handleHashChange();

        window.addEventListener("hashchange", handleHashChange);

        return () => {
            window.removeEventListener("hashchange", handleHashChange);
        }
    }, []);

    const nextPage = () => {
        BlogStore.incrementPageAllPosts();
    }
    
    const prevPage = () => {
        BlogStore.decrementPageAllPosts();
    }

    const toStartPage = () => {
        BlogStore.toStartPage();
    }

    const toEndPage = () => {
        BlogStore.toEndPage();
    }
    

    return (
        <div className="wrapper__content">
            <div className="blog-posts-posts">
                <div className="buttons-group">
                    <Button className="button" variant={"outline-secondary"} onClick={toStartPage}
                            disabled={BlogStore.currentPageAllPosts === 0}>
                        &lt;&lt;
                    </Button>
                    <Button className="button" variant={"outline-secondary"} onClick={prevPage}
                            disabled={BlogStore.currentPageAllPosts === 0}>
                        Предыдущая
                    </Button>
                    <Button className="button" variant={"outline-secondary"} onClick={nextPage}
                            disabled={BlogStore.currentPageAllPosts + 1 === BlogStore.totalPagesAllPosts}>
                        Следующая
                    </Button>
                    <Button className="button" variant={"outline-secondary"} onClick={toEndPage}
                            disabled={BlogStore.currentPageAllPosts + 1 === BlogStore.totalPagesAllPosts}>
                        &gt;&gt;
                    </Button>
                </div>
                Страница {BlogStore.currentPageAllPosts + 1} из {BlogStore.totalPagesAllPosts}
                {!TitleStore.isMembersTitlesLoading && BlogStore.allPosts.slice(BlogStore.currentPageAllPosts * BlogStore.maxItemsOnPageAllPosts, (BlogStore.currentPageAllPosts + 1) * BlogStore.maxItemsOnPageAllPosts).map((post) => {
                    return <BlogPost classNameExtend={isHighlighted === post.Post.id} key={post.Post.id} post={post}
                                     isPublic={true}/>
                })}
            </div>
            {/* <h2 style={{marginTop: 20}}>Это реклама? Это дефолт Фляма? Нет, это TON!</h2>
            <iframe height="2000px" width="100%" id="geckoterminal-embed" title="GeckoTerminal Embed" src="https://www.geckoterminal.com/ru/eth/pools/0x4b62fa30fea125e43780dc425c2be5acb4ba743b?embed=1&info=1&swaps=1" frameborder="0" allow="clipboard-write" allowfullscreen></iframe> */}
        </div>
    );
});

export default PostsPage;