import ThemeToggleButton from "../../UI/ThemeToggleButton.jsx";


const PublicHeader = (props) => {
    return (
        <header className="page__header__no-border">
            <div className="page__header__left-block">
                <div className="page__header__left-block__name">
                    {props.title}
                </div>
            </div>
            <div className="page__header__right-block">
                <div className="page__header__right-block__buttons">
                    <ThemeToggleButton />
                </div>
            </div>
        </header>
    );
}

export default PublicHeader;