import { useEffect } from "react";
import { observer } from "mobx-react";

// COMPONENTS
import PublicHeader from "../../components/Main/PublicHeader.jsx";

import UniverseItemsStore from "../../modules/Universe/UniverseEditItemsStore.js";
import ItemComponent from "./ItemComponent.jsx";



const UniverseEditItemsPage = observer(() => {
    useEffect(() => {
        async function fetchData() {
            await UniverseItemsStore.getItems();
        }

        fetchData();
    }, []);

    return (
        // foreach items and create table with buttons
        <div className="wrapper__content">
            ТАБЛИЦА ПРЕДМЕТОВ ВСЕЛЕННОЙ DEFAULT [BETA]
            <table className="universe__table">
                <thead>
                    <tr>
                        <th>
                            UPD
                        </th>
                        <th>
                            ID
                        </th>
                        <th>
                            Name
                        </th>
                        <th>
                            Desc.
                        </th>
                        <th>
                            Цена
                        </th>
                        <th>
                            Шанс
                        </th>
                        <th>
                            Редкость
                        </th>
                        <th>
                            Падает с
                        </th>
                        <th>
                            Тип
                        </th>
                        <th>
                            Урон
                        </th>
                        <th>
                            Защита
                        </th>
                        <th>
                            IRC
                        </th>
                        <th>
                            УР
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {!UniverseItemsStore.isLoading && UniverseItemsStore.items.map(item => {
                        return <ItemComponent key={item.itemId} item={item} />
                    })}
                </tbody>
            </table>
        </div>
    );
});

export default UniverseEditItemsPage;