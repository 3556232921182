import { makeAutoObservable, runInAction } from "mobx";
import axios from "axios";

// STORES
import MainStore from "../Main/MainStore.js";



class Player {
    playerData = {};
    isLoadingPlayerData = true;

    constructor() {
        makeAutoObservable(this);
    }

    initialize = async () => {
        await this.getPlayerData();
    }

    getPlayerData = async () => {
        let response = await axios.post(`${MainStore.SERVER_ADDRESS}/universe/getPlayerData`, {
            playerId: 1
        }, {
            withCredentials: true
        });

        if (!response?.data) {
            return;
        }

        runInAction(() => {
            this.playerData = response.data;
            this.isLoadingPlayerData = false;
        });
    }

    updateNickname = (newNickname) => {
        axios.post(`${MainStore.SERVER_ADDRESS}/universe/changeNickname`, {
            playerId: this.playerData.botId,
            newNickname
        }, {
            withCredentials: true
        });

        runInAction(() => {
            this.playerData.nickname = newNickname;
        });
    }
}

export default new Player();