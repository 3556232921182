import { observer } from "mobx-react";
import { Button, Form } from "react-bootstrap";
import { useState, useContext } from "react";
import axios from "axios";

// STORES
import MainStore from "../../../modules/Main/MainStore.js";
import QuotesStore from "./QuotesStore.js";
import { UserStoreContext } from "../../../App.js";

// MODULES
import NotificationCenter from "../../../modules/Main/NotificationsCenter.js";



const AddSuggestedQuoteForm = observer(() => {
    const User = useContext(UserStoreContext);

    const [textQuote, setTextQuote] = useState("");
    const [createdAtQuote, setCreatedAtQuote] = useState("");
    const [authorsQuote, setAuthorsQuote] = useState("");

    const OnChangeTextQuote = (event) => {
        setTextQuote(event.target.value);
    }
    
    const OnChangeCreatedAtQuote = (event) => {
        setCreatedAtQuote(event.target.value);
    }

    const OnChangeAuthorsQuote = (event) => {
        setAuthorsQuote(event.target.value);
    }

    const AddSuggestedQuote = async () => {
        if (textQuote === "" || createdAtQuote === "" || authorsQuote === "") {
            return;
        }

        if (textQuote.length > 255) {
            return NotificationCenter.ShowWarn("Текст слишком большой");
        }

        const date = new Date(createdAtQuote);
        date.setHours(date.getHours() - 3);
        const milliseconds = date.getTime();

        const response = await axios.post(`${MainStore.SERVER_ADDRESS}/addSuggestedQuote`, {
            text: textQuote,
            createdAt: milliseconds,
            authors: authorsQuote.split(" ").join(", "),
            whoSuggested: User.userData.login
        }, {
            withCredentials: true
        });

        NotificationCenter.ShowSuccess(response.data.message);

        await QuotesStore.updateQuotes();
    }

    const ChangeForm = () => {
        QuotesStore.toggleIsSuggestedForm();
    }

    return (
        <div className="add-quote-form">
            <div className="add-quote-form__title">
                Предложить цитату
            </div>
            <Form>
                <Form.Control as="textarea" placeholder="Текст цитаты" className="mb-2" onChange={OnChangeTextQuote} />
                <Form.Control type="text" placeholder="Автор1 Автор2" className="mb-2" onChange={OnChangeAuthorsQuote} />
                <Form.Control type="date" placeholder="Дата" onChange={OnChangeCreatedAtQuote} />
            </Form>
            <div className="add-quote-form__button">
                <Button className="button" variant="outline-secondary" onClick={AddSuggestedQuote}>
                    Отправить
                </Button>
                {User.userData.accessLevel > 2 &&
                <Button className="button" variant="outline-secondary" onClick={ChangeForm} style={{marginLeft: 5}}>
                    Переключить
                </Button>}
            </div>
        </div>
    );
});

export default AddSuggestedQuoteForm;