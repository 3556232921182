import { observer } from "mobx-react";
import { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

// COMPONENTS
import MarketLot from "./MarketLot.jsx";
import RainbowLine from "../Other/RainbowLine.jsx";

// STORES
import { MarketStoreContext } from "../../../App.js";



const MarketList = observer(() => {
    const Market = useContext(MarketStoreContext);

    const [addLotModalOpen, setAddLotModalOpen] = useState(false);

    const toggleAddLotModal = () => {
        setAddLotModalOpen(!addLotModalOpen);
    }

    const addLotHandle = () => {
        // toggleAddLotModal(); TODO: разкомментировать потом

        Market.addMarketLot();
    }


    return (
        <div className="universe__page-body">
            <div className="market-list__header">
                <div className="left-block">
                    <h2>Биржа</h2>
                </div>
                <div className="right-block">
                    <div className="universe-buttons">
                        <button onClick={toggleAddLotModal}>
                            Добавить лот
                        </button>
                    </div>
                </div>
            </div>
            <table className="universe__table">
                <thead>
                    <tr className="table__categories-sticky">
                        <th>
                            Предмет
                        </th>
                        <th>
                            Тип
                        </th>
                        <th>
                            Лотов на продажу
                        </th>
                        <th>
                            Лотов на покупку
                        </th>
                        <th>
                            Продажа от
                        </th>
                        <th>
                            Спрос
                        </th>
                    </tr>
                </thead>
                <tbody className="inventory-list">
                    {Market.marketLots.map((lot) => {
                        return <MarketLot key={`${lot.id}`} lot={lot} />
                    })}
                </tbody>
            </table>

            <Modal
                show={addLotModalOpen}
                backdrop="static"
                keyboard={false}
                >
                <Modal.Header>
                    <Modal.Title>
                        Регистрация лота
                    </Modal.Title>
                </Modal.Header>
                <RainbowLine />
                <Modal.Body>
                    {/* <DropdownButton title="Тип">
                        <Dropdown.Item>Оружие</Dropdown.Item>
                    </DropdownButton> */}
                    СДЕЛАТЬ НОРМАЛЬНО
                </Modal.Body>
                <Modal.Footer>
                    <div className="universe-buttons universe-buttons-group">
                        <button onClick={toggleAddLotModal}>
                            Отмена
                        </button>
                        <button onClick={addLotHandle}>
                            Добавить
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
});

export default MarketList;