// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 1000px) {
    .posts_postsList__-16r0 {
        font-size: .75em !important;
    }
}


.posts_postsList__-16r0 {
    display: grid;
    grid-template-rows: auto auto auto auto auto;
    justify-content: center;
    row-gap: 1em;

    font-size: 1em;
}

`, "",{"version":3,"sources":["webpack://./src/pages/Squad/posts.module.css"],"names":[],"mappings":"AAAA;IACI;QACI,2BAA2B;IAC/B;AACJ;;;AAGA;IACI,aAAa;IACb,4CAA4C;IAC5C,uBAAuB;IACvB,YAAY;;IAEZ,cAAc;AAClB","sourcesContent":["@media screen and (max-width: 1000px) {\r\n    .postsList {\r\n        font-size: .75em !important;\r\n    }\r\n}\r\n\r\n\r\n.postsList {\r\n    display: grid;\r\n    grid-template-rows: auto auto auto auto auto;\r\n    justify-content: center;\r\n    row-gap: 1em;\r\n\r\n    font-size: 1em;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"postsList": `posts_postsList__-16r0`
};
export default ___CSS_LOADER_EXPORT___;
