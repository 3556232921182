import style from "./style.module.css";
import { useContext } from "react";

import NotificationsCenter from "../../../../modules/Main/NotificationsCenter.js";

// STORES
import { TitleStoreContext } from "../../../../App.js";
import {Button} from "react-bootstrap";


const BlogPost = ({ classNameExtend, post, BlogStore, isPublic }) => {
    const TitleStore = useContext(TitleStoreContext);

    const DeletePostHandler = async () => {
        if (BlogStore.isBlogDeleting) {
            return NotificationsCenter.ShowWarn("Дождитесь удаления записи поста");
        }

        await BlogStore.deleteBlogPost(post.Post.id);
    }

    const handleClick = (event) => {
        event.preventDefault();
        window.location.hash = post.Post.id;
    }

    return (
        <div className={classNameExtend ? "blog-post s-container highlighted" : "blog-post s-container"} id={post.Post.id}>
            {isPublic &&
                <div className="post-author-info">
                    <div className={style.authorBox}>
                        <img className={style.avatar} src={post.User.avatar}
                             alt={"Avatar"}></img>
                        <a className="post-author-name" href={`#members/${post.User.userId}`} dir="auto">
                            {post.User.login}
                        </a>
                        <div>
                            {TitleStore.membersTitlesObj[post.User.login] !== null &&
                                <div className={`member__title-${TitleStore.membersTitlesObj[post.User.login].rarity}`}
                                     style={{borderColor: TitleStore.membersTitlesObj[post.User.login].color}}>{TitleStore.membersTitlesObj[post.User.login].title}
                                </div>}
                        </div>
                    </div>
                </div>}

            {isPublic ? <a className="post-text" href={`#${post.Post.id}`} onClick={handleClick}>{post.Post.text}</a> :
                <div className="post-text">{post.Post.text}</div>}

            <div>
                <span className="post-date" title={new Date(Number(post.Post.createdAt)).toLocaleString()}>
                    {new Date(Number(post.Post.createdAt)).toLocaleString("ru", {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric'
                    })}{isPublic &&
                        <div style={{
                                display: "flex",
                                alignItems: "center",
                                columnGap: 5
                            }}>
                        </div>}
                </span>

                {!isPublic &&
                <div className="buttons-group" style={{margin: 0}}>
                    <Button
                        className="button" variant={"outline-secondary"} onClick={DeletePostHandler}>
                        Удалить
                    </Button>
                </div>}
            </div>
        </div>
    );
}

export default BlogPost;