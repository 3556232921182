import { observer } from "mobx-react";


const HistoryList = observer(() => {
    return (
        <div className="wrapper__content">
            <div className="history__page">
                <div className="container__year">
                    <div className="year-name">
                        2020
                    </div>

                    <div className="months">
                        <div className="history__month">
                            <div className="month-name">
                                Май, 3
                            </div>
                            <div className="month-text">
                                Создание дискорд сервера сквада. Формирование Default Squad
                            </div>
                        </div>

                        <div className="history__month">
                            <div className="month-name">
                                Октябрь, 25
                            </div>
                            <div className="month-text">
                                Запуск сервера Wheat на версии 1.16.4
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container__year">
                    <div className="year-name">
                        2021
                    </div>

                    <div className="months">
                        <div className="history__month">
                            <div className="month-name">
                                Внесите свой вклад
                            </div>
                            <div className="month-text">
                                Помогите нам в формировании истории!
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container__year">
                    <div className="year-name">
                        2022
                    </div>

                    <div className="months">
                        <div className="history__month">
                            <div className="month-name">
                                Июнь, 1
                            </div>
                            <div className="month-text">
                                Насос уходит в армию
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container__year">
                    <div className="year-name">
                        2023
                    </div>

                    <div className="months">
                        <div className="history__month">
                            <div className="month-name">
                                Май, 30
                            </div>
                            <div className="month-text">
                                Насос возвращается с армии
                            </div>
                        </div>

                        <div className="history__month">
                            <div className="month-name">
                                Июнь, 29
                            </div>
                            <div className="month-text">
                                Лёва уходит в армию
                            </div>
                        </div>

                        <div className="history__month">
                            <div className="month-name">
                                Август, 21
                            </div>
                            <div className="month-text">
                                Начало создания сайта default-squad.ru
                            </div>
                        </div>

                        <div className="history__month">
                            <div className="month-name">
                                Октябрь, 11
                            </div>
                            <div className="month-text">
                                Создание Ринатом (Лапшой) топа по неадекватности.
                                <p>Топ-🔟 по неадекватности ✅:</p>
                                <ul style={{listStyle: "none"}}>
                                    <li>1. Фляма 🥳</li>
                                    <li>2. Нарижопа 🤓</li>
                                    <li>3. Семен im mechanic 🦽</li>
                                    <li>4. Квакер 🐸</li>
                                    <li>5. Руслан 🇺🇦</li>
                                    <li>6. Семен лаурайон 🎮</li>
                                    <li>7. Антон олололорион 🐸</li>
                                    <li>8. Лисимп ⚫</li>
                                    <li>9. Леша бизнес 💰</li>
                                    <li>10. Дед 👴</li>
                                </ul>
                            </div>
                        </div>

                        <div className="history__month">
                            <div className="month-name">
                                Октябрь, 13
                            </div>
                            <div className="month-text">
                                Встреча с Насосом (и Мотей) в Москве на его др (с Димой, Семёном, Флямой и Русланом)
                            </div>
                        </div>

                        <div className="history__month">
                            <div className="month-name">
                                Ноябрь, 3
                            </div>
                            <div className="month-text">
                                Форс про Мияги и Антона, слушает ли он Мияги
                            </div>
                        </div>

                        <div className="history__month">
                            <div className="month-name">
                                Декабрь, 19
                            </div>
                            <div className="month-text">
                                Начало прохождения сборки GregTech: New Horizons версии 2.5.0
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container__year">
                    <div className="year-name">
                        2024
                    </div>

                    <div className="months">
                        <div className="history__month">
                            <div className="month-name">
                                Январь, 27
                            </div>
                            <div className="month-text">
                                <q>Пошёл бизнес на Wildberries</q>
                                <p>Loorion</p>
                                Сходка Семёна, Флямы и Руслана на Чкаловской и обсуждение ситуации по Wildberries
                            </div>
                        </div>

                        <div className="history__month">
                            <div className="month-name">
                                Март, 20
                            </div>
                            <div className="month-text">
                                Самая большая сходка Default Squad за всё время и совместный просмотр кино
                            </div>
                        </div>

                        <div className="history__month">
                            <div className="month-name">
                                Июнь, 7
                            </div>
                            <div className="month-text">
                                Сходка у Флямы Олега, Руслана, Стёпы и Семёна
                            </div>
                        </div>

                        <div className="history__month">
                            <div className="month-name">
                                Июль, 6
                            </div>
                            <div className="month-text">
                                Возвращение Лёвы с армии
                            </div>
                        </div>

                        <div className="history__month">
                            <div className="month-name">
                                Июль, 25
                            </div>
                            <div className="month-text">
                                Приезд дефектных в Питер
                            </div>
                        </div>

                        <div className="history__month">
                            <div className="month-name">
                                Июль, 29
                            </div>
                            <div className="month-text">
                                Бизнес встреча дефолтных в Питере
                            </div>
                        </div>

                        <div className="history__month">
                            <div className="month-name">
                                Декабрь, 24
                            </div>
                            <div className="month-text">
                                Подарили ПК Стёпе
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container__year">
                    <div className="year-name">
                        2025
                    </div>

                    <div className="months">
                        <div className="history__month">
                            <div className="month-name">
                                ???
                            </div>
                            <div className="month-text">
                                Пока что не было никаких событий!
                            </div>
                        </div>
                    </div>
                </div>

                {/*<div className="history__vertical-text" style={{left: 560, top: 1600}}>*/}
                {/*    ФЛЯМА ДАУН АНТОН БОМЖ*/}
                {/*</div>*/}
            </div>
        </div>
        //     <div className="s-container upcoming-event" >Предстоящее событие</div>
    );
});

export default HistoryList;