import {useContext, useEffect} from "react";
import {Spinner} from "react-bootstrap";
import {observer} from "mobx-react";

import {WheatItemsStoreContext} from "../../../App.js";

// COMPONENTS
import Item from "../../../components/Wheat/Items/Item.jsx";



const ProportionalDistribution = observer(() => {
    const WIS = useContext(WheatItemsStoreContext);

    useEffect(() => {
        const fetchData = async () => {
            await WIS.getItemsProportionalDistribution();
        }

        fetchData();
    }, [WIS]);

    return (
        <div className="wheat__wrapper-content">
            <div className="wheat__list-title">
                Пропорциональное распределение предметов
            </div>
            <div className="wheat__list-description">
                (% предметов от всех предметов)
            </div>
            <div className="wheat-search">
                <input type="text" placeholder="Поиск" onChange={WIS.onChangeSearch}/>
            </div>
            {WIS.itemsLoading ? <Spinner/> :
                WIS.isSorted ?
                    WIS.itemsSorted.map((item) => <Item key={item.id} item={item} />)
                    :
                    WIS.items.map((item) => <Item key={item.id} item={item} />)
            }
        </div>
    );
});

export default ProportionalDistribution;