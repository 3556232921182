// MODULES
import SquadLoginForm from "../../modules/Squad/Auth/SquadLoginForm.jsx";

// COMPONENTS
import PublicHeader from "../../components/Main/PublicHeader.jsx";
// import Footer from "../../components/Index/Footer.jsx";



const LoginPage = () => {
    return (
        <>
            <PublicHeader title="Default Squad" />
            <SquadLoginForm title="Login" />
            {/* <Footer /> */}
        </>
    );
};

export default LoginPage;