import s from "./style.module.css";
import { createChart } from "lightweight-charts";
import { useRef, useEffect } from "react";


export const Chart = ({ data }) => {
    // data = {
    //     values: [
    //         {
    //             time: "2024-05-20",
    //             value: 5
    //         },
    //         {
    //             time: "2024-05-21",
    //             value: 6
    //         },
    //         {
    //             time: "2024-05-22",
    //             value: 4
    //         },
    //         {
    //             time: "2024-05-23",
    //             value: 7
    //         }
    //     ]
    // }

    const chartContainerRef = useRef(null);
    const chartRef = useRef(null);
    const seriesRef = useRef(null);

    useEffect(() => {
        if (!chartRef.current) {
            const chartOptions = {
                layout: {
                    textColor: "black",
                    background: {
                        type: "solid",
                        color: "white"
                    }
                }
            }

            chartRef.current = createChart(chartContainerRef.current, chartOptions);

            // const areaSeries = chart.addAreaSeries({
            //     lineColor: "#4dff39",
            //     topColor: "#ff8b1f",
            //     bottomColor: 'rgba(41, 98, 255, 0.28)'
            // });
            //
            // areaSeries.setData([
            //     { time: '2018-12-22', value: 32.51 },
            //     { time: '2018-12-23', value: 31.11 },
            //     { time: '2018-12-24', value: 27.02 },
            //     { time: '2018-12-25', value: 27.32 },
            //     { time: '2018-12-26', value: 25.17 },
            //     { time: '2018-12-27', value: 28.89 },
            //     { time: '2018-12-28', value: 25.46 },
            //     { time: '2018-12-29', value: 23.92 },
            //     { time: '2018-12-30', value: 22.68 },
            //     { time: '2018-12-31', value: 22.67 },
            // ]);

            seriesRef.current = chartRef.current.addLineSeries({
                upColor: '#26a69a',
                downColor: '#ef5350',
                borderVisible: false,
                wickUpColor: '#26a69a',
                wickDownColor: '#ef5350'
            });

            chartRef.current.timeScale().fitContent();
        }

        if (data?.values && data.values.length > 0) {
            seriesRef.current.setData(data.values);
        }

        return () => {
            if (chartRef.current) {
                chartRef.current.remove();
                chartRef.current = null;
            }
        }
    }, [data]);

    return (
        <div className={s.chart} ref={chartContainerRef}></div>
    );
};