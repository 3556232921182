// MODULES
import SquadRegistrationForm from "../../modules/Squad/Auth/SquadRegistrationForm.jsx";

// COMPONENTS
import PublicHeader from "../../components/Main/PublicHeader.jsx";
// import Footer from "../../components/Index/Footer.jsx";



const SquadRegistration = () => {
    return (
        <>
            <PublicHeader title="Default Squad" />
            <SquadRegistrationForm title="Registration" />
            {/* <Footer /> */}
        </>
    );
};

export default SquadRegistration;