import { observer } from "mobx-react";
import { Button, Image } from "react-bootstrap";
import { House, ArrowRight } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";

import NotificationsCenter from "../../../modules/Main/NotificationsCenter.js";

import ThemeToggleButton from "../../../UI/ThemeToggleButton.jsx";

// STORES
import { UserStoreContext } from "../../../App.js";



const Header = observer(() => {
    const User = useContext(UserStoreContext);

    const navigate = useNavigate();

    const IndexNavigate = () => {
        navigate("/");
    }

    const ProfileNavigate = () => {
        navigate("/profile");
    }

    const QuotesNavigate = () => {
        navigate("/quotes");
    }

    const MembersNavigate = () => {
        navigate("/members");
    }

    const HistoryNavigate = () => {
        navigate("/history");
        NotificationsCenter.ShowInfo("История будет дополняться");
    }

    const PostsNavigate = () => {
        navigate("/posts");
    }

    const LogoutHandler = async () => {
        await User.logout();
        navigate("/login");
    }

    return (
        <header className="page__header">
            <div className="page__header__left-block">
                <div className="page__header__left-block__ava">
                    <Image src={User.userData.avatarURL} title={User.userData.login + ' Avatar'} rounded />
                </div>
                <div className="page__header__left-block__name">
                    <div className="page__header__left-block__login text-glitch">
                        {User.userData.login}
                    </div>
                    <div className="page__header__left-block__buttons">
                        <div className="buttons-group">
                            <Button className="button" variant="outline-secondary" onClick={ProfileNavigate}>
                                Профиль
                            </Button>
                            <Button className="button" variant="outline-secondary" onClick={QuotesNavigate}>
                                Цитаты
                            </Button>
                            <Button className="button" variant="outline-secondary" onClick={MembersNavigate}>
                                Участники
                            </Button>
                            <Button className="button" variant="outline-secondary" onClick={HistoryNavigate}>
                                История
                            </Button>
                            <Button className="button" variant="outline-secondary" onClick={PostsNavigate}>
                                Посты
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page__header__right-block">
                <div className="buttons-group">
                    <ThemeToggleButton />
                    <Button className="button" variant="outline-secondary" onClick={IndexNavigate}>
                        На главную <House />
                    </Button>
                    <Button className="button" variant="outline-secondary" onClick={LogoutHandler}>
                        Выход <ArrowRight />
                    </Button>
                </div>
            </div>
        </header>
    );
});

export default Header;