import React from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { observer } from "mobx-react";


// PAGES
import NotFoundPage from "../../pages/Utils/NotFoundPage.jsx";
import TechnicalWorks from "../../pages/Utils/TechnicalWorks.jsx";

// COMPONENTS
import UniverseWrapper from "../../components/Universe/Wrapper/UniverseWrapper.jsx";
import UniverseEditItemsPage from "../../pages/Universe/UniverseEditItemsPage.jsx";
import UniverseProfilePage from "../../pages/Universe/Profile/UniverseProfilePage.jsx";
import InventoryPage from "../../pages/Universe/Inventory/InventoryPage.jsx";
import StoragePage from "../../pages/Universe/Storage/StoragePage.jsx";
import MarketPage from "../../pages/Universe/Market/MarketPage.jsx";

// STORES
import { PlayerStoreContext, MarketStoreContext } from "../../App.js";



const UniverseSection = observer(() => {
    // const User = useContext(UserStoreContext);
    //
    // useEffect(() => {
    //     User.updateUserData();
    // }, [User]);

    return (
        <>
            <Routes>
                {/*Protected*/}

                {/*<Route path="*" element={<TechnicalWorks />} />*/}
                <Route path="/universe/explore-items" element={<UniverseWrapper element={<UniverseEditItemsPage />} />} />
                <Route path="/universe/profile" element={<UniverseWrapper element={<UniverseProfilePage />} />} />
                <Route path="/universe/inventory" element={<UniverseWrapper element={<InventoryPage />} />} />
                <Route path="/universe/storage" element={<UniverseWrapper element={<StoragePage />} />} />
                <Route path="/universe/market" element={<UniverseWrapper element={<MarketPage />} />} />
                <Route path="/universe/equipment" element={<UniverseWrapper element={<UniverseProfilePage />} />} />
            </Routes>

            <ToastContainer />
        </>
    );
});

export default UniverseSection;