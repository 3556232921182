import { observer } from "mobx-react";

import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

import PublicHeader from "../../components/Main/PublicHeader.jsx";
import Footer from "../../components/Squad/Footer/Footer.jsx";


// https://ru.bem.info/methodology/quick-start/

const Index = observer(() => {
    const navigate = useNavigate();

    const SquadHandler = () => navigate("/profile");
    const UniverseHandler = () => navigate("/universe/profile");
    const WheatHandler = () => navigate("/wheat");

    const universeLock = true;
    const wheatLock = false;

    return (
        <div className="wrapper">
            <PublicHeader title="Default Squad" />
            <div className="index__content">
                <div className="index__logo-text">
                    Default
                </div>
                <div className="index__cards">
                    <div className="container">
                        <div className="index__card__title">
                            Squad
                        </div>
                        <div className="index-status index-status-working">
                            Working
                        </div>
                        <div className="index__card__button">
                            <Button variant="secondary" onClick={SquadHandler}>
                                Squad
                            </Button>
                        </div>
                    </div>
                    <div className="container">
                        <div className="index__card__title">
                            Universe
                        </div>
                        <div className="index-status index-status-wip">
                            In development
                        </div>
                        <div className="index__card__button">
                            {!universeLock &&
                                <Button variant="secondary" onClick={UniverseHandler}>
                                    Universe
                                </Button>
                            }
                        </div>
                    </div>
                    <div className="container">
                        <div className="index__card__title">
                            Wheat
                        </div>
                        <div className="index-status index-status-working">
                            Working
                        </div>
                        <div className="index__card__button">
                            {!wheatLock &&
                                <Button variant="secondary" onClick={WheatHandler}>
                                    Wheat
                                </Button>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
});

export default Index;