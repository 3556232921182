import s from "../gtnh.module.css";
import {ItemSlot} from "./ItemSlot.jsx";
import {useContext, useState} from "react";
import {DisassemblerStoreContext} from "../../../../App.js";
import NotificationsCenter from "../../../Main/NotificationsCenter.js";


export const AddItemForm = () => {
    const DisassemblerStore = useContext(DisassemblerStoreContext);

    const [itemName, setItemName] = useState("");
    const [machineName, setMachineName] = useState("");
    const [isFluid, setIsFluid] = useState(false);
    const [isBasic, setIsBasic] = useState(false);
    const [amountResult, setAmountResult] = useState(0);
    const [amountEMC, setAmountEMC] = useState(0);
    const [amountCreationTime, setAmountCreationTime] = useState(0);
    const [amountEnergy, setAmountEnergy] = useState(0);


    const amountSlots = 24;

    const onChangeName = (event) => {
        setItemName(event.target.value);
    }

    const onChangeAmount = (event) => {
        setAmountResult(event.target.value);
    }

    const onChangeAmountEMC = (event) => {
        setAmountEMC(event.target.value);
    }

    const onChangeAmountCreationTime = (event) => {
        setAmountCreationTime(event.target.value);
    }

    const onChangeAmountEnergy = (event) => {
        setAmountEnergy(event.target.value);
    }

    const onChangeMachineName = (event) => {
        setMachineName(event.target.value);
    }

    const onChangeIsFluid = () => {
        setIsFluid(!isFluid);
    }

    const onChangeIsBasic = () => {
        setIsBasic(!isBasic);
    }

    const clearForm = () => {
        setItemName("");
        setMachineName("");
        setIsFluid(false);
        setIsBasic(false);
        setAmountResult(0);
        setAmountEMC(0);
        setAmountCreationTime(0);
        setAmountEnergy(0);
    }

    const sendData = async (event) => {
        event.preventDefault();
        let data = await DisassemblerStore.sendItemData(itemName, amountResult, amountEnergy, amountCreationTime, machineName, isFluid, isBasic, amountEMC);

        if (data?.message) {
            NotificationsCenter.ShowSuccess(data.message);
        }

        clearForm();
    }

    return (
        <div className={s.formBackground}>
            <form onSubmit={sendData} className={s.addItemForm}>
                <fieldset>
                    <legend>
                        Основные параметры
                    </legend>
                    <div className={s.sectionRow}>
                        <label htmlFor={"itemName"}>Что получаем?</label>
                        <input id={"itemName"} type={"text"} value={itemName} onChange={onChangeName}/>
                    </div>
                    <div className={s.sectionRow}>
                        <label htmlFor={"amountResult"}>Сколько?</label>
                        <input id={"amountResult"} type={"text"}
                               value={amountResult} onChange={onChangeAmount}/>
                    </div>
                </fieldset>
                <fieldset>
                    <legend>
                        Ингредиенты
                    </legend>
                    <div className={s.slotsContainer}>
                        {
                            [...Array(amountSlots)].map((c, i) => {
                                return <ItemSlot key={i} index={i} />;
                            })
                        }
                    </div>
                </fieldset>

                <fieldset>
                    <legend>
                        GTNH
                    </legend>
                    <div className={s.sectionRow}>
                        <label htmlFor={"amountCreationTime"}>Время</label>
                        <input id={"amountCreationTime"} type={"text"}
                               value={amountCreationTime} onChange={onChangeAmountCreationTime}/>
                    </div>
                    <div className={s.sectionRow}>
                        <label htmlFor={"amountEnergy"}>Энергии</label>
                        <input id={"amountEnergy"} type={"text"}
                               value={amountEnergy} onChange={onChangeAmountEnergy}/>
                    </div>
                    <div className={s.sectionRow}>
                        <label htmlFor={"machineName"}>Механизм</label>
                        <input id={"machineName"} type={"text"} value={machineName}
                               onChange={onChangeMachineName}/>
                    </div>
                    <div className={s.sectionRow}>
                        <label htmlFor={"isFluid"}>Жидкость?</label>
                        <input checked={isFluid} value={isFluid} type={"checkbox"} onChange={onChangeIsFluid}
                               id={"isFluid"}/>
                    </div>
                    <div className={s.sectionRow}>
                        <label htmlFor={"isBasic"}>Базовый?</label>
                        <input checked={isBasic} value={isBasic} type={"checkbox"} onChange={onChangeIsBasic}
                               id={"isBasic"}/>
                    </div>
                    <div className={s.sectionRow}>
                        <label htmlFor={"amountEMC"}>EMC</label>
                        <input id={"amountEMC"} type={"text"} placeholder={"EMC"} value={amountEMC} onChange={onChangeAmountEMC}/>
                    </div>
                </fieldset>

                <div>
                    <input type={"submit"} value={"OK"}/>
                </div>
            </form>
        </div>
    );
}