import React from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { observer } from "mobx-react";


// PAGES
import NotFoundPage from "../../pages/Utils/NotFoundPage.jsx";
import TechnicalWorks from "../../pages/Utils/TechnicalWorks.jsx";

// COMPONENTS
import WheatWrapper from "../../components/Wheat/Wrapper/WheatWrapper.jsx";

// STORES
import WheatIndex from "../../pages/Wheat/Index/WheatIndex.jsx";
import ItemsProportionalDistribution from "../../pages/Wheat/Items/ProportionalDistribution.jsx";
import ItemsTopAmount from "../../pages/Wheat/Items/TopAmount.jsx";
import ItemsTopAmountDynamics from "../../pages/Wheat/Items/TopAmountDynamics.jsx";
import ItemChartAmount from "../../components/Wheat/Items/ItemChartAmount.jsx";



const WheatSection = observer(() => {
    // const User = useContext(UserStoreContext);
    //
    // useEffect(() => {
    //     User.updateUserData();
    // }, [User]);

    return (
        <>
            <Routes>
                {/*<Route path="*" element={<TechnicalWorks />} />*/}

                <Route path="/wheat" element={<WheatWrapper element={<WheatIndex />} />} />
                <Route path="/wheat/items/proportional-distribution" element={<WheatWrapper element={<ItemsProportionalDistribution />} />} />
                <Route path="/wheat/items/top-amount" element={<WheatWrapper element={<ItemsTopAmount />} />} />
                <Route path="/wheat/items/top-amount-dynamics" element={<WheatWrapper element={<ItemsTopAmountDynamics />} />} />
                <Route path="/wheat/item-chart-amount/:itemId" element={<WheatWrapper element={<ItemChartAmount />} />} />
                <Route path="/wheat/players-statistics" element={<WheatWrapper element={<ItemsTopAmountDynamics />} />} />
            </Routes>

            <ToastContainer />
        </>
    );
});

export default WheatSection;