// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 1000px) {
    .blog_blogTextAreaWidth__RShZu {
        width: 40em !important;
    }
}

@media screen and (max-width: 750px) {
    .blog_blogTextAreaWidth__RShZu {
        width: 30em !important;
    }
}

@media screen and (max-width: 500px) {
    .blog_blogTextAreaWidth__RShZu {
        width: 20em !important;
    }
}

.blog_blogTextAreaWidth__RShZu {
    width: 50em;
}`, "",{"version":3,"sources":["webpack://./src/components/Squad/Profile/blog.module.css"],"names":[],"mappings":"AAAA;IACI;QACI,sBAAsB;IAC1B;AACJ;;AAEA;IACI;QACI,sBAAsB;IAC1B;AACJ;;AAEA;IACI;QACI,sBAAsB;IAC1B;AACJ;;AAEA;IACI,WAAW;AACf","sourcesContent":["@media screen and (max-width: 1000px) {\r\n    .blogTextAreaWidth {\r\n        width: 40em !important;\r\n    }\r\n}\r\n\r\n@media screen and (max-width: 750px) {\r\n    .blogTextAreaWidth {\r\n        width: 30em !important;\r\n    }\r\n}\r\n\r\n@media screen and (max-width: 500px) {\r\n    .blogTextAreaWidth {\r\n        width: 20em !important;\r\n    }\r\n}\r\n\r\n.blogTextAreaWidth {\r\n    width: 50em;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blogTextAreaWidth": `blog_blogTextAreaWidth__RShZu`
};
export default ___CSS_LOADER_EXPORT___;
