import "./Project416.css";

import React from "react";
import {observer} from "mobx-react";



const Project416 = observer(() => {
    return (
        <div className="anton-game-wrapper">
            <div className="anton-game-content">
                <div className="anton-game-title">
                    Вход выполнен! Только он пока не привязан, по этому GG
                </div>
            </div>
        </div>
    );
});

export default Project416;